<template>
    <div class="main-terms-content banner-bg">
      <MainHeader></MainHeader>
      <div class="main-content-wrapper pb-5">
        <div class="container">
          <div class="row ">
            <div class="col-md-12">
              <h2>Oops! Page Not Found</h2>
              <p>
                We're sorry, but the page you're looking for seems to have vanished into thin air. While we can't
                hypnotize it back into existence, we can help guide you back on track. Here are a few options to
                help you find what you're looking for:
              </p>
              <p>
                1.Double-check the URL for any typos or errors.
                2.Use the navigation menu at the top of the page to browse through our website.
                3.Visit our homepage to explore the Believe Hypnosis app and its features.
                4.Join our Facebook group "Believe Hypnosis: Manifest Your
                Dreams" and connect with our community.
              </p>
              <p>
                If you believe you've reached this page in error, or if you need further assistance, please don't
                hesitate to contact our support team. We're here to help you manifest your dreams and make
                the most of your Believe Hypnosis experience!
              </p>
              <p>
                [Contact Support Button]
              </p>
              <p>
                Now, take a deep breath, relax, and let's get you back on the path to manifesting your dreams
                with Believe Hypnosis.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </template>
  <script>
  import Footer from "../components/footer.vue";
  import MainHeader from "../components/MainHeader.vue";
  
  export default {
    components: {
      MainHeader,
      Footer,
    },
  };
  </script>
  <style>
     .main-content-wrapper h2 , .main-content-wrapper h4 {
      color: #071649;
      margin-bottom: 20px;
  }
  .main-content-wrapper p{
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 400;
  }
  </style>
  