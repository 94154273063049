<template>
  <div>
    <form id="payment-form">
      <div id="payment-element">
        <!-- Elements will create form elements here -->
      </div>
      <div class="col-12 payment_btn">
          <a aria-label="Read more about Believe" href="#" class="main-btn banner-cta empty-2" @click="submitForm"
            ><span>Complete your Subscription</span></a
          >
        </div>
      
      <div id="error-message" class="text-danger">
        <!-- Display error message to your customers here -->
        {{ error_message }}
      </div>
    </form>
  </div>
</template>

<script>
// Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3

export default {
  props: {
    options: Object,
  },
  data() {
    return {
      elements: null,
      paymentElement: null,
      error_message: null,
    };
  },
  mounted() {
    this.elements = this.$stripe.elements(this.options);

    // Create and mount the Payment Element
    this.paymentElement = this.elements.create("payment");
    this.paymentElement.mount("#payment-element");
  },
  methods: {
    async submitForm() {
      this.error_message = null;
      const { error } = await this.$stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements: this.elements,
        confirmParams: {
          return_url: process.env.VUE_APP_SITE_URL + '/processing', // success url
          // return_url: "http://localhost:8080/processing", // success url
        },
      });
      if (error) {
        this.error_message =
          error?.message ??
          "We are unable to authenticate your payment method. Please choose a different payment method and try again";
        console.log(error);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    },
  },
};
</script>

<style></style>
