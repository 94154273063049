import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VModal from 'vue-js-modal'
import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
  pk: "pk_live_aXEBsfKQF0Ac22gvVxJwHsTk",
  // pk: "pk_test_51Iw6BGDjK3I8iT1C8OpxJS1NMmfrawYMrEyCImc6vfp6c0ZLCDE2XyJntKNVmqlT1xcunnPftLIVlHyql3AZiz4I00ttM70Gvr",
};

Vue.use(StripePlugin, options);
Vue.use(require('vue-moment'));

// import firebase from "firebase";
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyAQ11HECnLffAM3ZeJifGcZZmUNrN2uB-o",
  authDomain: "believe-b665d.firebaseapp.com",
  databaseURL: "https://believe-b665d-default-rtdb.firebaseio.com",
  projectId: "believe-b665d",
  storageBucket: "believe-b665d.appspot.com",
  messagingSenderId: "360199194045",
  appId: "1:360199194045:web:8c08f7d82623661304bed4",
  measurementId: "G-P6YWNQXV18",
};
initializeApp(firebaseConfig);
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

Vue.config.productionTip = false;
Vue.use(VueFormWizard)
Vue.use(VModal)
new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
