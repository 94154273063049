<template>
  <div>
    <section class="payment-wrapper payment-wrapper-mob">
      <div class="payment-inner-wrap">
        <section class="pricings pt-5" id="pricings">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-8">
                <div class="main-title text-center">
                  <div class="sec-heading style1">
                    <h3>Manage My Subscriptions</h3>
                  </div>
                  <!-- <div class="row banner">
                    <div class="col package_price">
                      <span>$ {{ actual_price }}</span>
                      <p class="package_price_para">
                        $ 6.49 USD per month, billed annually at $77.99/year
                        after<br />
                        your 14-day trial. Cancel anytime.
                      </p>
                    </div>
                  </div> -->

                  <div class="row banner justify-content-center m-0 pt-2 pb-4">
                    <img
                      width="72px"
                      src="../../public/assets/images/logo-mob.png"
                      alt=""
                    />
                  </div>
                  <div class="sec-heading style1 mb-4">
                    <h4>Believe Hypnosis App</h4>
                    <h4>Available Plans</h4>
                  </div>

                  <div class="payment_cards">
                    <h4>Choose a plan</h4>
                    <div class="subscription-form-wrap">
                      <form action="#1">
                        <div
                          class="form-group custom-select-box s"
                          :style="[
                            picked == 2
                              ? { 'background-color': '#0c5185' }
                              : '',
                            subbed_annual ? { opacity: 0.5 } : '',
                          ]"
                        >
                          <span class="best-value">Best Value</span>
                          <label class="subscription_packages" for="annual">
                            <p
                              class="monthly-text"
                              :style="[picked == 2 ? { color: '#fff' } : '']"
                            >
                              Annual - Billed at $77.99
                            </p>
                            <p class="days-text">14 days free</p>
                            <p
                              class="price-text"
                              :style="[picked == 2 ? { color: '#ffffff' } : '']"
                            >
                              {{
                                !subbed_annual
                                  ? " $ 6.49/month"
                                  : "Already Subscribed"
                              }}
                            </p>
                          </label>
                          <input
                            :disabled="subbed_annual"
                            type="radio"
                            id="annual"
                            name="subscription_plan"
                            value="2"
                            v-model="picked"
                            @change="changePlan()"
                          />
                        </div>
                        <div
                          class="form-group custom-select-box"
                          :style="[
                            picked == 1
                              ? { 'background-color': '#0c5185' }
                              : '',
                            subbed_month ? { opacity: 0.5 } : '',
                          ]"
                        >
                          <label class="subscription_packages" for="monthly">
                            <p
                              class="monthly-text"
                              :style="[picked == 1 ? { color: '#fff' } : '']"
                            >
                              Monthly
                            </p>
                            <p class="days-text">Monthly payment</p>
                            <p
                              class="price-text"
                              :style="[picked == 1 ? { color: '#fff' } : '']"
                            >
                              {{
                                !subbed_month
                                  ? " $ 11.99/month"
                                  : "Already Subscribed"
                              }}
                            </p>
                          </label>
                          <input
                            :disabled="subbed_month"
                            type="radio"
                            id="monthly"
                            name="subscription_plan"
                            value="1"
                            v-model="picked"
                            @change="changePlan()"
                          />
                        </div>
                      </form>
                    </div>
                    <!-- <h4
                      class="subscription_packages promo-code-label"
                      for="annual"
                    >
                      Promo code
                    </h4>

                    <input
                      type="text"
                      class="promo-code-label-input"
                      :class="[
                        link_access && picked == link_package
                          ? 'custom-disabled'
                          : '',
                      ]"
                      v-model="promo_code"
                      :disabled="link_access && picked == link_package"
                    />

                    <button
                      :disabled="link_access && picked == link_package"
                      type="button"
                      :class="[
                        link_access && picked == link_package
                          ? 'custom-disabled'
                          : '',
                      ]"
                      class="promo-code-label-btn"
                      @click="applyPromo()"
                    >
                      Apply
                    </button>
                    <div v-if="promo_error_message" class="text-danger">
                      {{ promo_error_message }}
                    </div>
                    <div v-if="promo_success_message" class="text-success">
                      {{ promo_success_message }}
                    </div> -->
                  </div>

                  <!-- <div class="payment_cards">
                    <div class="row email-field">
                      <div class="col-md-6 col-xs-12">
                        <h4 v-if="$store.state.user">Logged in as</h4>
                        <h4
                          v-else
                          class="subscription_packages promo-code-label"
                          for="annual"
                        >
                          Your Email
                        </h4>
                      </div>
                      <div class="col-md-6 col-xs-12 text-right">
                        <p
                          class="subscription_packages promo-code-label already-believe-user"
                          for="annual"
                        >
                          <a
                            v-if="$store.state.user"
                            href="javascript:;"
                            @click="logout()"
                            >Logout</a
                          >

                          <a v-else href="javascript:;" @click="login()"
                            >Already A Believe User? Login</a
                          >
                        </p>
                      </div>
                      <div class="col-md-12 col-xs-12 mb-3">
                        <input
                          v-if="$store.state.user"
                          type="text"
                          class="promo-code-label-input w-100 border-0 box-shadow-0"
                          disabled
                          v-model="$store.state.user.email"
                        />

                        <input
                          v-else
                          type="text"
                          class="promo-code-label-input w-100"
                          v-model="email"
                        />
                      </div>
                    </div>
                  </div> -->

                  <div class="payment_cards">
                    <h4>Choose payment method</h4>
                    <div class="row payment-methods">
                      <div class="col-md-6 p-0 paypal-button-col">
                        <button
                          class="payment_btn"
                          type="button"
                          @click="showPaypal(true)"
                        >
                          <img
                            src="/assets/images/resource/paypal.png"
                            width="30px"
                            alt=""
                          />
                          <span>Paypal</span>
                        </button>

                        <div
                          v-if="payment_method == 'paypal'"
                          class="paypal-body"
                        >
                          <Paypal
                            v-if="
                              payment_method == 'paypal' &&
                              $store.state.intent_generated != false
                            "
                            :plan_id="picked"
                            :promo_token="promo_token"
                            :promo_code="promo_code"
                            :key="picked"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 p-0 stripe-button-col">
                        <button
                          class="payment_btn"
                          type="button"
                          @click="
                            setPaymentMethod('stripe'), (show_paypal = false)
                          "
                        >
                          <img
                            src="/assets/images/resource/credit-card.png"
                            width="30px"
                            alt=""
                          />
                          <span> Credit or Debit Card </span>
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                          v-if="
                            payment_method == 'stripe' && show_paypal == false
                          "
                          class="stripe-body"
                        >
                          <Stripe
                            v-if="
                              payment_method == 'stripe' &&
                              $store.state.intent_generated != false
                            "
                            :plan_id="picked"
                            :promo_token="promo_token"
                            :promo_code="promo_code"
                            :key="picked"
                            :actual_price="actual_price"
                            :discount="discount"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="paypal_btn_div text-center">
                          <!-- <p>Sign in to Paypal to Continue</p> -->
                          <div class="total">
                            <div class="row total-top">
                              <div class="col-md-6">
                                <h6>Subtotal</h6>
                              </div>
                              <div class="col-md-6 text-right">
                                <p>$ {{ actual_price }}</p>
                              </div>
                            </div>
                            <div class="row total-top color-discount">
                              <div class="col-md-6">
                                <h6>Discount</h6>
                              </div>
                              <div class="col-md-6 text-right">
                                <p>$ {{ discount }}</p>
                              </div>
                            </div>
                            <div><hr /></div>
                            <div class="row total-middle">
                              <div class="col-md-6">
                                <h4>Total USD</h4>
                              </div>
                              <div class="col-md-6 text-right">
                                <span> $ {{ $store.state.final_price }} </span>
                              </div>
                            </div>
                            <div
                              v-if="
                                $store.state.user &&
                                !$store.state.user.trial_used &&
                                picked == '2'
                              "
                              class="row total-middle"
                            >
                              <div class="col-md-6">
                                <h4>Total During Trial USD</h4>
                              </div>
                              <div class="col-md-6 text-right">
                                <span> $ 0.00 </span>
                              </div>
                            </div>
                          </div>
                          <a
                            class="paypal_btn_payment"
                            v-if="show_paypal"
                            @click="setPaymentMethod('paypal')"
                          >
                            <img
                              src="../../public/assets/images/icons/paypalicon.png"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import Stripe from "../components/Stripe.vue";
import Paypal from "../components/Paypal.vue";
import LoginModal from "../Modal/LoginModal.vue";
import API from "../config/api";

export default {
  components: {
    Stripe,
    Paypal,
  },
  data() {
    return {
      picked: "2",
      promo_token: null,
      promo_code: null,
      payment_method: null,
      show_paypal: false,
      promo_error_message: "",
      promo_success_message: "",
      link_access: false,
      link_package: null,
      link_promo: null,
      email: "",
      subbed_month: false,
      subbed_annual: false,
    };
  },
  computed: {
    actual_price() {
      return this.picked == "1" ? "11.99" : "77.99";
    },
    discount() {
      return parseFloat(
        this.actual_price != this.$store.state.final_price
          ? this.actual_price - this.$store.state.final_price
          : "0.00"
      ).toFixed(2);
    },
  },

  mounted() {
    // this.showPaypal();
  },
  created() {
    this.getPromotoken();
    this.verify();
    this.checkAvailableSub();
  },
  methods: {
    async verify() {
      if (this.$route?.query?.promo_code) {
        localStorage.setItem("promo_code", this.$route?.query?.promo_code);
        localStorage.setItem("has_promo", "true");
        if (this.$route?.query?.package_type)
          localStorage.setItem(
            "package_type",
            this.$route?.query?.package_type
          );
      }
      this.$store.state.final_price = this.actual_price;
    },

    getPromotoken() {
      this.picked =
        this.$route?.query?.package_type ??
        this.$store.state.selected_package ??
        2;
      if (this.$route?.query?.promo)
        this.promo_token = this.$route?.query?.promo;
      if (this.$route?.query?.promo_code) {
        this.promo_code = this.$route?.query?.promo_code;
        this.applyPromo();
        this.link_access = true;
        this.link_package = this.picked;
        this.link_promo = this.promo_code;
      }
    },
    check_subscription() {
      if (this.$store?.state?.user && !this.$store?.state?.user?.is_subscribed)
        return true;
      else {
        return false;
      }
    },
    setPaymentMethod(payment_method) {
      if (this.$store.state.user) {
        this.payment_method = payment_method;
        this.$store.state.intent_generated = null;
        this.$store.state.general_error = null;
        if (this.$store.state.final_price == "0.00")
          this.$store.state.final_price = this.actual_price;
      } else this.login();
    },
    checkPaymentMethod() {
      if (this.payment_method != null) {
        return true;
      } else {
        return false;
      }
    },
    async applyPromo() {
      try {
        this.promo_error_message = null;
        this.promo_success_message = null;
        if (this.promo_code != null && this.promo_code != "") {
          const response = await API.post("/apply-promo-code", {
            promo_code: this.promo_code,
            plan_id: this.picked,
          });
          this.promo_success_message = response.data.message;
          this.$store.state.final_price = response.data.final_price;
        }
      } catch (err) {
        this.promo_error_message =
          err.response.data.message ?? "Something went wrong.";
      }
    },

    showPaypal(flag = true) {
      this.show_paypal = flag;
      if (this.$store.state.final_price == "0.00")
        this.$store.state.final_price = this.actual_price;
    },

    changePlan() {
      this.promo_code = null;
      this.payment_method = null;
      this.showPaypal(false);
      this.$store.state.final_price = this.actual_price;
      this.promo_error_message = null;
      this.promo_success_message = null;
      if (this.link_access && this.picked == this.link_package) {
        this.promo_code = this.link_promo;
        this.applyPromo();
      }
    },

    logout() {
      localStorage.removeItem("token");
      this.$store.state.user = null;
      // this.$router.push("/login");
      // console.log('token');
    },
    login() {
      this.$modal.show(LoginModal, {}, { height: "auto" });
    },
    checkAvailableSub() {
      let sub_history = this.$store.state.sub_history;
      if (sub_history.length > 0) {
        sub_history.forEach((element) => {
          if (element?.plan_id == "1" && element?.status == "active")
            this.subbed_month = true;
          else if (element?.plan_id == "2" && element?.status == "active")
            this.subbed_annual = true;
        });
      }
      if (this.subbed_annual) this.picked = "1";
      else if (this.subbed_month) this.picked = "2";
      if (this.subbed_annual && this.subbed_month) this.picked = "0";
    },
  },
};
</script>

<style>
section.payment-wrapper-mob {
  margin: 0 !important;
}
.payment-wrapper .paypal-button-col .payment_btn,
.payment-wrapper .stripe-button-col .payment_btn {
  width: 100%;
  text-align: start;
  display: block;
  padding-left: 20px;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0px;
  background: transparent;
  cursor: pointer;
  border-color: #00000080;
}
.payment-wrapper .paypal-button-col .payment_btn:focus,
.payment-wrapper .stripe-button-col .payment_btn:focus {
  outline: none;
}
.payment-wrapper .payment_btn {
  font-size: 18px;
}
.payment-wrapper .payment_btn span {
  padding-left: 10px;
}
.payment-wrapper .stripe-button-col .payment_btn {
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 10px;
}
.payment-wrapper .payment_cards .payment-methods {
  margin: 0;
}
.payment-wrapper .payment_cards h4 {
  color: #071649;
  font-size: 22px;
  font-weight: 400;
}
.payment-wrapper .payment-methods .col {
  padding: 0;
}
.payment-wrapper .payment_cards {
  position: relative;
  padding: 25px 30px;
  background: rgba(61, 188, 184, 0.1);
  backdrop-filter: blur(4.5px);
  border-radius: 10px;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: start;
}
.payment-wrapper .payment_cards .subscription-form-wrap form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.payment-wrapper
  .payment_cards
  .subscription-form-wrap
  form
  .subscription_packages
  p.days-text {
  color: #3dbcb8;
  font-size: 22px;
}
.payment-wrapper
  .payment_cards
  .subscription-form-wrap
  form
  .subscription_packages
  p {
  font-size: 16px;
}
.payment-wrapper
  .payment_cards
  .subscription-form-wrap
  form
  input[type="checkbox"] {
  height: 35px;
  width: 35px;
}
.payment-wrapper
  .payment_cards
  .subscription-form-wrap
  form
  input[type="checkbox"]::before {
  height: 35px;
  width: 35px;
}
.payment-wrapper
  .payment_cards
  .subscription-form-wrap
  form
  .custom-select-box {
  width: 48%;
}
p.package_price_para {
  font-size: 20px;
  line-height: 1.2em;
  margin-top: 20px;
}
.package_price span {
  font-size: 34px;
  font-weight: 600;
}
.package_price {
  margin-bottom: 30px;
}
/* section.payment-wrapper {
  margin: 90px 0 0 0;
} */
section.payment-wrapper section#pricings {
  background-color: #3dbcb826;
}
.step_form .wizard-header {
  display: none;
}
.step_form .wizard-icon-container {
  background: linear-gradient(184deg, #03174c, #051c50 10%, #45c5af 100%);
}
.step_form .vue-form-wizard .wizard-card-footer button {
  padding: 10px 25px;
  border: none;
  background: linear-gradient(184deg, #03174c, #051c50 10%, #45c5af 100%);
}
.step_form .pricing-btn {
  text-align: left;
}
.step_form .pricing-btn a {
  background: linear-gradient(184deg, #03174c, #051c50 10%, #45c5af 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 16px;
  font-weight: 500;
}
.step_form .vue-form-wizard .stepTitle {
  background: linear-gradient(184deg, #03174c, #051c50 10%, #45c5af 100%);
  -webkit-background-clip: text;
  color: transparent !important;
  margin-top: 10px;
}
.promo-code {
  text-align: left;
  margin-bottom: 30px;
}
.promo-code h4 span {
  font-weight: 200;
  padding-left: 5px;
}
.promo-code input[type="text"] {
  padding: 5px;
  border-radius: 2px;
  border: 0.5px solid #d1d1d1;
  width: 200px;
}
.payment-merchant-btn button {
  width: 100%;
  display: block;
  font-size: 18px;
  font-weight: 500;
  padding: 15px;
  border: none;
  text-transform: uppercase;
  border-radius: 2px;
}
.vue-form-wizard .wizard-tab-content {
  min-height: 100px;
  padding: 30px 20px 30px !important;
}
.payment-merchant-btn h4:before {
  content: "";
  background: black;
  width: 35%;
  height: 2px;
  position: absolute;
  top: 40%;
  left: 0;
}
.payment-merchant-btn h4:after {
  content: "";
  background: black;
  width: 35%;
  height: 2px;
  position: absolute;
  top: 40%;
  right: 0;
}
.payment-merchant-btn button.stripe-btn {
  background: #3395ce;
  color: white;
  cursor: pointer;
}
a {
  cursor: pointer;
}
.custom-select-box input[type="radio"]::before {
  content: "";
  display: block;
  position: absolute;
  text-align: center;
  height: 35px;
  width: 35px;
  left: 0;
  top: 0px;
  background-color: #3dbcb840;
  -webkit-clip-path: circle(65% at 50% 50%);
  clip-path: circle(65% at 50% 50%);
}
.custom-select-box input[type="radio"] {
  position: absolute;
  height: 35px;
  width: 35px;
  right: 5%;
  top: 35%;
  border: transparent;
  outline: transparent;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  accent-color: #0c5185;
  -webkit-clip-path: circle(65% at 50% 50%);
  clip-path: circle(65% at 50% 50%);
  background-color: #ceeeed;
}
.custom-select-box input[type="radio"]:checked {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background: url(../../public/assets/images/icons/tick.png) no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-clip-path: circle(65% at 50% 50%);
  clip-path: circle(65% at 50% 50%);
}
input.promo-code-label-input {
  height: 42px;
  width: 70%;
  padding: 10px;
  background-color: var(--colorBackground);
  border-radius: 5px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
    color 0.15s ease;
  border: 1px solid #00000080;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  display: inline-block;
  color: black;
}
button.promo-code-label-btn {
  background: #071649;
  width: 26%;
  display: inline-block;
  text-align: center;
  color: #3dbcb8;
  padding: 10px 0;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Rubik";
  font-weight: 500;
  box-shadow: 0px 6px 8px rgb(0 0 0 / 25%);
  text-decoration: none;
  margin-left: 10px;
}
a.paypal_btn_payment {
  width: 100%;
  display: block;
  padding: 10px;
  border-radius: 5px;
  background: #edb116;
}
.paypal_btn_div p {
  color: #00000080;
  font-family: "Rubik";
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
}
.login-form.popup {
  max-width: 100% !important;
  margin: 0;
}

input.promo-code-label-input.custom-disabled {
  background: #f1f1f1b3;
  border-color: #b5b5b5;
  color: grey;
}
button.promo-code-label-btn.custom-disabled {
  background: #f1f1f1b3;
  color: gray;
  pointer-events: visiblefill;
  box-shadow: none;
}
.already-believe-user a {
  color: #042051;
  /* font-size: 16px; */
}

.payment-wrapper .payment_cards .payment-methods .col-md-6 {
  border: 1px solid black;
  border-radius: 5px;
}
</style>
