<template>
  <section class="home-section" data-scroll-section>
    <!-- <MainHeader></MainHeader> -->
    <VideoBg></VideoBg>
    <Banner></Banner>
    <SectionOne></SectionOne>
    <SectionTwo></SectionTwo>
    <SectionThree></SectionThree>
    <ServicesSliderVue></ServicesSliderVue>
    <ServiceCarouselNew1></ServiceCarouselNew1>
    <ServiceCarouselNew2></ServiceCarouselNew2>
    <SectionFour></SectionFour>
    <ClientReview></ClientReview>
    <SectionFive></SectionFive>
    <Footer></Footer>
  </section>
</template>

<script>
// import MainHeader from "../components/MainHeader.vue";

// import Footer from "../components/NewFooter.vue";
import VideoBg from "../components/videobg.vue";
import Footer from "../components/footer.vue";
import Banner from "../components/banner.vue";
import SectionOne from "../components/SectionOne.vue";
import SectionTwo from "../components/SectionTwo.vue";
import SectionThree from "../components/SectionThree.vue";
import ServicesSliderVue from "../components/ServiceCarouselNew.vue";
import SectionFour from "../components/SectionFour.vue";
import ClientReview from "../components/ClientReview.vue";
import SectionFive from "../components/SectionFive.vue";
import ServiceCarouselNew1 from "../components/ServiceCarouselNew1.vue";
import ServiceCarouselNew2 from "../components/ServiceCarouselNew2.vue";

export default {
  components: {
    // MainHeader,
    VideoBg,
    Footer,
    Banner,
    SectionOne,
    SectionTwo,
    SectionThree,
    SectionFour,
    ClientReview,
    SectionFive,
    ServicesSliderVue,
    ServiceCarouselNew1,
    ServiceCarouselNew2,
  },
};
</script>
