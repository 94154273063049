<template>
  <div class="powerful-tool">
    <div class="container">
      <div class="row position-relative">
        <div class="col-md-4 col-sm-6">
          <h2 class="subheadings">A Powerful Tool For Personal Growth</h2>
          <div class="text-grid margin-set">
            <h4>Master Your Thinking</h4>
            <p>
              Learn to focus on what's important. Get control, consistency, and
              confidence to perform at your highest potential.
            </p>
          </div>
          <div class="text-grid">
            <h4>Enjoy Happiness and Pleasure</h4>
            <p>
              Learn the skills you need to become happy and present, to live in
              the moment, and allow yourself to let go and enjoy life's
              pleasures.
            </p>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 d-flex align-items-end">
          <div class="meditate">
            <lottie
              :options="defaultOptions"
              :height="0"
              :width="0"
              v-on:animCreated="handleAnimation"
            />
          </div>

          <!-- <img class="meditate" src="../../public/assets/images/resource/meditate.png" alt="Meditate" /> -->
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="text-grid mb-4">
            <h4>Create an Abundant Mindset</h4>
            <p>
              Gain a sense of financial empowerment with an abundance mentality
              that tells you there are always new opportunities.
            </p>
          </div>
          <div class="text-grid mb-4">
            <h4>Improve Your Health</h4>
            <p>
              A healthy mindset leads to healthy choices. Healthier choices lead
              to a healthy body. Program your mind and body for improved health.
            </p>
          </div>
          <div class="text-grid">
            <h4>Achieve Spiritual Enlightenment</h4>
            <p>
              Unlock your inner potential with personal empowerment. Tap into
              wisdom, clarity, and a deeper sense of purpose on your path to
              growth.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Lottie from "vue-lottie";
import * as animationData from "../../public/assets/images/lottie/meditation-animation.json";

export default {
  name: "app",
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      defaultOptions: { animationData: animationData },
      animationSpeed: 1,
    };
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
  },
};
</script>
<style>
.powerful-tool {
  margin: 100px 0;
}
h2.subheadings {
  color: #071649;
  font-size: 38px;
  font-weight: 500;
}
.text-grid {
  background: rgba(61, 188, 184, 0.15);
  backdrop-filter: blur(4.5px);
  border-radius: 10px;
  padding: 20px;
  border-radius: 10px;
}
.text-grid h4 {
  color: #071649;
  font-size: 22px;
  font-weight: 500;
}
.text-grid p {
  color: black;
  font-size: 18px;
  font-weight: 400;
}
.margin-set {
  margin: 75px 0;
}
img.meditate {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  right: 0;
}
</style>
