<template>
  <div class="main-terms-content banner-bg">
    <MainHeader></MainHeader>
    <div class="main-content-wrapper pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2>PRIVACY POLICY</h2>
            <p>Last updated December 29, 2022</p>
            <p>
              Thank you for choosing to be part of our community at Believe,
              LLC, doing business as Believe ("Believe," "we," "us," or "our").
              We are committed to protecting your personal information and your
              right to privacy. If you have any questions or concerns about this
              privacy notice or our practices with regard to your personal
              information, please contact us at info@believe.com
            </p>
            <p class="list-style">
              This privacy notice describes how we might use your information if
              you:
            </p>
            <div class="list-style-inner">
              <p>Download and use our mobile application — Believe</p>
              <p>
                Engage with us in other related ways ― including any sales,
                marketing, or events
              </p>
              <p>In this privacy notice, if we refer to:</p>
              <p>
                "<b>App</b>," we are referring to any application of ours that
                references or links to this policy, including any listed above
              </p>
              <p>
                "<b>Services</b>," we are referring to our App, and other
                related services, including any sales, marketing, or events
              </p>
              <p>
                The purpose of this privacy notice is to explain to you in the
                clearest way possible what information we collect, how we use
                it, and what rights you have in relation to it. If there are any
                terms in this privacy notice that you do not agree with, please
                discontinue use of our Services immediately.
              </p>
            </div>
            <p>
              <strong
                >Please read this privacy notice carefully, as it will help you
                understand what we do with the information that we
                collect.</strong
              >
            </p>
            <div class="table-of-content">
              <h4>TABLE OF CONTENTS</h4>
              <ul>
                <li><a href="#one">WHAT INFORMATION DO WE COLLECT? </a></li>
                <li><a href="#two">HOW DO WE USE YOUR INFORMATION? </a></li>
                <li>
                  <a href="#three"
                    >WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                  </a>
                </li>
                <li>
                  <a href="#four"> WHO WILL YOUR INFORMATION BE SHARED WITH?</a>
                </li>
                <li>
                  <a href="#five">HOW LONG DO WE KEEP YOUR INFORMATION? </a>
                </li>
                <li>
                  <a href="#six">HOW DO WE KEEP YOUR INFORMATION SAFE? </a>
                </li>
                <li><a href="#seven"> WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
                <li>
                  <a href="#eight"> CONTROLS FOR DO-NOT-TRACK FEATURES</a>
                </li>
                <li>
                  <a href="#nine"
                    >DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </a>
                </li>
                <li><a href="#ten">DO WE MAKE UPDATES TO THIS NOTICE? </a></li>
                <li>
                  <a href="#eleven"
                    >HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </a>
                </li>
                <li>
                  <a href="#twelve"
                    >HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
                    FROM YOU?
                  </a>
                </li>
                <li>
                  <a href="#thirteen">DELETION POLICY </a>
                </li>
              </ul>
            </div>
            <h4 id="one">1. WHAT INFORMATION DO WE COLLECT?</h4>
            <h5>Personal information you disclose to us</h5>
            <p>
              <i>
                <b>In Short:</b> We collect personal information that you
                provide to us.</i
              >
            </p>
            <p>
              We collect personal information that you voluntarily provide to us
              when you register on the App, express an interest in obtaining
              information about us or our products and Services, when you
              participate in activities on the App or otherwise when you contact
              us.
            </p>
            <p>
              The personal information that we collect depends on the context of
              your interactions with us and the App, the choices you make and
              the products and features you use. The personal information we
              collect may include the following:
            </p>
            <p>
              <b>Personal Information Provided by You.</b> We collect email
              addresses; names; usernames; passwords; contact or authentication
              data; and other similar information.
            </p>
            <p>
              All personal information that you provide to us must be true,
              complete and accurate, and you must notify us of any changes to
              such personal information.
            </p>
            <h5>Information automatically collected</h5>
            <p>
              <i
                ><b>In Short:</b> Some information — such as your Internet
                Protocol (IP) address and/or browser and device characteristics
                — is collected automatically when you visit our App.</i
              >
            </p>
            <p>
              We automatically collect certain information when you visit, use
              or navigate the App. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our App and other technical
              information. This information is primarily needed to maintain the
              security and operation of our App, and for our internal analytics
              and reporting purposes.
            </p>
            <p class="list-style">The information we collect includes:</p>
            <div class="list-style-inner">
              <p>
                <i><b>Log and Usage Data.</b></i> Log and usage data is
                service-related, diagnostic, usage and performance information
                our servers automatically collect when you access or use our App
                and which we record in log files. Depending on how you interact
                with us, this log data may include your IP address, device
                information, browser type and settings and information about
                your activity in the App (such as the date/time stamps
                associated with your usage, pages and files viewed, searches and
                other actions you take such as which features you use), device
                event information (such as system activity, error reports
                (sometimes called 'crash dumps') and hardware settings).
              </p>
              <p>
                <i><b>Device Data.</b></i> We collect device data such as
                information about your computer, phone, tablet or other device
                you use to access the App. Depending on the device used, this
                device data may include information such as your IP address (or
                proxy server), device and application identification numbers,
                location, browser type, hardware model Internet service provider
                and/or mobile carrier, operating system and system configuration
                information.
              </p>
              <p>
                <i><b>Location Data.</b></i> We collect location data such as
                information about your device's location, which can be either
                precise or imprecise. How much information we collect depends on
                the type and settings of the device you use to access the App.
                For example, we may use GPS and other technologies to collect
                geolocation data that tells us your current location (based on
                your IP address). You can opt out of allowing us to collect this
                information either by refusing access to the information or by
                disabling your Location setting on your device. Note however, if
                you choose to opt out, you may not be able to use certain
                aspects of the Services.
              </p>
            </div>
            <h5>Information collected through our App</h5>
            <p>
              <i
                ><b>In Short:</b> We collect information regarding your mobile
                device, push notifications, when you use our App.</i
              >
            </p>
            <p class="list-style">
              If you use our App, we also collect the following information:
            </p>
            <div class="list-style-inner">
              <p>
                <i><b>Mobile Device Data.</b></i> We automatically collect
                device information (such as your mobile device ID, model and
                manufacturer), operating system, version information and system
                configuration information, device and application identification
                numbers, browser type and version, hardware model Internet
                service provider and/or mobile carrier, and Internet Protocol
                (IP) address (or proxy server). If you are using our App, we may
                also collect information about the phone network associated with
                your mobile device, your mobile device’s operating system or
                platform, the type of mobile device you use, your mobile
                device’s unique device ID and information about the features of
                our App you accessed.
              </p>
              <p>
                <i><b>Push Notifications.</b></i> We may request to send you
                push notifications regarding your account or certain features of
                the App. If you wish to opt-out from receiving these types of
                communications, you may turn them off in your device's settings.
              </p>
              <p>
                This information is primarily needed to maintain the security
                and operation of our App, for troubleshooting and for our
                internal analytics and reporting purposes.
              </p>
            </div>

            <h5>Collection and Use of Image Data</h5>

            <h6>1. Collection of Images</h6>
            <p>
              <i>Our app collects images from users for various functionalities. These may include profile pictures, photos uploaded for in-app use, or images captured directly through the app’s camera integration. The collection occurs when users voluntarily upload images or grant the app permission to access their device's camera or photo gallery.
              </i>
            </p>
            <h6>2. Purpose of Image Collection</h6>
            <p class="list-style">
              We collect images to enhance user experience and app functionality. This includes, but is not limited to:
            </p>
            <li>Customizing user profiles.</li>
            <li>Enabling user participation in interactive features that require image uploads.</li>
            <li>Facilitating content creation within the app.</li>
            <h6>3. Use of Collected Images</h6>
            <p>
              Collected images are used for the following purposes:
            </p>
            <ul>
              <li>To display within the user’s profile or within app content where user interaction is necessary.</li>
              <li>To process or analyze within the app for delivering service features, such as photo editing or filtering.</li>
              <li>Images may also be used for promotional purposes if explicit consent is provided by the user.</li>
            </ul>

            <h6>4. Sharing of Images</h6>
            <p>
              We do not share personal images with third parties without user consent. However, images may be shared with third-party service providers under the following conditions:
            </p>
            <ul>
              <li>To our cloud storage providers for secure storage and backup.</li>
              <li>To our analytics and service optimization partners, strictly for performance improvement and user experience enhancement purposes.</li>
            </ul>
            <p>All third-party service providers are bound by strict data protection agreements that ensure the confidentiality and security of your data.</p>

            <h6>5. Security Measures</h6>
            <p>
              We implement a variety of security measures to ensure the safety of your images. These include:
            </p>
            <ul>
              <li>Encryption of images both in transit and at rest.</li>
              <li>Secure server storage with access restricted to authorized personnel only.</li>
              <li>Regular audits and tests of our security practices and technologies.</li>
            </ul>
            <h6>6. User Rights</h6>
            <p>
              You have the right to access, amend, or delete your images at any time through the app’s settings. If you wish to withdraw consent for our use of your images, please contact us at [your contact information]. Upon withdrawal of consent, we will cease the use of your images and remove them from our systems as per our data retention policy, unless otherwise required by law.
            </p>

            <h4 id="two">2. HOW DO WE USE YOUR INFORMATION?</h4>
            <p>
              <i
                ><b>In Short:</b> We process your information for purposes based
                on legitimate business interests, the fulfillment of our
                contract with you, compliance with our legal obligations, and/or
                your consent.</i
              >
            </p>
            <p>
              We use personal information collected via our App for a variety of
              business purposes described below. We process your personal
              information for these purposes in reliance on our legitimate
              business interests, in order to enter into or perform a contract
              with you, with your consent, and/or for compliance with our legal
              obligations. We indicate the specific processing grounds we rely
              on next to each purpose listed below.
            </p>

            <p class="list-style">
              We use the information we collect or receive:
            </p>
            <div class="list-style-inner">
              <p>
                <b>To facilitate account creation and logon process.</b> If you
                choose to link your account with us to a third-party account
                (such as your Google or Facebook account), we use the
                information you allowed us to collect from those third parties
                to facilitate account creation and logon process for the
                performance of the contract.
              </p>
              <p>
                <b>To post testimonials.</b> We post testimonials on our App
                that may contain personal information. Prior to posting a
                testimonial, we will obtain your consent to use your name and
                the content of the testimonial. If you wish to update, or delete
                your testimonial, please contact us at orders@hyptalk.com and be
                sure to include your name, testimonial location, and contact
                information.
              </p>
              <p>
                <b>Request feedback.</b> We may use your information to request
                feedback and to contact you about your use of our App.
              </p>
              <p>
                <b>To enable user-to-user communications. </b>We may use your
                information in order to enable user-to-user communications with
                each user's consent.
              </p>
              <p>
                <b>To manage user accounts.</b> We may use your information for
                the purposes of managing our account and keeping it in working
                order.
              </p>
              <p>
                <b>To send administrative information to you.</b> We may use
                your personal information to send you product, service and new
                feature information and/or information about changes to our
                terms, conditions, and policies.
              </p>
              <p>
                <b>To protect our Services.</b> We may use your information as
                part of our efforts to keep our App safe and secure (for
                example, for fraud monitoring and prevention).
              </p>
              <p>
                <b
                  >To enforce our terms, conditions and policies for business
                  purposes, to comply with legal and regulatory requirements or
                  in connection with our contract.</b
                >
              </p>
              <p>
                <b>To respond to legal requests and prevent harm.</b> If we
                receive a subpoena or other legal request, we may need to
                inspect the data we hold to determine how to respond.
              </p>
            </div>

            <p>
              <b>Fulfill and manage your orders.</b> We may use your information
              to fulfill and manage your orders, payments, returns, and
              exchanges made through the App.
            </p>
            <p>
              <b>Administer prize draws and competitions.</b> We may use your
              information to administer prize draws and competitions when you
              elect to participate in our competitions.
            </p>
            <p>
              <b>To deliver and facilitate delivery of services to the user.</b>
              We may use your information to provide you with the requested
              service.
            </p>

            <p>
              <b>To respond to user inquiries/offer support to users.</b> We may
              use your information to respond to your inquiries and solve any
              potential issues you might have with the use of our Services.
            </p>
            <p>
              <b>To send you marketing and promotional communications.</b> We
              and/or our third-party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is
              in accordance with your marketing preferences. For example, when
              expressing an interest in obtaining information about us or our
              App, subscribing to marketing or otherwise contacting us, we will
              collect personal information from you. You can opt-out of our
              marketing emails at any time (see the "WHAT ARE YOUR PRIVACY
              RIGHTS?" below).
            </p>
            <p>
              <b>Deliver targeted advertising to you.</b> We may use your
              information to develop and display personalized content and
              advertising (and work with third parties who do so) tailored to
              your interests and/or location and to measure its effectiveness.
            </p>

            <h4 id="three">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h4>
            <p>
              <i
                ><b>In Short:</b> We only share information with your consent,
                to comply with laws, to provide you with services, to protect
                your rights, or to fulfill business obligations.</i
              >
            </p>
            <p class="list-style">
              We may process or share your data that we hold based on the
              following legal basis:
            </p>
            <div class="list-style-inner">
              <p>
                <b>Consent:</b> We may process your data if you have given us
                specific consent to use your personal information for a specific
                purpose.
              </p>
              <p>
                <b>Legitimate Interests:</b> We may process your data when it is
                reasonably necessary to achieve our legitimate business
                interests.
              </p>
              <p>
                <b>Performance of a Contract:</b> Where we have entered into a
                contract with you, we may process your personal information to
                fulfill the terms of our contract.
              </p>
              <p>
                <b>Legal Obligations:</b> We may disclose your information where
                we are legally required to do so in order to comply with
                applicable law, governmental requests, a judicial proceeding,
                court order, or legal process, such as in response to a court
                order or a subpoena (including in response to public authorities
                to meet national security or law enforcement requirements).
              </p>
              <p>
                <b>Vital Interests:</b> We may disclose your information where
                we believe it is necessary to investigate, prevent, or take
                action regarding potential violations of our policies, suspected
                fraud, situations involving potential threats to the safety of
                any person and illegal activities, or as evidence in litigation
                in which we are involved. More specifically, we may need to
                process your data or share your personal information in the
                following situations:
              </p>
              <p>
                <b>Business Transfers.</b> We may share or transfer your
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all
                or a portion of our business to another company.
              </p>
              <p>
                <b
                  >Vendors, Consultants and Other Third-Party Service
                  Providers.</b
                >
                We may share your data with third-party vendors, service
                providers, contractors or agents who perform services for us or
                on our behalf and require access to such information to do that
                work. Examples include: payment processing, data analysis, email
                delivery, hosting services, customer service and marketing
                efforts. We may allow selected third parties to use tracking
                technology on the App, which will enable them to collect data on
                our behalf about how you interact with our App over time. This
                information may be used to, among other things, analyze and
                track data, determine the popularity of certain content, pages
                or features, and better understand online activity. Unless
                described in this notice, we do not share, sell, rent or trade
                any of your information with third parties for their promotional
                purposes. We have contracts in place with our data processors,
                which are designed to help safeguard your personal information.
                This means that they cannot do anything with your personal
                information unless we have instructed them to do it. They will
                also not share your personal information with any organization
                apart from us. They also commit to protect the data they hold on
                our behalf and to retain it for the period we instruct.
              </p>
            </div>

            <h4 id="four">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h4>
            <p>
              <i
                ><b>In Short:</b> We only share information with the following
                categories of third parties.</i
              >
            </p>
            <p>
              We only share and disclose your information with the following
              categories of third parties. If we have processed your data based
              on your consent and you wish to revoke your consent, please
              contact us using the contact details provided in the section below
              titled "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?".
            </p>
            <p>Data Analytics Services</p>
            <p>Retargeting Platforms</p>
            <p>Communication & Collaboration Tools</p>
            <p>Performance Monitoring Tools</p>
            <p>Testing Tools</p>
            <p>User Account Registration & Authentication Services</p>

            <h4 id="five">5. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
            <p>
              <i
                ><b>In Short:</b> We keep your information for as long as
                necessary to fulfill the purposes outlined in this privacy
                notice unless otherwise required by law.</i
              >
            </p>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </p>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>

            <h4 id="six">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
            <p>
              <i
                ><b>In Short:</b> We aim to protect your personal information
                through a system of organizational and technical security
                measures.</i
              >
            </p>
            <p>
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. However, despite our safeguards and
              efforts to secure your information, no electronic transmission
              over the Internet or information storage technology can be
              guaranteed to be 100% secure, so we cannot promise or guarantee
              that hackers, cybercriminals, or other unauthorized third parties
              will not be able to defeat our security, and improperly collect,
              access, steal, or modify your information. Although we will do our
              best to protect your personal information, transmission of
              personal information to and from our App is at your own risk. You
              should only access the App within a secure environment.
            </p>
            <h4 id="seven">7. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
            <p>
              <i
                ><b>In Short:</b> In some regions, such as the European Economic
                Area (EEA) and United Kingdom (UK), you have rights that allow
                you greater access to and control over your personal
                information. You may review, change, or terminate your account
                at any time.</i
              >
            </p>
            <p>
              In some regions (like the EEA and UK), you have certain rights
              under applicable data protection laws. These may include the right
              (i) to request access and obtain a copy of your personal
              information, (ii) to request rectification or erasure; (iii) to
              restrict the processing of your personal information; and (iv) if
              applicable, to data portability. In certain circumstances, you may
              also have the right to object to the processing of your personal
              information. To make such a request, please use the contact
              details provided below. We will consider and act upon any request
              in accordance with applicable data protection laws.
            </p>
            <p>
              If we are relying on your consent to process your personal
              information, you have the right to withdraw your consent at any
              time. Please note however that this will not affect the lawfulness
              of the processing before its withdrawal, nor will it affect the
              processing of your personal information conducted in reliance on
              lawful processing grounds other than consent.
            </p>
            <p>
              If you are a resident in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details here:
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
            </p>
            <p>
              If you are a resident in Switzerland, the contact details for the
              data protection authorities are available here:
              https://www.edoeb.admin.ch/edoeb/en/home.html.
            </p>
            <p>
              If you have questions or comments about your privacy rights, you
              may email us at orders@hyptalk.com.
            </p>
            <h5>Account Information</h5>
            <p>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can: Contact us
              using the contact information provided.
            </p>
            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with applicable legal
              requirements.
            </p>
            <p>
              <b><u>Opting out of email marketing:</u></b> You can unsubscribe
              from our marketing email list at any time by clicking on the
              unsubscribe link in the emails that we send or by contacting us
              using the details provided below. You will then be removed from
              the marketing email list — however, we may still communicate with
              you, for example to send you service-related emails that are
              necessary for the administration and use of your account, to
              respond to service requests, or for other non-marketing purposes.
              To otherwise opt-out, you may: Contact us using the contact
              information provided.
            </p>
            <h4 id="eight">8. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </p>
            <h4 id="nine">
              9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h4>
            <p>
              <b>In Short:</b> Yes, if you are a resident of California, you are
              granted specific rights regarding access to your personal
              information.
            </p>
            <p>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with the App, you have the right to request
              removal of unwanted data that you publicly post on the App. To
              request removal of such data, please contact us using the contact
              information provided below, and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the App, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.
              backups, etc.).
            </p>
            <h5>CCPA Privacy Notice</h5>
            <p>The California Code of Regulations defines a "resident" as:</p>
            <ul>
              <li>
                every individual who is in the State of California for other
                than a temporary or transitory purpose and
              </li>
              <li>
                every individual who is domiciled in the State of California who
                is outside the State of California for a temporary or transitory
                purpose
              </li>
            </ul>
            <p>All other individuals are defined as "non-residents."</p>
            <p>
              If this definition of "resident" applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </p>
            <p><b>What categories of personal information do we collect?</b></p>
            <p>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </p>

            <div class="table-div">
              <table style="width: 100%">
                <tbody>
                  <tr>
                    <td
                      style="
                        width: 33.8274%;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <br /><span style="font-size: 16px; color: black"
                        ><span style="font-size: 16px; color: black"
                          ><span data-custom-class="body_text"
                            ><strong>Category</strong></span
                          ></span
                        ></span
                      ><br /><br />
                    </td>
                    <td
                      style="
                        width: 51.4385%;
                        border-top: 1px solid black;
                        border-right: 1px solid black;
                      "
                    >
                      <br /><span style="font-size: 16px; color: black"
                        ><span style="font-size: 16px; color: black"
                          ><span data-custom-class="body_text"
                            ><strong>Examples</strong></span
                          ></span
                        ></span
                      ><br /><br />
                    </td>
                    <td
                      style="
                        width: 14.9084%;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                        text-align: center;
                      "
                    >
                      <br /><span style="font-size: 16px; color: black"
                        ><span style="font-size: 16px; color: black"
                          ><span data-custom-class="body_text"
                            ><strong>Collected</strong></span
                          ></span
                        ></span
                      ><br /><br />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.8274%;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >A. Identifiers</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 51.4385%;
                        border-top: 1px solid black;
                        border-right: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >Contact details, such as real name, alias, postal
                              address, telephone or mobile contact number,
                              unique personal identifier, online identifier,
                              Internet Protocol address, email address and
                              account name</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 14.9084%;
                        text-align: center;
                        vertical-align: middle;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5"><br /></div>
                      <div
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span style="color: black"
                          ><span data-custom-class="body_text"
                            ><bdt class="forloop-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="block-component"
                                  ></bdt></span></span></bdt></span></span
                        >NO<span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="statement-end-if-in-editor"
                                  ></bdt></span></span></span></span
                        ></span>
                      </div>
                      <div style="line-height: 1.5"><br /></div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.8274%;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >B. Personal information categories listed in the
                              California Customer Records statute</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 51.4385%;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >Name, contact information, education, employment,
                              employment history and financial information</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 14.9084%;
                        text-align: center;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5"><br /></div>
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >YES</span
                            ></span
                          ></span
                        >
                      </div>
                      <div style="line-height: 1.5"><br /></div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.8274%;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >C. Protected classification characteristics under
                              California or federal law</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 51.4385%;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >Gender and date of birth</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 14.9084%;
                        text-align: center;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5"><br /></div>
                      <div
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span style="color: black"
                          ><span data-custom-class="body_text"
                            ><bdt class="forloop-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="block-component"
                                  ></bdt></span></span></bdt></span></span
                        >NO<span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="statement-end-if-in-editor"
                                  ></bdt></span></span></span></span
                        ></span>
                      </div>
                      <div style="line-height: 1.5"><br /></div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.8274%;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >D. Commercial information</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 51.4385%;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >Transaction information, purchase history,
                              financial details and payment information</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 14.9084%;
                        text-align: center;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5"><br /></div>
                      <div
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span style="color: black"
                          ><span data-custom-class="body_text"
                            ><bdt class="forloop-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="block-component"
                                  ></bdt></span></span></bdt></span></span
                        >NO<span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="statement-end-if-in-editor"
                                  ></bdt></span></span></span></span
                        ></span>
                      </div>
                      <div style="line-height: 1.5"><br /></div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.8274%;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >E. Biometric information</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 51.4385%;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >Fingerprints and voiceprints</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 14.9084%;
                        text-align: center;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5"><br /></div>
                      <div
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span style="color: black"
                          ><span data-custom-class="body_text"
                            ><bdt class="forloop-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="block-component"
                                  ></bdt></span></span></bdt></span></span
                        >NO<span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="statement-end-if-in-editor"
                                  ></bdt></span></span></span></span
                        ></span>
                      </div>
                      <div style="line-height: 1.5"><br /></div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.8274%;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >F. Internet or other similar network
                              activity</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 51.4385%;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >Browsing history, search history, online
                              behavior, interest data, and interactions with our
                              and other websites, applications, systems and
                              advertisements</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 14.9084%;
                        text-align: center;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5"><br /></div>
                      <div
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span style="color: black"
                          ><span data-custom-class="body_text"
                            ><bdt class="forloop-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="block-component"
                                  ></bdt></span></span></bdt></span></span
                        >NO<span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="statement-end-if-in-editor"
                                  ></bdt></span></span></span></span
                        ></span>
                      </div>
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              ><br /></span></span
                        ></span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.8274%;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >G. Geolocation data</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 51.4385%;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >Device location</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 14.9084%;
                        text-align: center;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5"><br /></div>
                      <div
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span style="color: black"
                          ><span data-custom-class="body_text"
                            ><bdt class="forloop-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="block-component"
                                  ></bdt></span></span></bdt></span></span
                        >NO<span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="statement-end-if-in-editor"
                                  ></bdt></span></span></span></span
                        ></span>
                      </div>
                      <div style="line-height: 1.5"><br /></div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.8274%;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >H. Audio, electronic, visual, thermal, olfactory,
                              or similar information</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 51.4385%;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >Images and audio, video or call recordings
                              created in connection with our business
                              activities</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 14.9084%;
                        text-align: center;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5"><br /></div>
                      <div
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span style="color: black"
                          ><span data-custom-class="body_text"
                            ><bdt class="forloop-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="block-component"
                                  ></bdt></span></span></bdt></span></span
                        >NO<span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="statement-end-if-in-editor"
                                  ></bdt></span></span></span></span
                        ></span>
                      </div>
                      <div style="line-height: 1.5"><br /></div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.8274%;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >I. Professional or employment-related
                              information</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 51.4385%;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >Business contact details in order to provide you
                              our services at a business level, job title as
                              well as work history and professional
                              qualifications if you apply for a job with
                              us</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        width: 14.9084%;
                        text-align: center;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                      "
                    >
                      <div style="line-height: 1.5"><br /></div>
                      <div
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span style="color: black"
                          ><span data-custom-class="body_text"
                            ><bdt class="forloop-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="block-component"
                                  ></bdt></span></span></bdt></span></span
                        >NO<span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="statement-end-if-in-editor"
                                  ></bdt></span></span></span></span
                        ></span>
                      </div>
                      <div style="line-height: 1.5"><br /></div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                        width: 33.8274%;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >J. Education Information</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                        width: 51.4385%;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >Student records and directory information</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        text-align: center;
                        border-right: 1px solid black;
                        border-top: 1px solid black;
                        width: 14.9084%;
                      "
                    >
                      <div style="line-height: 1.5"><br /></div>
                      <div
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span style="color: black"
                          ><span data-custom-class="body_text"
                            ><bdt class="forloop-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"></bdt></span
                          ><span data-custom-class="body_text"
                            ><bdt class="block-component"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="block-component"
                                  ></bdt></span></span></bdt></span></span
                        >NO<span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              ><span style="color: black"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="statement-end-if-in-editor"
                                  ></bdt></span></span></span></span
                        ></span>
                      </div>
                      <div style="line-height: 1.5"><br /></div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        border-width: 1px;
                        border-color: black;
                        border-style: solid;
                        width: 33.8274%;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >K. Inferences drawn from other personal
                              information</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        border-bottom: 1px solid black;
                        border-top: 1px solid black;
                        border-right: 1px solid black;
                        width: 51.4385%;
                      "
                    >
                      <div style="line-height: 1.5">
                        <span style="font-size: 16px; color: black"
                          ><span style="font-size: 16px; color: black"
                            ><span data-custom-class="body_text"
                              >Inferences drawn from any of the collected
                              personal information listed above to create a
                              profile or summary about, for example, an
                              individual’s preferences and characteristics</span
                            ></span
                          ></span
                        >
                      </div>
                    </td>
                    <td
                      style="
                        text-align: center;
                        border-right: 1px solid black;
                        border-bottom: 1px solid black;
                        border-top: 1px solid black;
                        width: 14.9084%;
                      "
                    >
                      <div style="line-height: 1.5"><br /></div>
                      <div
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span data-custom-class="body_text"
                          ><bdt class="block-component"></bdt>NO<span
                            style="font-size: 16px; color: black"
                            ><span style="font-size: 16px; color: black"
                              ><span data-custom-class="body_text"
                                ><span style="color: black"
                                  ><span data-custom-class="body_text"
                                    ><bdt
                                      class="statement-end-if-in-editor"
                                    ></bdt></span></span></span></span></span
                        ></span>
                      </div>
                      <div style="line-height: 1.5"><br /></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              We may also collect other personal information outside of these
              categories instances where you interact with us in-person, online,
              or by phone or mail in the context of:
            </p>
            <p>Receiving help through our customer support channels;</p>
            <p>Participation in customer surveys or contests; and</p>
            <p>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </p>
            <p><b>How do we use and share your personal information?</b></p>
            <p>
              More information about our data collection and sharing practices
              can be found in this privacy notice.
            </p>
            <p>
              You may contact us by email at orders@hyptalk.com, or by referring
              to the contact details at the bottom of this document.
            </p>
            <p>
              If you are using an authorized agent to exercise your right to
              opt-out we may deny a request if the authorized agent does not
              submit proof that they have been validly authorized to act on your
              behalf.
            </p>
            <p><b>Will your information be shared with anyone else?</b></p>
            <p>
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf.
            </p>
            <p>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be "selling" of your personal data.
            </p>
            <p>
              Believe, LLC has disclosed the following categories of personal
              information to third parties for a business or commercial purpose
              in the preceding twelve (12) months:
            </p>
            <p>
              Category B. Personal information, as defined in the California
              Customer Records law, such as your name, contact information,
              education, employment, employment history and financial
              information.
            </p>
            <p>
              The categories of third parties to whom we disclosed personal
              information for a business or commercial purpose can be found
              under "WHO WILL YOUR INFORMATION BE SHARED WITH?".
            </p>
            <p>
              Believe, LLC has not sold any personal information to third
              parties for a business or commercial purpose in the preceding
              twelve (12) months. Believe, LLC will not sell personal
              information in the future belonging to website visitors, users and
              other consumers.
            </p>
            <p><b>Your rights with respect to your personal data</b></p>
            <p>
              <u>Right to request deletion of the data - Request to delete</u>
            </p>
            <p>
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation or
              any processing that may be required to protect against illegal
              activities.
            </p>
            <p><u>Right to be informed - Request to know</u></p>
            <p>Depending on the circumstances, you have a right to know:</p>
            <p>whether we collect and use your personal information;</p>
            <p>the categories of personal information that we collect;</p>
            <p>
              the purposes for which the collected personal information is used;
            </p>
            <p>whether we sell your personal information to third parties;</p>
            <p>
              the categories of personal information that we sold or disclosed
              for a business purpose;
            </p>
            <p>
              the categories of third parties to whom the personal information
              was sold or disclosed for a business purpose; and
            </p>
            <p>
              the business or commercial purpose for collecting or selling
              personal information.
            </p>
            <p>
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.
            </p>
            <p>
              <u
                >Right to Non-Discrimination for the Exercise of a Consumer’s
                Privacy Rights</u
              >
            </p>
            <p>
              We will not discriminate against you if you exercise your privacy
              rights.
            </p>
            <p><u>Verification process</u></p>
            <p>
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g. phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </p>
            <p>
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. If, however, we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity, and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.
            </p>
            <p><u>Other privacy rights</u></p>
            <p>you may object to the processing of your personal data</p>
            <p>
              you may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the data
            </p>
            <p>
              you can designate an authorized agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorized
              agent that does not submit proof that they have been validly
              authorized to act on your behalf in accordance with the CCPA.
            </p>
            <p>
              you may request to opt-out from future selling of your personal
              information to third parties. Upon receiving a request to opt-out,
              we will act upon the request as soon as feasibly possible, but no
              later than 15 days from the date of the request submission.
            </p>
            <p>
              To exercise these rights, you can contact us by email at
              orders@hyptalk.com, or by referring to the contact details at the
              bottom of this document. If you have a complaint about how we
              handle your data, we would like to hear from you.
            </p>

            <h4 id="ten">10. DO WE MAKE UPDATES TO THIS NOTICE?</h4>
            <p>
              <i
                ><b>In Short:</b> Yes, we will update this notice as necessary
                to stay compliant with relevant laws.</i
              >
            </p>
            <p>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
            <h4 id="eleven">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
            <p>
              If you have questions or comments about this notice, you may email
              us at orders@hyptalk.com or by post to:
            </p>
            <p>
              Believe, LLC<br />
              4815 E. Carefree Hwy, #108-256<br />
              Cave Creek, AZ 85331<br />
              United States
            </p>
            <h4 id="twelve">
              12. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h4>
            <p>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it in some
              circumstances. To request to review, update, or delete your
              personal information, please email us at orders@hyptalk.com.
            </p>

            <h4 id="thirteen">13. DELETION POLICY</h4>

            <h5>Introduction</h5>
            <p>
              This Deletion Policy applies to users of the BELIEVE app, which is
              wholly owned and operated by Hyptalk.com, LLC. By using the
              BELIEVE app, you agree to the terms outlined in this policy
              concerning the deletion of your account
            </p>

            <h5>Information That Gets Deleted</h5>
            <p>
              <i
                >When you choose to delete your BELIEVE account, the following
                information is removed:</i
              >
            </p>
            <p>
              <b>Identifiers Cleared:</b> Personal identifiers including your
              email address, name, and FCM (Firebase Cloud Messaging) token are
              changed to an empty string "" in our database.
            </p>
            <p>
              <b>Authentication Removal:</b> Your user profile will be removed
              from Firebase, disabling any future authentication through the
              BELIEVE app.
            </p>
            <h5>Information That Is Not Deleted</h5>
            <p>
              <b>Activity Data:</b> All activities you have performed in the
              BELIEVE app will be retained.
            </p>
            <p class="list-style">
              We may process or share your data that we hold based on the
              following legal basis:
            </p>
            <div class="list-style-inner">
              <p>This encompasses:</p>
              <p>Total minutes spent using the app</p>
              <p>Favorites</p>
              <p>Playlists created</p>
              <p>Badges earned</p>
              <p>
                This activity data is essential for generating internal
                statistics and improving the app's features. Once your account
                is deleted, this data will be anonymized and cannot be linked
                back to you.
              </p>
            </div>
            <h5>Why We Retain Some Data</h5>
            <p>
              We keep your activity data for statistical analysis aimed at
              enhancing the user experience and functionality of the BELIEVE
              app.
            </p>
            <h5>How To Delete Your Account</h5>
            <p>
              To begin the account deletion process, please follow the specific
              steps outlined within the BELIEVE app or contact our support team
              at <a href="mailto:orders@hyptalk.com">orders@hyptalk.com</a>.
            </p>
            <h5>Updates To This Policy</h5>
            <p>
              Hyptalk.com, LLC reserves the right to modify this Deletion
              Policy. Should changes occur, we will notify users through
              appropriate channels, such as in-app notifications or updates on
              our website.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../components/footer.vue";
import MainHeader from "../components/MainHeader.vue";

export default {
  components: {
    MainHeader,
    Footer,
  },
};
</script>
<style>
.main-content-wrapper h2,
.main-content-wrapper h4,
.main-content-wrapper h5 {
  color: #071649;
  margin-bottom: 20px;
}
.main-content-wrapper p {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
}
.main-content-wrapper p.list-style {
  font-weight: 500;
  margin: 0;
}
.main-content-wrapper .list-style-inner p {
  margin: 0;
}
.main-content-wrapper .list-style-inner {
  margin-bottom: 20px;
}
.main-content-wrapper .table-of-content {
  margin-bottom: 30px;
}
.main-content-wrapper .table-of-content ul li {
  list-style: num;
  color: black;
  margin-bottom: 5px;
  font-size: 18px;
}
.main-content-wrapper .table-of-content ul li a {
  color: black;
}
.table-div table td {
  padding: 5px;
}
.table-div {
  margin-bottom: 30px;
}
</style>
