<template>
    
    <div class="home-section">
        <MainHeader></MainHeader>
      <section class="pricings pt-5 pb-5 subscription-cancel-info-main" id="pricings">
        <div class="container">
         <div class="row">
            <div class="col-md-6 pr-4">
                <h3>Cancel Subscription Android</h3>
                <ul>
                    <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</li>
                    <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</li>
                    <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</li>
                    
                </ul>
               
            </div>
            <div class="col-md-6 pl-4">
                <h3>Cancel Subscription IOS</h3>
                <ul>
                    <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</li>
                    <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</li>
                    <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</li>
                    
                </ul>
            </div>
         </div>
        </div>
      </section>
    </div>

  </template>
  
  <script>
  import MainHeader from "../components/MainHeader.vue";
  
  export default {
    components: {
      MainHeader,
    },
    created() {
    
    },
  };
  </script>
  
  <style>
 .subscription-cancel-info-main{
    margin-top: 100px;
 }
 .subscription-cancel-info-main ul li::marker {
    color: #349795;
}
.subscription-cancel-info-main ul li{
    margin-bottom: 10px;
}
  </style>
  