<template>
  <div class="main-terms-content banner-bg">
    <MainHeader></MainHeader>
    <div class="main-content-wrapper pb-5">
      <div class="container">
        <div class="row ">
          <div class="col-md-12">
            <h2>Coming Soon: Content Creators Program for Believe Hypnosis</h2>
            <p>
              Welcome to the Believe Hypnosis Content Creators Program! We're in the process of developing an exciting opportunity for talented content creators to contribute their own unique content to the Believe Hypnosis app and earn revenue from their creations. By joining our Content Creators Program, you'll have the chance to share your expertise with a wider audience and generate income while doing so.
            </p>
            <p>
              <b>⏳ Our Content Creators Program is coming soon!</b>
            </p>
            <p>
              If you're interested in becoming a content creator for Believe Hypnosis, please fill out the form below to register your interest. We'll notify you as soon as our Content Creators Program is launched!
            </p>
            <p>
              [Opt-in Form] 
           </p>
            <p>
            <b>Our Content Creators Program is perfect for:</b>
            </p>
            <p>
              <ul class="affiliate-points">
                  <li>Hypnotherapists and guided meditation experts</li>
                  <li>Personal development coaches</li>
                  <li>Law of Attraction specialists</li>
                  <li>Wellness and mindfulness instructors</li>
                  <li>Inspirational speakers and authors in the personal growth niche</li>
              </ul>
          </p>
          <p><b>
            By joining our Content Creators Program, you'll enjoy:
          </b></p>
          <p>
              <ul class="affiliate-points">
                  <li>A revenue-sharing model to earn from your content</li>
                  <li>Exposure to a global audience through the Believe Hypnosis app</li>
                  <li>Support and guidance from our team</li>
                  <li>The opportunity to collaborate with like-minded creators</li>
              </ul>
          </p>
          <p>
            Don't miss out on this unique opportunity to become a part of the Believe Hypnosis Content Creators Program. Register your interest now, and stay tuned for more details on the program's launch!
          </p>
          <p>
            In the meantime, we invite you to explore the Believe Hypnosis app, join our Facebook group "Believe Hypnosis: Manifest Your Dreams," and connect with our growing community of passionate individuals.
          </p>
          </div>
        </div>
      </div>
    </div>

    <iframe
      id="JotFormIFrame-231166551081147"
      title="New Customer Registration Form"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allowfullscreen="true"
      allow="geolocation; microphone; camera"
      src="https://form.jotform.com/231166551081147"
      frameborder="0"
      style="min-width:100%;max-width:100%;height:2150px;border:none;"
      scrolling="no"
    >
    </iframe>

    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../components/footer.vue";
import MainHeader from "../components/MainHeader.vue";

export default {
  components: {
    MainHeader,
    Footer,
  },
};
</script>
<style>
   .main-content-wrapper h2 , .main-content-wrapper h4 {
    color: #071649;
    margin-bottom: 20px;
}
.main-content-wrapper p{
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
}
</style>