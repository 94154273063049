<template>
    <div class="page-wrapers main-terms-content">
      <loadingOverlay :is-loading="loading" />
      <MainHeader></MainHeader>
      <section class="same-layout payments-plans fixed-height">
        <div class="inner-left pt-5">
          <div class="col-sm-12">
            <!-- <img
              src="../../public/assets/images/logo-mob.png"
              alt=""
              class="d-block"
            /> -->
            <br />
            <br />
            <br />
            <br />
            <span class="banner-para"><b>DREAM</b> it, <b>BELIEVE</b> it, and <b>ACHIEVE</b> your
              DESIRES</span>
            <ul class="banner-list">
              <li>Speed up your manifestations</li>
              <li>Access 1000+ powerful LOA hypnosis audios</li>
              <li>Members only personal growth courses</li>
              <li>Videos, Scripts, Live Events + Much More</li>
              <li>Start Living a Happier More Abundant life now</li>
            </ul>
          </div>
        </div>
        <div v-if="!showCD" class="inner-right pt-5">
          <div class="col-sm-12">
            <div v-if="alert_primary_message" class="alert alert-primary" role="alert">
              Changing Plan Subscription ...
            </div>
            <div id="login" class="tab-pane active text-center">
              <div class="p-a30 dez-form">
                <h3 class="form-title m-t0 mb-5 text-left">{{ getHead }}</h3>
                <div class="payment_cards">
                  <div class="subscription-form-wrap">
                    <form action="#1">
                      <div class="form-group custom-select-box s" :style="[
                        picked == 2 ? { border: '1px solid #0c5185' } : '',
                      ]">
                        <div class="trialdiv">
                          <h6 class="freetrial">{{ noTrial }}</h6>
                        </div>
                        <div v-if="disableYearly" class="trialdiv">
                          <h6 class="alreadysubscribed">{{ substatus }}</h6>
                        </div>
                        <!-- <span v-if="!this.promoManually || !this.has_promo == false" class="best-value">Best Value</span> -->
                        <span class="best-value">Best Value</span>
                        <label class="subscription_packages" for="annual">
                          <p class="monthly-text" :style="[picked == 2 ? { color: '#000' } : '']">
                            Yearly
                          </p>
                          <p class="days-text">$6.49/mo. billed yearly</p>
                          <p class="price-text" :style="[picked == 2 ? { color: '#000' } : '']">
                            Then $77.99/yr. Cancel Anytime
                          </p>
                        </label>
                        <input type="radio" id="annual" name="subscription_plan" value="2" v-model="picked"
                          @change="changePlan()" :disabled="disableYearly" v-bind:class="{
                            'disable-field': disableYearly,
                          }" />
                      </div>
                      <div class="form-group custom-select-box" :style="[
                        picked == 1 ? { border: '1px solid #0c5185' } : '',
                      ]">
                        <div v-if="disableMonthly" class="trialdiv">
                          <h6 class="alreadysubscribed">{{ substatus }}</h6>
                        </div>
                        <label class="subscription_packages" for="monthly">
                          <p class="monthly-text" :style="[picked == 1 ? { color: '#000' } : '']">
                            Monthly
                          </p>
                          <p class="days-text">$11.99/mo.</p>
                          <p class="price-text" :style="[picked == 1 ? { color: '#000' } : '']">
                            Then $11.99/mo. Cancel Anytime
                          </p>
                        </label>
                        <input type="radio" id="monthly" name="subscription_plan" value="1" v-model="picked"
                          @change="changePlan()" :disabled="disableMonthly" v-bind:class="{
                            'disable-field': disableMonthly,
                          }" />
                      </div>
                    </form>
                  </div>
                  <div class="main-email-flex">
                    <div class="email-flex">
                      <h4 class="responsive-heading" v-if="this.user">
                        Logged in as
                      </h4>
                      <h4 v-else class="responsive-heading subscription_packages promo-code-label" for="annual">
                        Your Email
                      </h4>
                      <h4 class="responsive-heading subscription_packages promo-code-label already-believe-user"
                        for="annual">
                        <a class="responsive-heading" v-if="this.user" href="javascript:;" @click="logout()">Logout</a>

                        <a class="responsive-heading" v-else href="javascript:;" @click="login()">Already A Believe User?
                          Login</a>
                      </h4>
                    </div>
                    <input v-if="this.user" type="text" class="promo-code-label-input w-100 border-0 box-shadow-0" disabled
                      v-model="this.user.email" />

                    <input v-else type="text" class="promo-code-label-input w-100" v-model="email" />
                  </div>
                  <h4 class="subscription_packages text-left underline curp" for="annual"
                    v-if="!showPromo && this.checkSub == false" @click="showPromo = true">
                    Have a Promo code?
                  </h4>
                  <div v-if="showPromo" class="row position-relative pb-4 pl-2 pr-2">
                    <h4 class="subscription_packages promo-code-label text-left responsive-heading" for="annual">
                      Enter Your Promo code
                    </h4>
                    <div class="flex-promo-div">
                      <input type="text" class="promo-code-label-input" :class="[
                        link_access && picked == link_package
                          ? 'custom-disabled'
                          : '',
                      ]" v-model="promo_code" :disabled="link_access && picked == link_package" />
                      <!-- <span class="promo-code-text"
                      >Jan10 <span class="promo-applied">Applied</span></span
                    > -->

                      <button :disabled="link_access && picked == link_package" type="button" :class="[
                        link_access && picked == link_package
                          ? 'custom-disabled'
                          : '',
                      ]" class="promo-code-label-btn" @click="applyPromo()">
                        Apply
                      </button>
                      <button :disabled="link_access && picked == link_package" type="button" :class="[
                        link_access && picked == link_package
                          ? 'custom-disabled'
                          : '',
                      ]" class="btn btn-danger text-white" @click="cancelPromo()">
                        Cancel
                      </button>
                    </div>
                    <div v-if="promo_error_message" class="text-danger">
                      {{ promo_error_message }}
                    </div>
                    <div v-if="promo_success_message" class="text-success">
                      {{ promo_success_message }}
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col">
                    <div class="paypal_btn_div text-center">
                      <!-- <p>Sign in to Paypal to Continue</p> -->
                      <div class="total">
                        <div class="row total-top">
                          <div class="col-md-6">
                            <h6 class="responsive-heading">{{ subtotalText }}</h6>
                          </div>
                          <div class="col-md-6 text-right">
                            <p class="responsive-heading">$ {{ actual_price }}</p>
                          </div>
                        </div>
                        <div class="row total-top color-discount mt-0">
                          <div class="col-md-6">
                            <h6 class="responsive-heading">{{ discountText }}</h6>
                          </div>
                          <div class="col-md-6 text-right">
                            <p class="responsive-heading">$ {{ discount }}</p>
                          </div>
                        </div>

                        <div class="row total-middle mt-0">
                          <div class="col-md-6">
                            <h4 class="responsive-heading">{{ totalText }}</h4>
                          </div>
                          <div class="col-md-6 text-right">
                            <span class="responsive-heading">
                              $ {{ $store.state.final_price }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="checkSub && this.preventUpgrade()" class="col-12 payment_btn">
                  <a @click="upgradePlan()" href="#" class="main-btn banner-cta empty-2">
                    <span v-if="this.platform == 'stripe'">{{
                      changePlanText
                    }}</span>
                    <router-link v-if="this.platform == 'app_store' ||
                        this.platform == 'play_store'
                        " to="/help">Manage Subscriptions
                    </router-link>
                  </a>
                </div>
                <div v-if="!this.checkSub" class="payment_cards mt-3">
                  <!-- <h4 class="text-left">Choose payment method</h4> -->
                  <div class="row payment-methods mt-2">
                    <div class="col-md-12 stripe-button-col">
                      <button :disabled="promo_error_message && promo_error_message == 'Invalid promo code.'"
                        class="payment_btn" type="button" @click="
                          setPaymentMethod('stripe'),
                          (show_paypal = false),
                          (showCD = false)
                          ">
                        <span class=""> Subscribe Now </span>
                        <!-- <img
                          src="../../public/assets/images/icons/visa-logo.png"
                          width="25%"
                          alt=""
                        /> -->
                      </button>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <p>
                      {{ actual_text_4 }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- pp -->
      </section>
      <!-- Content -->

      <!-- Content END-->
    </div>
  </template>
  <script>
  import MainHeader from "../components/MainHeader.vue";
  import LoginModal from "../Modal/LoginModal.vue";
  import API from "../config/api";
  import loadingOverlay from '../components/loadingOverlay.vue';

  export default {
    components: {
      MainHeader,
      loadingOverlay
    },
    data() {
      return {
        loading: false,
        user: "",
        picked: "",
        promo_token: null,
        promo_code: null,
        payment_method: null,
        show_paypal: false,
        alert_primary_message: false,
        promo_error_message: "",
        promo_success_message: "",
        promoSuccess: null,
        link_access: false,
        has_promo: false,
        link_package: null,
        link_promo: null,
        promoManually: false,
        email: "",
        showCD: false,
        showPromo: false,
        checkSub: false,
        disableYearly: null,
        disableMonthly: null,
        platform: null,
        subType: null,
        subStatus: null,
        trialPeriod: null,
        platformSpecificId: null,
        subPid: null,
        stripeYearlyProductId: "prod_OgDz8g3XUOygz2",
        stripeMonthlyProductId: "prod_OgE2yrolPFLqeX",
        // IosYearlyProductId: "31012023",
        // IosMonthlyProductId: "3101202313",
        // AndroidMonthlyProductId: "25102022653",
        // AndroidYearlyProductId: "29102022152",

        IosYearlyProductId: "114092023",
        IosMonthlyProductId: "014092023",
        AndroidMonthlyProductId: "monthly_014092023",
        AndroidYearlyProductId: "yearly_114092023",
        activeSub: [],
        maxDate: null,
        // hidePaypal: false
      };
    },
    computed: {
      actual_price() {
        return this.picked == "1"
          ? "11.99"
          : this.picked == "2"
            ? "77.99"
            : "0.00";
      },
      actual_text_1() {
        return this.picked == "1"
          ? "Unlock 30 days of Believe Premium"
          : "Unlock 12 months of Believe Premium";
      },
      actual_text_2() {
        return this.picked == "1"
          ? "Then $11.99 monthly renewing subscription"
          : this.picked == "2" &&
            this.checkSub == false &&
            this.promo_code == null
            ? "14-Day Free Trial, then $77.99 annually renewing subscription"
            : "Then $77.99 annually renewing subscription";
      },
      actual_text_3() {
        // return this.picked == "1"
        //   ? `The monthly membership give you unlimited access to Believe's entire library of content.  You will be charged, $11.99 today, then $11.99 after the first 30 Days, which will renew monthly.`
        //   : "The yearly membership give you unlimited access to Believe's entire library of content.  You will be charged, $0.00 today, then $77.99 after the first 14 Days, which will renew annually.";
        if (this.checkSub == false) {
          if (this.picked == "2") {
            if (this.has_promo || this.promoManually) {
              return `The yearly membership give you unlimited access to Believe's entire library of content.  You will be charged, $${this.$store.state.final_price} today, then $77.99, which will renew annually.`;
            } else {
              return "The yearly membership give you unlimited access to Believe's entire library of content.  You will be charged, $0.00 today, then $77.99 after the first 14 Days, which will renew annually.";
            }
          }
          if (this.picked == "1") {
            if (this.has_promo || this.promoManually) {
              return `The monthly membership give you unlimited access to Believe's entire library of content.  You will be charged, $${this.$store.state.final_price} today, then $11.99 after the first 30 Days, which will renew monthly.`;
            } else {
              return "The monthly membership give you unlimited access to Believe's entire library of content.  You will be charged, $11.99 today, then $11.99 after the first 30 Days, which will renew monthly.";
            }
          } else {
            return "The yearly membership give you unlimited access to Believe's entire library of content.  You will be charged, $0.00 today, then $77.99 after the first 14 Days, which will renew annually";
          }
        } else {
          // console.log("Free User");
          return "The yearly membership give you unlimited access to Believe's entire library of content.  You will be charged, $0.00 today, then $77.99 after the first 14 Days, which will renew annually";
        }
      },
      actual_text_4() {
        // if (this.checkSub == false) {
        if (this.picked == "2") {
          if (this.has_promo || this.promoManually) {
            return `*$${this.$store.state.final_price} today, then $77.99, which will renew annually. Cancel any time by going to Manage Subscriptions page.`;
          } else if (this.checkSub == true) {
            return "$77.99 1 year subscription, will renew annually at $77.99 Cancel any time by going to Manage Subscriptions page.";
          } else {
            return "*$0.00 today, $77.99 after 14 Days, which will renew annually. Cancel any time by going to Manage Subscriptions page.";
          }
        }
        if (this.picked == "1") {
          if (this.has_promo || this.promoManually) {
            return `$${this.$store.state.final_price} for 30 day subscription,  Will renew monthly at $11.99 Cancel any time by going to Manage Subscriptions page.`;
          } else {
            return "$11.99 for 30 day subscription,  Will renew monthly at $11.99 Cancel any time by going to Manage Subscriptions page.";
          }
        } else {
          return "$77.99 1 year subscription, will renew annually at $77.99 Cancel any time by going to Manage Subscriptions page.";
        }
        // } else {
        //   // console.log("Free User");
        //   return "$77.99 1 year subscription, will renew annually at $77.99 Cancel any time by going to Manage Subscriptions page.";
        // }
      },
      discount() {
        if (this.checkSub == false) {
          if (this.picked == "2") {
            if (this.has_promo || this.promoManually) {
              return parseFloat(
                this.actual_price - this.$store.state.final_price
              ).toFixed(2);
            } else {
              return "0.00";
            }
          }
          if (this.picked == "1") {
            if (this.has_promo || this.promoManually) {
              return parseFloat(
                this.actual_price - this.$store.state.final_price
              ).toFixed(2);
            } else {
              return "0.00";
            }
          } else {
            return "0.00";
          }
        } else {
          // console.log("Free User");
          return "0.00";
        }
      },
      getHead() {
        return this.checkSub == true ? "View All Plans" : "Choose Your Plan";
      },
      changePlanText() {
        return this.subType == "Monthly"
          ? "Upgrade Your Plan"
          : this.subType == "Yearly"
            ? "Downgrade Your Plan"
            : "";
      },
      noTrial() {
        if (this.subType == "Monthly") {
          return "No Trial";
        } else if (!this.checkSub) {
          if (this.has_promo || this.promoManually) {
            return "No Trial";
          }
          else if (this.$store.state?.user?.trial_used != 1) {
            return "14 Day Free Trial";
          }
          return "";
        } else {
          return "";
        }
      },
      subtotalText() {
        if (this.picked == "2" && this.checkSub == false) {
          if (this.promoSuccess != true) {
            return "1 Year Believe Premium Due in 14 Days";
          } else {
            return "1 Year Believe Premium";
          }
        } else if (this.picked == "1" && this.checkSub == false) {
          return "30 Days of Believe Premium";
        } else {
          return "Subtotal";
        }
      },
      discountText() {
        if (this.picked == "2" && this.checkSub == false) {
          if (this.promoSuccess != true) {
            return "14-Day Trial (Cancel Anytime)";
          } else {
            return "Discount";
          }
        } else {
          return "Discount";
        }
      },
      totalText() {
        if (this.picked == "2" && this.checkSub == false) {
          if (this.promoSuccess != true) {
            return "Total Due Today";
          } else {
            return "Total After Discount";
          }
        } else if (this.picked == "1" && this.checkSub == false) {
          return "Total Due Today";
        } else {
          return "Total";
        }
      },
      substatus() {
        if (this.subStatus == "trial") {
          return "Trial Period Active";
        } else {
          return this.subStatus;
        }
      },
    },
    created() {
      this.getUserSubs();
      console.log('check created at : ' + this.$store.state.revcatSubType);
      this.user = this.$store.state.user;
      if (this.user == null) {
        this.user = JSON.parse(localStorage.getItem("user"));
      }
      if (typeof this.user.subscription_history == "undefined") {
        this.user = JSON.parse(localStorage.getItem("user"));
      }
      this.getUpdatedUser();
      this.getSessionPlan();
      this.getPromotoken();
      this.verify();
      this.$store.state.revcatSubType = "Free";
      this.getSubscribedPrice();
      this.getSubType();

      // this.getSubStatus();
      // this.getPlatform();
    },
    mounted() {
      this.promo_error_message = null;
      // console.log("promo error message :::: " , this.promo_error_message);
      // console.log('before checkSubscription');
      // this.checkSubscription();
      console.log("cc", this.preventUpgrade());
      // this.getUserSubs();
      // this.getPlatform();
      // this.$store.state.revcatSubType = "Free";
    },
    updated() {
      // console.log(this.preventUpgrade());
      // console.log("link package", this.link_package)
    },
    methods: {
      async getUpdatedUser() {
        try {
          const response = await API.post("/get-updated-user", {
            id: this.user.id,
          });
          // console.log("get user successfully", response.data.message);
          this.$store.state.user = null;
          localStorage.removeItem("user");
          localStorage.setItem("user", JSON.stringify(response.data.message));
          this.$store.state.user = response.data.message;
          console.log("message", response.data.message);
        } catch (error) {
          console.log(error);
        }
      },
      async getSessionPlan() {
        try {
          const response = await API.get(`/session-plan/get?user_id=${this.user.id}&token=${localStorage.getItem("token")}`);
          this.picked = response.data.entry.chosen_plan_id;
          if (response.data?.entry?.promo_code) {
            this.promo_code = response.data.entry.promo_code;
            this.applyPromo();
          }
        } catch (error) {
          console.log(error);
        }
      },
      async setSessionPlan() {
        try {
          const response = await API.post("/session-plan/create", {
            plan_id: this.picked,
            user_id: this.user.id,
            token: localStorage.getItem("token"),
            promo_code: this.promo_code,
            final_price: this.$store.state.final_price,
            payload: this.$store.state.subFormData[0],
          });
          console.log("message: ", response.data);
        } catch (error) {
          console.log(error);
        }
      },
      async getUserSubs() {
        try {
          this.loading = true;
          this.$store.state.sub_history = [];
          let array_1 = [];
          let array_2 = [];
          let array_3 = [];
          const sub_history = await API.get("/list-web-subscription");
          console.log('st fasdfasd fasdf asdf asdfas df asd fasdf asdf asdfasd 2: ', sub_history);
          if (sub_history?.status == 200) {
            this.loading = false;

            this.revsub = sub_history.data.subscriptions.subscriber.subscriptions;
            this.entitlement =
              sub_history.data.subscriptions.subscriber.entitlements;
            for (const [key, value] of Object.entries(this.revsub)) {
              console.log(key);
              array_1.push(value);
            }

            for (const [key, value] of Object.entries(this.entitlement)) {
              console.log(key);
              array_2.push(value);
            }
            const maxDate = new Date(
              Math.max(...array_2.map((e) => new Date(e.purchase_date)))
            );
            console.log("maxTime", maxDate);

            this.$store.state.sub_history = array_1.filter(function (data) {
              if (
                new Date(Math.max(new Date(data.purchase_date))).getTime() ==
                maxDate.getTime()
              ) {
                return true;
              }
            });

            localStorage.setItem('sub_history', JSON.stringify(this.$store.state.sub_history));

            this.checkSubscription();

            array_3 = array_2.filter(function (data) {
              if (
                new Date(Math.max(new Date(data.purchase_date))).getTime() ==
                maxDate.getTime()
              ) {
                return true;
              }
            });

            this.subPid = array_3[0].product_identifier;
            this.platform = this.$store.state.sub_history[0].store;
            console.log("platform: ", this.platform);
            this.subStatus = this.$store.state.sub_history[0].period_type;
            const givenDateString = this.$store.state.sub_history[0].expires_date;
            const givenDate = new Date(givenDateString);
            const currentDate = new Date();
            let check_expire_trial_yearly = false;
            let check_expire = false;
            if (this.subStatus == 'trial' && givenDate < currentDate) {
              check_expire_trial_yearly = true;
            }
            if(givenDate < currentDate){
              check_expire = true;
              this.subStatus = null;
            }
            if (this.$store.state.user.trial_used == 1) {
              this.subStatus = null;
            }

            // if (this.subStatus == 'trial' && givenDate < currentDate) {
            //   check_expire_trial_yearly = true;
            // }
            // if(givenDate < currentDate || this.$store.state.user.trial_used == 1){
            //   this.subStatus = null;
            // }

            console.log('sub status from plan mon ::::: ', this.$store.state.sub_history[0]);
            this.platformSpecificId =
              sub_history.data.subscriptions?.subscriber?.subscriber_attributes?.subscription_id?.value;
            console.log(sub_history.data.subscriptions);

            if (
              this.subPid == this.stripeMonthlyProductId ||
              this.subPid == this.IosMonthlyProductId ||
              this.subPid == this.AndroidMonthlyProductId
            ) {
              // this.$store.state.revcatSubType = "Monthly";
              if (check_expire) {
                this.$store.state.revcatSubType = "Free";
                localStorage.setItem('revcatSubType','Free');
              } else {
                this.$store.state.revcatSubType = "Monthly";
                localStorage.setItem('revcatSubType','Monthly');
              }
            } else if (
              this.subPid == this.stripeYearlyProductId ||
              this.subPid == this.IosYearlyProductId ||
              this.subPid == this.AndroidYearlyProductId
            ) {
              if (check_expire_trial_yearly || check_expire) {
                this.$store.state.revcatSubType = "Free";
                localStorage.setItem('revcatSubType','Free');
              } else {
                this.$store.state.revcatSubType = "Yearly";
                localStorage.setItem('revcatSubType','Yearly');
              }
            } else {
              this.$store.state.revcatSubType = "Free";
              localStorage.setItem('revcatSubType','Free');
            }
            this.getSubscribedPrice();
            this.getSubType();

            // console.log(this.$store.state.revcatSubType);
            this.stripe_subscription_id =
              sub_history.data.subscriptions?.subscriber?.subscriber_attributes?.subscription_id?.value;
          }
        } catch (error) {
          this.loading = false;
          console.log("not logged in");
        }
      },
      async upgradePlan() {
        try {
          if (this.platform == "app_store" || this.platform == "play_store") {
            this.$router.push("/help");
          } else {
            this.alert_primary_message = true;
            const response = await API.post("/update-stripe-subscription", {
              plan_id: this.picked,
              order_id: this.platformSpecificId,
              platform: this.platform,
            });
            console.log(response);
            this.$store.state.intent_generated = true;
            this.updateUser();
          }
        } catch (err) {
          this.$store.state.intent_generated = false;
          console.log(err);
        }
      },
      async verify() {
        if (this.$route?.query?.promo_code) {
          localStorage.setItem("promo_code", this.$route?.query?.promo_code);
          localStorage.setItem("has_promo", "true");
          this.has_promo = true;
          if (this.$route?.query?.package_type)
            localStorage.setItem(
              "package_type",
              this.$route?.query?.package_type
            );
        }
        this.$store.state.final_price = this.actual_price;
      },
      async updateUser() {
        try {
          this.alert_primary_message = true;
          const response = await API.post("/get-updated-user", {
            id: this.user.id,
          });
          this.$store.state.user = null;
          localStorage.removeItem("user");
          localStorage.setItem("user", JSON.stringify(response.data.message));
          this.$store.state.user = response.data.message;
          console.log(response.data.message);
          this.alert_primary_message = false;
          this.$router.push("/success-plan-changed");
          // window.location.reload();
        } catch (error) {
          console.log(error);
        }
      },
      getSubscribedPrice() {
        // console.log('pickkkkked :::: ', this.$store.state.revcatSubType);
        if (this.$store.state.revcatSubType == "Monthly") {
          this.picked = "1";
          this.$store.state.final_price = this.actual_price;
          this.disableMonthly = true;
          this.getHead;
          console.log("1");
        } else if (this.$store.state.revcatSubType == "Yearly") {
          this.picked = "2";
          this.$store.state.final_price = this.actual_price;
          this.disableYearly = true;
          this.getHead;
          console.log("2");
        } else if (this.$store.state.revcatSubType == "Free") {
          console.log("3");
          this.getPromotoken();
          if (this.getPromotoken() == false) {
            this.picked = "2";
            this.$store.state.final_price = "0.00";
          } else {
            this.picked =
              this.$route?.query?.package_type ??
              this.$store.state.selected_package ??
              "2";
            this.$store.state.final_price = this.actual_price;
          }
          this.getHead;
          // console.log(this.$store.state.selected_package);
        }
      },
      preventUpgrade() {
        if (this.$store.state.revcatSubType == "Monthly" && this.picked == "1") {
          return false;
        } else if (
          this.$store.state.revcatSubType == "Yearly" &&
          this.picked == "2"
        ) {
          return false;
        } else if (this.subType == "Yearly") {
          return false;
        } else {
          return true;
        }
      },
      getSubType() {
        this.subType = this.$store.state.revcatSubType;
      },
      // getSubStatus() {
      //   if (this.user.subscription_history == null) {
      //     this.subStatus = null;
      //   } else {
      //     this.subStatus = this.user.subscription_history.status;
      //   }
      // },
      getPromotoken() {
        this.picked =
          this.$route?.query?.package_type ??
          this.$store.state.selected_package ??
          2;
        if (this.$route?.query?.promo) {
          this.promo_token = this.$route?.query?.promo;
        }
        if (this.$route?.query?.promo_code) {
          this.promo_code = this.$route?.query?.promo_code;
          this.applyPromo();
          this.link_access = true;
          this.link_package = this.picked;
          this.link_promo = this.promo_code;
          return true;
        } else {
          return false;
        }
      },
      checkSubscription() {
        console.log('tic tic tic: ', this.$store.state.sub_history);
        // old code that check subscription from our revenuecat with expiration
        const subscription = this.$store.state.sub_history;
        console.log('under check sub 1 :', subscription);
        if (subscription && subscription.length > 0) {
          console.log('under check sub 2 :', subscription.length);
          const givenDateString = subscription[0]?.expires_date;
          const givenDate = new Date(givenDateString);
          const currentDate = new Date();
          console.log("check subscription check sub mounted run before if :::: ", givenDate, currentDate);
          if (givenDate > currentDate) {
            this.checkSub = true;
            console.log("check subscription check sub mounted run :::: ", this.checkSub);
          } else {
            this.checkSub = false;
            console.log("check subscription check sub mounted run else:::: ", this.checkSub);
          }
        }

        // old code that check subscription from our db
        // if (this.user.revenuecat_customer_id == null) {
        //   this.checkSub = false;
        // } else {
        //   this.checkSub = true;
        // }
      },
      setPaymentMethod(payment_method) {
        console.log(payment_method);
        if (this.user) {
          this.payment_method = payment_method;
          this.$store.state.intent_generated = null;
          this.$store.state.general_error = null;
          this.$store.state.subFormData[0]["text1"] = this.actual_text_1;
          this.$store.state.subFormData[0]["text2"] = this.actual_text_2;
          this.$store.state.subFormData[0]["text3"] = this.actual_text_3;
          this.$store.state.subFormData[0]["planId"] = this.picked;
          this.$store.state.subFormData[0]["promo_code"] = this.promo_code;
          this.$store.state.subFormData[0]["promo_token"] = this.promo_token;
          this.$store.state.subFormData[0]["actual_price"] = this.actual_price;
          this.$store.state.subFormData[0]["discount"] = this.discount;
          this.$store.state.subFormData[0]["intent_generated"] = this.$store.state.intent_generated;
          this.setSessionPlan();
          this.$router.push("/payment");
        } else this.login();
      },
      checkPaymentMethod() {
        if (this.payment_method != null) {
          return true;
        } else {
          return false;
        }
      },
      async applyPromo() {
        try {
          this.disableMonthly = true;
          this.disableYearly = true;

          this.checkSubscription();
          console.log('apply promo ::::::: ' , this.checkSub);
          if (this.checkSub == true) {
            console.log('apply promo under if 1 ::::::: ' , this.checkSub);
            this.showPromo = true;
            this.promo_error_message = "Invalid User";
            return false;
          }
          this.promo_error_message = null;
          this.promo_success_message = null;
          this.showPromo = true;
          if (
            this.promo_code != null &&
            this.promo_code != "" &&
            this.checkSub == false
          ) {
            const response = await API.post("/apply-promo-code", {
              promo_code: this.promo_code,
              plan_id: this.picked,
            });
            // this.hidePaypal = true;
            this.promoManually = true;
            this.promo_success_message = response.data.message;
            this.$store.state.final_price = response.data.final_price.toFixed(2);
            this.promoSuccess = true;
            this.link_package = response.data.plan_id;
            console.log("final price", response);

            this.disableMonthly = true;
            this.disableYearly = true;
          }
        } catch (err) {
          this.promoManually = false;
          this.promo_error_message =
            err.response.data.message ?? "Something went wrong.";
        }
      },
      async cancelPromo() {
        this.promo_code = null;
        this.promoManually = false;
        // this.hidePaypal = false;
        this.promo_success_message = "";
        this.promo_error_message = null;
        if(this.picked == "1"){
          this.$store.state.final_price = "11.99";
        }else if(this.picked == "2"){
          this.$store.state.final_price = "77.99";
        }
        this.disableMonthly = false;
        this.disableYearly = false;
        this.showPromo = false;
        // this.$store.state.final_price = "0.00";
        return true;
      },
      showPaypal(flag = true) {
        this.show_paypal = flag;
        // if (this.$store.state.final_price == "0.00"){
        //   this.$store.state.final_price = this.actual_price;
        // }
      },
      changePlan() {
        console.log('change plan 1 ::: ' , this.$store.state.revcatSubType);
        console.log('change plan 2 ::: ' , this.picked);
        if (
          (this.$store.state.revcatSubType == "Yearly" && this.picked == "2") ||
          (this.$store.state.revcatSubType == "Monthly" && this.picked == "1")
        ) {
          console.log("Cannot subscribed twice");
        } else if (
          this.$store.state.revcatSubType == "Free" &&
          this.picked == "2"
        ) {
          this.$store.state.final_price = "0.00";
        } else {
          this.promo_code = null;
          this.payment_method = null;
          this.showPaypal(false);
          this.$store.state.final_price = this.actual_price;
          this.promo_error_message = null;
          this.promo_success_message = null;
          if (this.link_access && this.picked == this.link_package) {
            this.promo_code = this.link_promo;
            this.applyPromo();
          }
          console.log(this.$store.state.final_price, this.picked);
        }
      },
      logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("promo_code");
        localStorage.removeItem("has_promo");
        localStorage.removeItem("package_type");
        this.user = null;
        this.$store.state.user = null;
        this.$router.push("/login");
      },
      login() {
        this.$modal.show(LoginModal, {}, { height: "auto" });
      },
    },
  };
  </script>
  <style>
  .payment-wrapper .paypal-button-col .payment_btn,
  .payment-wrapper .stripe-button-col .payment_btn {
    width: 100%;
    text-align: start;
    display: block;
    padding-left: 20px;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0px;
    background: transparent;
    cursor: pointer;
    border-color: #00000080;
  }

  .payment-wrapper .paypal-button-col .payment_btn:focus,
  .payment-wrapper .stripe-button-col .payment_btn:focus {
    outline: none;
  }

  .payment-wrapper .payment_btn {
    font-size: 18px;
  }

  .payment-wrapper .payment_btn span {
    padding-left: 10px;
  }

  .payment-wrapper .payment_cards .payment-methods .col-md-6 {
    border: 1px solid black;
    border-radius: 5px;
  }

  .payment-wrapper .stripe-button-col .payment_btn {
    border-top-left-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
  }

  .payment-wrapper .payment_cards .payment-methods {
    margin: 0;
  }

  .payment-wrapper .payment_cards h4 {
    color: #071649;
    font-size: 22px;
    font-weight: 400;
  }

  .payment-wrapper .payment-methods .col {
    padding: 0;
  }

  .payment-wrapper .payment_cards {
    position: relative;
    padding: 25px 30px;
    background: rgba(61, 188, 184, 0.1);
    backdrop-filter: blur(4.5px);
    border-radius: 10px;
    width: 85%;
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: start;
  }

  .payment-wrapper .payment_cards .subscription-form-wrap form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }

  .payment-wrapper .payment_cards .subscription-form-wrap form .subscription_packages p.days-text {
    color: #3dbcb8;
    font-size: 22px;
  }

  .payment-wrapper .payment_cards .subscription-form-wrap form .subscription_packages p {
    font-size: 16px;
  }

  .payment-wrapper .payment_cards .subscription-form-wrap form input[type="checkbox"] {
    height: 35px;
    width: 35px;
  }

  .payment-wrapper .payment_cards .subscription-form-wrap form input[type="checkbox"]::before {
    height: 35px;
    width: 35px;
  }

  .payment-wrapper .payment_cards .subscription-form-wrap form .custom-select-box {
    width: 48%;
  }

  p.package_price_para {
    font-size: 20px;
    line-height: 1.2em;
    margin-top: 20px;
  }

  .package_price span {
    font-size: 34px;
    font-weight: 600;
  }

  .package_price {
    margin-bottom: 30px;
  }

  section.payment-wrapper {
    margin: 90px 0 0 0;
  }

  section.payment-wrapper section#pricings {
    background-color: #3dbcb826;
  }

  .step_form .wizard-header {
    display: none;
  }

  .step_form .wizard-icon-container {
    background: linear-gradient(184deg, #03174c, #051c50 10%, #45c5af 100%);
  }

  .step_form .vue-form-wizard .wizard-card-footer button {
    padding: 10px 25px;
    border: none;
    background: linear-gradient(184deg, #03174c, #051c50 10%, #45c5af 100%);
  }

  .step_form .pricing-btn {
    text-align: left;
  }

  .step_form .pricing-btn a {
    background: linear-gradient(184deg, #03174c, #051c50 10%, #45c5af 100%);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 16px;
    font-weight: 500;
  }

  .step_form .vue-form-wizard .stepTitle {
    background: linear-gradient(184deg, #03174c, #051c50 10%, #45c5af 100%);
    -webkit-background-clip: text;
    color: transparent !important;
    margin-top: 10px;
  }

  .promo-code {
    text-align: left;
    margin-bottom: 30px;
  }

  .promo-code h4 span {
    font-weight: 200;
    padding-left: 5px;
  }

  .promo-code input[type="text"] {
    padding: 5px;
    border-radius: 2px;
    border: 0.5px solid #d1d1d1;
    width: 200px;
  }

  .payment-merchant-btn button {
    width: 100%;
    display: block;
    font-size: 18px;
    font-weight: 500;
    padding: 15px;
    border: none;
    text-transform: uppercase;
    border-radius: 2px;
  }

  .vue-form-wizard .wizard-tab-content {
    min-height: 100px;
    padding: 30px 20px 30px !important;
  }

  .payment-merchant-btn h4:before {
    content: "";
    background: black;
    width: 35%;
    height: 2px;
    position: absolute;
    top: 40%;
    left: 0;
  }

  .payment-merchant-btn h4:after {
    content: "";
    background: black;
    width: 35%;
    height: 2px;
    position: absolute;
    top: 40%;
    right: 0;
  }

  .payment-merchant-btn button.stripe-btn {
    background: #3395ce;
    color: white;
    cursor: pointer;
  }

  a {
    cursor: pointer;
  }

  .custom-select-box input[type="radio"]::before {
    content: "";
    display: block;
    position: absolute;
    text-align: center;
    height: 35px;
    width: 35px;
    left: 0;
    top: 0px;
    background-color: #3dbcb840;
    -webkit-clip-path: circle(65% at 50% 50%);
    clip-path: circle(65% at 50% 50%);
  }

  .custom-select-box input[type="radio"] {
    position: absolute;
    height: 35px;
    width: 35px;
    right: 5%;
    top: 35%;
    border: transparent;
    outline: transparent;
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    accent-color: #0c5185;
    -webkit-clip-path: circle(65% at 50% 50%);
    clip-path: circle(65% at 50% 50%);
    background-color: #ceeeed;
  }

  .custom-select-box input[type="radio"]:checked {
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    background: url(../../public/assets/images/icons/tick.png) no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-clip-path: circle(65% at 50% 50%);
    clip-path: circle(65% at 50% 50%);
  }

  input.promo-code-label-input {
    height: 42px;
    width: 70%;
    padding: 10px;
    background-color: var(--colorBackground);
    border-radius: 5px;
    transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
      color 0.15s ease;
    border: 1px solid #00000080;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
    display: inline-block;
    color: black;
  }

  button.promo-code-label-btn,
  button.site-button {
    background: #071649;
    width: 26%;
    display: inline-block;
    text-align: center;
    color: #3dbcb8;
    padding: 10px 0;
    border-radius: 0.25rem;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "Rubik";
    font-weight: 500;
    box-shadow: 0px 6px 8px rgb(0 0 0 / 25%);
    text-decoration: none;
    margin-left: 10px;
  }

  button.site-button {
    width: 100% !important;
    padding: 20px 0;
    margin: 10px 0px 0px 0px;
  }

  a.paypal_btn_payment {
    width: 100%;
    display: block;
    padding: 10px;
    border-radius: 5px;
    background: #edb116;
  }

  .paypal_btn_div p {
    color: #00000080;
    font-family: "Rubik";
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
  }

  .login-form.popup {
    max-width: 100% !important;
    margin: 0;
  }

  input.promo-code-label-input.custom-disabled {
    background: #f1f1f1b3;
    border-color: #b5b5b5;
    color: grey;
  }

  button.promo-code-label-btn.custom-disabled {
    background: #f1f1f1b3;
    color: gray;
    pointer-events: visiblefill;
    box-shadow: none;
  }

  /* .already-believe-user a {
    color: #042051;
    font-size: 16px;
  } */
  .payments-plans .custom-select-box {
    position: relative;
    width: 100%;
    text-align: left;
  }

  .payments-plans h3 {
    color: #071649;
  }

  .payments-plans .subscription_packages p {
    font-size: 16px;
  }

  .payments-plans h4 {
    font-size: 20px;
    font-weight: 400;
    color: #071649;
  }

  span.promo-code-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 16px;
    color: #ff00dd;
  }

  .total .total-top h6,
  .total .total-top p {
    font-size: 20px;
  }

  .payments-plans .paypal-button-col img,
  .payments-plans .gpay-button-col img {
    width: 100px;
    height: 30px;
    object-fit: contain;
  }

  .payments-plans .paypal-button-col button,
  .payments-plans .stripe-button-col button,
  .payments-plans .gpay-button-col button {
    border-radius: 5px;
    width: 100%;
    padding: 10px;
  }

  .payments-plans .gpay-button-col button {
    background-color: #03174c;
  }

  .payments-plans .paypal-button-col button {
    background-color: #ffc439;
  }

  .payments-plans .stripe-button-col button {
    background: #071649;
    display: block;
    text-align: center;
    color: #3dbcb8;
    padding: 10px 0;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 18px;
    font-family: "Rubik";
    font-weight: 500;
    margin-top: 20px;
    box-shadow: 0px 6px 8px rgb(0 0 0 / 25%);
  }

  input[type="radio"] {
    cursor: pointer;
  }

  .email-flex {
    display: flex;
    justify-content: space-between;
  }

  .main-email-flex {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 50px 0px 50px 0px;
  }

  .flex-promo-div {
    display: flex;
    width: 100%;
    column-gap: 10px;
  }
  </style>
