<template>
  <div id="app">
    <div>
      <!-- <loading :active="$store.state.isLoading" :can-cancel="false" :is-full-page="true"></loading> -->
      <router-view></router-view>
      <!-- <stripe-card /> -->
    </div>
  </div>
</template>

<script>
// import StripeCard from './components/StripeCard.vue';
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
import API from "./config/api";

export default {
  components: {
    // StripeCard
    // Loading,
  },
  created() {
    this.verifyUserToken();
    this.verify();

  },
  mounted() {
    const sub_history = localStorage.getItem('sub_history');
    this.$store.state.sub_history = JSON.parse(sub_history);

    // const script = document.createElement('script');
    // script.type = 'text/javascript';
    // script.src = 'https://diffuser-cdn.app-us1.com/diffuser/diffuser.js';
    // script.async = true;
    // script.onload = this.initializeActiveCampaign;

    // this.$nextTick(() => {
    //   document.head.appendChild(script);
    // });
  },
  methods: {
    async verifyUserToken() {
      try {
        let user = JSON.parse(localStorage.getItem("user"));
        let myToken = localStorage.getItem("token");
        const response = await API.post("/verify-user-token", {
          id: user.id,
          token: myToken
        });
        if (response.data.status == false) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("promo_code");
          localStorage.removeItem("has_promo");
          localStorage.removeItem("package_type");
          this.$store.state.user = null;
        }
        console.log("token resp: ", response);
      } catch (error) {
        console.log(error);
      }
    },
    async verify() {
      try {
        const response = await API.get("/verify");
        this.$store.state.user = response.data;
        console.log("res", response.data);
        const sub_history = await API.get("/list-web-subscription");
        this.$store.state.sub_history = sub_history.data.subscriptions;
      } catch (error) {
        console.log("not logged in.");
      }
    },
    // initializeActiveCampaign() {
    //   if (typeof window.vgo !== 'undefined') {
    //     window.vgo('setAccount', '8');
    //     window.vgo('setTrackByDefault', true);
    //     window.vgo('process');
    //   }
    // },
  },
};
</script>

<!-- <style>

.main-terms-content {
    padding-top: 0px !important;
}

</style> -->
