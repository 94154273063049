<template>
  <VueSlickCarousel v-bind="settings">
    <div class="reviews">
      <div class="ratings-stars">
        <ul class="stars">
          <li><i class="fa fa-star"></i></li>
          <li><i class="fa fa-star"></i></li>
          <li><i class="fa fa-star"></i></li>
          <li><i class="fa fa-star"></i></li>
          <li><i class="fa fa-star"></i></li>
        </ul>
        <!-- <img src="../../public/assets/images/stars.png" /> -->
      </div>
      <h6 class="review-title pb-3 pt-2">Second to none..</h6>
      <p class="review-text">
        Just when you think you’ve seen it all from Victoria Marie Gallagher,
        she “one ups” herself for the benefit of us all with HypnoCloud.
      </p>
      <div class="client-avatar pt-3">
        <div class="client-img">
          <img src="../../public/assets/images/clients/client-1.png" alt="" />
        </div>
        <div class="client-excerpt">
          <h6 class="client-name">The Self Center</h6>
          <p class="review-date">08/03/2019</p>
          <p class="review-type">Review On Apple store</p>
        </div>
      </div>
    </div>

    <div class="reviews">
      <div class="ratings-stars">
        <ul class="stars">
          <li><i class="fa fa-star"></i></li>
          <li><i class="fa fa-star"></i></li>
          <li><i class="fa fa-star"></i></li>
          <li><i class="fa fa-star"></i></li>
          <li><i class="fa fa-star"></i></li>
        </ul>
        <!-- <img src="../../public/assets/images/stars.png" /> -->
      </div>
      <h6 class="review-title pb-3 pt-2">Must Have App to Change Your Life</h6>
      <p class="review-text">
        I have been listening to Victoria’s hypnosis sessions for a couple of
        years now and I can tell you first hand that this (almost) daily
        practice has been a critical component of me completely changing my
        life.
      </p>
      <div class="client-avatar pt-3">
        <div class="client-img">
          <img src="../../public/assets/images/clients/client-2.png" alt="" />
        </div>
        <div class="client-excerpt">
          <h6 class="client-name">Lisa M Williams</h6>
          <p class="review-date">08/14/2019</p>
          <p class="review-type">Review On Apple store</p>
        </div>
      </div>
    </div>

    <div class="reviews">
      <div class="ratings-stars">
        <ul class="stars">
          <li><i class="fa fa-star"></i></li>
          <li><i class="fa fa-star"></i></li>
          <li><i class="fa fa-star"></i></li>
          <li><i class="fa fa-star"></i></li>
          <li><i class="fa fa-star"></i></li>
        </ul>
        <!-- <img src="../../public/assets/images/stars.png" /> -->
      </div>
      <h6 class="review-title pb-3 pt-2">
        I wish I could give more than 5 stars..
      </h6>
      <p class="review-text">
        First app review I have ever written.. I have been using apps for
        meditation, wellness and LOA for at least five years now and this one is
        the best I’ve Ever seen.
      </p>
      <div class="client-avatar pt-3">
        <div class="client-img">
          <img src="../../public/assets/images/clients/client-3.webp" alt="" />
        </div>
        <div class="client-excerpt">
          <h6 class="client-name">jasonhayward</h6>
          <p class="review-date">08/23/2021</p>
          <p class="review-type">Review On Google Play</p>
        </div>
      </div>
    </div>

    <!-- <div class="reviews">
          <div class="ratings-stars">
            <ul class="stars">
              <li><i class="fa fa-star"></i></li>
              <li><i class="fa fa-star"></i></li>
              <li><i class="fa fa-star"></i></li>
              <li><i class="fa fa-star"></i></li>
              <li><i class="fa fa-star"></i></li>
            </ul>
          </div>
          <h6 class="review-title pb-3 pt-2">
            I wish I could give more than 5 stars..
          </h6>
          <p class="review-text">
            First app review I have ever written.. I have been using apps for
            meditation, wellness and LOA for at least five years now and this
            one is the best I’ve Ever seen.
          </p>
          <div class="client-avatar pt-3">
            <div class="client-img">
              <img
                src="../../public/assets/images/clients/client-3.png"
                alt=""
              />
            </div>
            <div class="client-excerpt">
              <h6 class="client-name">jasonhayward</h6>
              <p class="review-date">08/23/2021</p>
              <p class="review-type">Review On Google Play</p>
            </div>
          </div>
        </div>
       -->
  </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "MyComponent",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 9000,
        autoplaySpeed: 9000,
        cssEase: "linear",
        pauseOnHover: true,
        pauseOnFocus: true,
        adaptiveHeight: true,
        focusOnSelect: true,
        waitForAnimate: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style>
.slick-slide {
  margin: 0 20px;
}
@media (max-width: 600px) {
  .reviews {
    min-height: 550px;
  }
}
</style>
