<template>
  <div class="login-form popup">
    <div class="tab-content nav justify-content-center">
      <div id="login" class="tab-pane active text-center">
        <form class="dez-form pt-5 pb-5">
          <div class="back-wrap" v-if="form_type == 'register'">
            <a @click="changeFormType('login')" href="javascript:;" class="site-button outline gray active">
              <span class="back_btn"><i class="fa fa-arrow-left"></i> </span>
            </a>
          </div>
          <h3 v-if="form_type == 'login'" class="form-title m-t0">Log In</h3>
          <h3 v-if="form_type == 'register'" class="form-title">Sign Up</h3>
          
          <div class="dez-separator-outer m-b5">
            <div class="dez-separator bg-primary style-liner"></div>
          </div>
          <p class="mb-2">Enter your email address and your password.</p>
          <div v-if="error_message" class="alert alert-danger" role="alert">
                {{ this.error_message }}
          </div>
          <div v-if="success_message" class="alert alert-success" role="alert">
            Logging in...
          </div>            
          <div v-if="form_type == 'register'" class="form-group">
            <input
              name="dzName"
              required
              class="form-control"
              placeholder="Full Name*"
              type="text"
              v-model="name"
            />
          </div>
          <div class="form-group">
            <input
              name="dzName"
              required
              class="form-control"
              placeholder="User email*"
              type="email"
              v-model="email"
            />
          </div>
          <div class="form-group">
            <input
              name="dzName"
              required
              class="form-control"
              placeholder="User password*"
              type="password"
              v-model="password"
            />
          </div>

          <div v-if="form_type == 'register'" class>
            <input
              name="dzName"
              required
              class="form-control"
              placeholder="Re-type Your Password*"
              type="password"
              v-model="retype_password"
            />
          </div>
          <div v-if="form_type == 'login'" class="form-group text-left">
            <button
              type="button"
              class="site-button main-btn m-r5 dz-xs-flex mt-2"
              @click="login"
            >
              <span>login</span>
            </button>

            <!-- <div class="m-t20 mt-2 text-center forgot-password">
              <label class="m-b-0 mb-0">
                <input id="check1" type="checkbox" />
                <label for="check1">Remember me</label>
              </label>
              <a data-bs-toggle="tab" href="#forgot-password" class="m-l10">
                <i class="fa fa-unlock-alt"></i> Forgot Password
              </a>
            </div> -->
          </div>
          <div v-else-if="form_type == 'register'" class="form-group text-left">
           
            <button
              type="button"
              class="site-button main-btn pull-right"
              @click="btn_register"
            >
              <span>Submit</span>
            </button>
          </div>
          <div class="dz-social clearfix">
            <h5 class="form-title m-t5 pull-left">Sign In With</h5>
            <ul
              class="dez-social-icon dez-border pull-right dez-social-icon-lg text-white"
            >
              <li>
                <button
                  type="button"
                  class="btn btn-sm"
                  href="javascript:;"
                  @click="facebookLogin()"
                >
                  <i class="fab fa-facebook-f"></i>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="btn btn-sm"
                  href="javascript:;"
                  @click="googleLogin()"
                >
                  <i class="fab fa-google-plus-g"></i>
                </button>
              </li>
            </ul>
          </div>
        </form>
        <div v-if="form_type=='login'" class="bottom create-account-btn-div">
          <a @click="changeFormType('register')" href="javascript:;" class="main-btns site-button">
            <span>Create an Account</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  //   createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  FacebookAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
} from "firebase/auth";

import API from "../config/api";
export default {
  data() {
    return {
      email: null,
      password: null,
      retype_password: null,
      form_type: "login",
      error_message: null,
      success_message: null
    };
  },
  methods: {
    async login() {
      try {
        const auth = getAuth();
        const firebase_response = await signInWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );
        const backend_response = await API.post("/firebase", {
          token: firebase_response?.user?.accessToken,
        });
        localStorage.setItem("token", backend_response.data.token);
        this.$store.state.user = backend_response.data.detail;
        // alert("Login successful.");
        let has_promo = localStorage.getItem("has_promo");
        if (has_promo == "true") {
          this.hasPromoRoute();
        }else{
          this.$router.push("/plans");
        }
        this.$modal.hideAll();
      } catch (error) {
        this.error_message = error.code.split("/")[1].toUpperCase()
        // alert("Invalid Details.");
      }
    },
    async facebookLogin() {
      const auth = getAuth();
      const provider = new FacebookAuthProvider();
      provider.addScope("email");
      provider.setCustomParameters({
        display: "popup",
      });

      // provider.addScope('user_birthday');
      signInWithPopup(auth, provider).then((result) => {
        // This gives you a Facebook Access Token.
        // var token = result.credential.accessToken;
        // The signed-in user info.

        API.post("/firebase", {
          token: result.user.accessToken,
        }).then((res) => {
          localStorage.setItem("token", res.data.token);
          this.$store.state.user = res.data.detail;
          // alert("Login successful.");
          let has_promo = localStorage.getItem("has_promo");
          if (has_promo == "true") {
            this.hasPromoRoute();
          }
          this.$modal.hideAll();
        });
      });
    },
    async googleLogin() {
      try {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();

        const google_response = await signInWithPopup(auth, provider);
        console.log(google_response);
        const response_token = google_response.user.accessToken;

        const backend_response = await API.post("/firebase", {
          token: response_token,
        });
        localStorage.setItem("token", backend_response.data.token);
        this.$store.state.user = backend_response.data.detail;
        // alert("Login successful.");
        let has_promo = localStorage.getItem("has_promo");
        if (has_promo == "true") {
          this.hasPromoRoute();
        }
        this.$modal.hideAll();
      } catch (error) {
        console.log(error);
      }
    },

    hasPromoRoute() {
      this.$modal.hideAll();
    },

    async btn_register() {
      try {
        const auth = getAuth();
        const firebase_response = await createUserWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );
        const backend_response = await API.post("/firebase", {
          token: firebase_response?.user?.accessToken,
          name: this.name,
        });
        localStorage.setItem("token", backend_response.data.token);
        this.$store.state.user = backend_response.data.detail;
        // alert("Register successful.");
        let has_promo = localStorage.getItem("has_promo");
        if (has_promo == "true") {
          this.$router.push(
            `/plans?promo_code=${localStorage.getItem("promo_code")}`
          );
        }
        this.$modal.hideAll();
      } catch (error) {
        this.error_message = error;
        // alert("Invalid Details.");
      }
    },

    changeFormType(formtype) {
      this.form_type = formtype;
    },
  },
};
</script>

<style scoped src="../../public/assets/css/login-page.css">
.login-form.popup {
  max-width: 100% !important;
  margin: 0;
}
</style>
