<template>
  <div class="main-banner banner-bg">
    <div class="container">
      <div class="row pt-5 pb-5 justify-content-center no-pad-mob">
        <div class="col-md-6 col-sm-12">
          <h1 class="banner_text">Believe</h1>
          <span class="banner-para"
            ><b>DREAM</b> it, <b>BELIEVE</b> it, and <b>ACHIEVE</b> your
            DESIRES</span
          >
          <ul class="banner-list">
            <li>Speed up your manifestations</li>
            <li>Access 1000+ powerful LOA hypnosis audios</li>
            <li>Members-only personal growth courses</li>
            <li>Videos, Scripts, Live Events + Much More</li>
            <li>Start Living a Happier, More Abundant life now</li>
          </ul>
          <!-- <div class="subscription-form-wrap">
            <form action="#1">
              <div class="form-group custom-select-box" :style="[$store.state.selected_package==2 ? {'background-color':'#0c5185'} : '']">
                <span class="best-value">Best Value</span>
                <label class="subscription_packages" for="annual">
                  <p class="monthly-text" :style="[$store.state.selected_package==2 ? {'color':'#fff'} : '']">Annual - Billed at $77.99</p>
                  <p class="days-text">14 days free</p>
                  <p class="price-text" :style="[$store.state.selected_package==2 ? {'color':'#fff'} : '']">$ 6.49/month</p>
                </label>
                <input value="2" type="radio" id="annual" name="subscription_plan" v-model="$store.state.selected_package"/>
              </div>
              <div class="form-group custom-select-box" :style="[$store.state.selected_package==1 ? {'background-color':'#0c5185'} : '']">
                <label class="subscription_packages" for="monthly">
                  <p class="monthly-text" :style="[$store.state.selected_package==1 ? {'color':'#fff'} : '']">Monthly</p>
                  <p class="days-text">Monthly payment</p>
                  <p class="price-text" :style="[$store.state.selected_package==1 ? {'color':'#fff'} : '']">$ 11.99/month</p>
                </label>

                <input value="1" type="radio" id="monthly" name="subscription_plan" v-model="$store.state.selected_package" />
              </div>
            </form>
          </div> -->
          <!-- <router-link :to="`/payments?package_type=${$store.state.selected_package}`" class="banner-cta">Try Believe For Free</router-link> -->
          <router-link
            v-if="$store.state.user == null"
            :to="`/register`"
            class="banner-cta"
            >Try BELIEVE Free</router-link
          >
          <router-link
            v-if="$store.state.user != null"
            :to="`/plans`"
            class="banner-cta"
            >Try BELIEVE Free</router-link
          >

          <!-- <a href="#" class="banner-cta">Get Started</a> -->
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="banner-images">
            <img
              class="img-1 img-fluid"
              src="../../public/assets/images/resource/iphone13mockup-3.webp"
              alt=""
            />
            <img
              class="img-2 img-fluid"
              src="../../public/assets/images/resource/iphone13mockup.webp"
              alt=""
            />
          </div>
          <ul class="latest-app-widget">
            <li style="margin-right:0px !important">
              <a href="https://play.google.com/store/apps/details?id=com.hyptalk.believe" target="_blank" title="" aria-label="Read more about Believe"
                ><img
                  class="img-fluid"
                  src="../../public/assets/images/resource/Googleplay.png"
                  alt="image"
              /></a>
            </li>
            <li>
              <a href="https://apps.apple.com/us/app/believe-hypnosis/id6466321566" target="_blank" title="" aria-label="Read more about Believe"
                ><img
                  class="img-fluid"
                  src="../../public/assets/images/resource/App-Store.png"
                  alt="image"
              /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: this.$store.state.selected_package,
    };
  },
};
</script>
<style>
.banner-bg {
  padding: 30px 0 0 0;
}
.subscription_packages p {
  margin: 0;
}

.custom-select-box {
  width: 78%;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  backdrop-filter: blur(5.27143px);
  border-radius: 20px;
  background: white;
  padding: 30px 25px 15px 25px;
}
.custom-select-box label {
  margin: 0;
}
.custom-select-box input[type="checkbox"] {
  position: absolute;
  height: 45px;
  width: 45px;
  right: 5%;
  top: 35%;
  border: transparent;
  outline: transparent;
  appearance: none;
  -webkit-appearance: none;
  accent-color: #0c5185;
  clip-path: circle(65% at 50% 50%);
}
.custom-select-box input[type="checkbox"]:checked {
  appearance: auto;
  -webkit-appearance: auto;

  clip-path: circle(65% at 50% 50%);
}
input[type="checkbox"]::before {
  content: "";
  display: block;
  position: absolute;
  text-align: center;
  height: 45px;
  width: 45px;
  left: 0;
  top: 0px;
  background-color: #3dbcb840;

  clip-path: circle(65% at 50% 50%);
}

.subscription_packages p {
  margin: 0;
  color: #000000bf;
  font-size: 18px;
  font-weight: 500;
  font-family: "Rubik";
}
.subscription_packages p.days-text {
  color: #3dbcb8;
  font-size: 26px;
}
span.best-value {
  background: #3dbcb8;
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 18px;
  position: absolute;
  top: -20px;
}
.subscription-form-wrap {
  margin-top: 10px;
}
@media only screen and (max-width: 767px) {
  .banner-bg {
    padding: 0;
  }
}
</style>
