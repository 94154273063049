<template>
  <div class="home-section">
    <MainHeader></MainHeader>

    <section>
      <!-- banner -->
      <div class="block banner-bg">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-7 col-sm-12">
              <div class="banner-text">
                <span class="banner-tagline"
                  >Powerful Positive Programming in the Palm of Your Hand</span
                >
                <h1>
                  Welcome to
                  <span>Believe	</span> Apps!
                </h1>
                <p>
                  Reach your full potential through hypnosis! HypnoCloud
                  provides the most powerful high quality hypnosis audio
                  sessions, using the latest technology coupled with innovative
                  self-development tools.
                </p>
                <div class="banner-btns">
                  <a href="#" title class="apple-store">
                    <i class="fab fa-apple"></i>
                    <span class="small-text">Get It Form</span>App Store
                  </a>
                  <a href="#" title class="play-store">
                    <i class="fab fa-google-play"></i>
                    <span class="small-text">Get It Form</span>Play Store
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12">
              <div class="banner-img">
                <img src="assets/images/resource/banner-img.png" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Why Choose us----->
    <!-- <section>
      
      <div id="about" class="block p-120 no-top-pd">
        <div class="container">
          <div class="sec-heading style1 mt-80">
            <h2>Why Choose Believe</h2>
          </div>
          <div class="features">
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <div class="feature-box">
                  <img src="assets/images/resource/feature-img1.jpg" alt="" />
                  <h4>Chat With Groups</h4>
                  <p>
                    Writers and stars of Veep have responded incredulously to
                    the news an Australian politician required stitches way.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="feature-box">
                  <img src="assets/images/resource/feature-img2.jpg" alt="" />
                  <h4>Globally Chat</h4>
                  <p>
                    Writers and stars of Veep have responded incredulously to
                    the news an Australian politician required stitches way.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="feature-box">
                  <img src="assets/images/resource/feature-img3.jpg" alt="" />
                  <h4>Globally Chat</h4>
                  <p>
                    Writers and stars of Veep have responded incredulously to
                    the news an Australian politician required stitches way.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!--- End Why Choose Us---->

    <!-- <section>
      
      <div id="features" class="block p-120 no-top-pd no-bottom-pd">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="sec-heading style2">
                <h2>Integrated Social Network</h2>
              </div>
              <div class="feature">
                <p>
                  You can now use all Social Network from this Believe app also.
                  Writers and stars of Veep have responded incredulous to the
                  news an Australian politician required stitches after knocking
                  himself unconscious while laughing.
                </p>
              </div>
              <div class="feature-meta">
                <a href="#" title=""><span>3.2 B</span> USER</a>
                <a href="#" title=""><span>2.6 B</span> Download</a>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="social-media-integration">
                <img src="assets/images/resource/screen.jpg" alt="" />
                <ul class="social-media-icons">
                  <li class="facebook">
                    <a href="#" title=""><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li class="twitter">
                    <a href="#" title=""><i class="fab fa-twitter"></i></a>
                  </li>
                  <li class="pinterest">
                    <a href="#" title=""><i class="fab fa-pinterest"></i></a>
                  </li>
                  <li class="skype">
                    <a href="#" title=""><i class="fab fa-skype"></i></a>
                  </li>
                  <li class="drible">
                    <a href="#" title=""><i class="fab fa-dribbble"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- <section>
      
      <div class="block p-140">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="sec-feature-img">
                <img src="assets/images/resource/feature-img5.jpg" alt="" />
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="sec-feature-content">
                <div class="sec-heading style2">
                  <h2>High Quality Video Chating</h2>
                </div>
                <div class="feature">
                  <p>
                    You can now use all Social Network from this Believe app
                    also. Writers and stars of Veep have responded incredulous
                    to the news an Australian politician required stitches after
                    knocking himself unconscious while laughing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- <section class="home demo2" id="home">
				<div id="particles-js"></div>
				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<div class="home-content display-table">
								<div class="display-table-cell">
									<h5 class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">Welcome To Believe</h5>
									<h1 class="wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay="1s">Join Us</h1>
									<p class="wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay="1.5s">Meditation is the ability to focus the mind on a single point without distraction.</p>
									<div class="my-btn wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="2s">
										<a href="#services" class="main-btn"><span>Learn More</span></a>
										<a href="#contact" class="main-btn custom-btn"><span>Contact Us</span></a>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 d-none d-md-block">
							<div class="home-image wow fadeInUp" data-wow-duration="1.5s">
								<img src="assets/images/home2.png" alt>
							</div>
						</div>
					</div>
				</div>
    </section>-->

    <section class="pricings pt-5 pb-5 block" id="pricings">
      <div class="container">
        <div class="main-title text-center">
          <div class="sec-heading style1">
            <h2>Our Packages</h2>
          </div>
          <div class="step_form">
            <form-wizard
              @on-complete="onComplete"
              shape="tab"
              color="#9b59b6"
              ref="step_form"
            >
              <tab-content
                title="Pricing Plan"
                icon="fa fa-money-bill"
                :beforeChange="check_subscription"
              >
                <div class="pricing-plans">
                  <div class>
                    <form action>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="pricing-plan two">
                            <div class="pricing-head">
                              <div class="pricing-title">
                                <input
                                  type="radio"
                                  class="d-inline-block mr-3 align-middle"
                                  id="one"
                                  name="package"
                                  value="1"
                                  checked
                                  v-model="picked"
                                />
                                <h2 class="d-inline-block align-middle">
                                  Standard Plan
                                </h2>
                              </div>
                              <div class="pricing-price">
                                <p>
                                  <span class="up">$</span>
                                  <span class="price">79</span>
                                  <span class="down">/Month</span>
                                </p>
                              </div>
                            </div>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              labore et dolore magna aliqua. enim ad minim
                              veniam
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="pricing-plan two">
                            <div class="pricing-head">
                              <div class="pricing-title">
                                <input
                                  type="radio"
                                  id="two"
                                  name="package"
                                  value="2"
                                  class="d-inline-block mr-3 align-middle"
                                  v-model="picked"
                                />
                                <h2 class="d-inline-block align-middle">
                                  Premium Plan
                                </h2>
                              </div>
                              <div class="pricing-price">
                                <p>
                                  <span class="up">$</span>
                                  <span class="price">99</span>
                                  <span class="down">/Year</span>
                                </p>
                              </div>
                            </div>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              labore et dolore magna aliqua. enim ad minim
                              veniam
                            </p>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div v-if="$store.state.user">
                        <p v-if="$store.state.user.is_subscribed">
                          You already have an active subscription.
                        </p>
                      </div>
                      <div v-else class="pricing-btn pt-2 pb-2">
                        <router-link to="/login" class="main-btn">
                          <span>Login</span>
                        </router-link>
                      </div>
                    </form>
                  </div>
                </div>
              </tab-content>
              <tab-content title="Payment Merchant" icon="fa fa-credit-card" :beforeChange="checkPaymentMethod">
                <div class="payment-merchant">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="promo-code">
                        <h4>Enter Promo Code<span>(Optional)</span></h4>
                        <input
                          type="text"
                          placeholder="123456"
                          v-model="promo_code"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row payment-merchant-btn align-items-center">
                    <div class="col-lg-5">
                      <button
                        type="button"
                        @click="setPaymentMethod('paypal')"
                        class="paypal-btn"
                      >
                        <img
                          src="/assets/images/paypal.svg"
                          width="60px"
                          alt=""
                        />
                      </button>
                    </div>
                    <div class="col-lg-2">
                      <h4>Or</h4>
                    </div>
                    <div class="col-lg-5">
                      <button
                        type="button"
                        @click="setPaymentMethod('stripe')"
                        class="stripe-btn"
                      >
                        Stripe
                      </button>
                    </div>
                  </div>
                </div>
              </tab-content>
              <tab-content
                title="Final Step"
                icon="fa fa-receipt"
                
              >
                <div>
                  <Paypal
                    v-if="
                      payment_method == 'paypal' &&
                      $store.state.intent_generated != false
                    "
                    :plan_id="picked"
                    :promo_token="promo_token"
                    :promo_code="promo_code"
                  />
                  <Stripe
                    v-if="
                      payment_method == 'stripe' &&
                      $store.state.intent_generated != false
                    "
                    :plan_id="picked"
                    :promo_token="promo_token"
                    :promo_code="promo_code"
                  />
                </div>
                <div v-if="$store.state.general_error" class="error">
                  {{ $store.state.general_error }}
                </div>
                <div v-if="$store.state.payment_successful" class="thankyou">
                  <i class="fa fa-chevron-circle-right"></i>
                </div>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </section>

    <!-- =======Video Section===========--->
    <section>
      <!-- video sec -->
      <div
        class="block parallax"
        style="background: url('assets/images/resource/video-bg.jpg') no-repeat"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="video-sec">
                <div class="video-sec-content pb-70">
                  <a
                    href="https://www.youtube.com/watch?v=D0UnqGm_miA?&width=600&height=400"
                    data-gal="prettyPhoto[gal]"
                    class="video-icon"
                    ><i class="fas fa-play"></i
                  ></a>
                  <span>Video Chat</span>
                </div>
                <div class="video-sec-img">
                  <div class="video-img">
                    <img src="assets/images/resource/video-img.png" alt="" />
                    <span
                      ><img src="assets/images/logo.png" alt="" height="60"
                    /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- =======End Video Section ====== --->

    <!-- =======Screen Slider Section ====== --->
    <section>
      <!-- feature slider -->
      <div id="gallery" class="block">
        <div class="container">
          <div class="sec-heading style1 mt-90">
            <h2>Believe Screenshots</h2>
          </div>
        </div>
        <div class="slider-section">
          <div class="feature-slider">
            <div class="col-lg-3 col-sm-4">
              <img src="assets/images/resource/screen.jpg" alt="" />
            </div>
            <div class="col-lg-3 col-sm-4">
              <img src="assets/images/resource/01.png" alt="" />
            </div>
            <div class="col-lg-3 col-sm-4">
              <img src="assets/images/resource/02.png" alt="" />
            </div>
            <div class="col-lg-3 col-sm-4">
              <img src="assets/images/resource/03.png" alt="" />
            </div>
            <div class="col-lg-3 col-sm-4">
              <img src="assets/images/resource/04.png" alt="" />
            </div>
            <div class="col-lg-3 col-sm-4">
              <img src="assets/images/resource/05.png" alt="" />
            </div>
            <div class="col-lg-3 col-sm-4">
              <img src="assets/images/resource/06.png" alt="" />
            </div>
          </div>
          <ul class="slider-navigation">
            <li class="prev-arrow">
              <img src="assets/images/resource/prev-arrow.jpg" alt="" />
            </li>
            <li class="next-arrow">
              <img src="assets/images/resource/next-arrow.jpg" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- =======End Screen Slider Section ====== --->

    <!-- =======Cool Features Section ====== --->
    <!-- <section>
    
      <div class="block no-top-pd">
        <div class="container">
          <div class="sec-heading style1 mt-40">
            <h2>Believe Cool Features</h2>
          </div>
          <div class="cool-feaures pt-20">
            <div class="cool-features-slider">
              <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img
                    src="assets/images/resource/cool-feature-img1.jpg"
                    alt=""
                  />
                  <h4>Voice Massage</h4>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img
                    src="assets/images/resource/cool-feature-img2.jpg"
                    alt=""
                  />
                  <h4>Live Conference</h4>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img
                    src="assets/images/resource/cool-feature-img3.jpg"
                    alt=""
                  />
                  <h4>HD Video Chat</h4>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img
                    src="assets/images/resource/cool-feature-img4.jpg"
                    alt=""
                  />
                  <h4>Unique Stickers</h4>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img
                    src="assets/images/resource/cool-feature-img1.jpg"
                    alt=""
                  />
                  <h4>Voice Massage</h4>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img
                    src="assets/images/resource/cool-feature-img2.jpg"
                    alt=""
                  />
                  <h4>Live Conference</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- =======End Cool Features Section ====== --->

    <!-- ==========Cta Section ========= -->
    <section>
      <!-- downlaod app -->
      <div
        class="block parallax blue-bg"
        style="background: url('assets/images/resource/app-bg.jpg') no-repeat"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="sec-heading style1">
                <h2>Download Our Free Believe App</h2>
              </div>
              <div class="download-app-sec">
                <p>
                  Take your journal wherever you go with our apps for iPhone,
                  iPad, and Android phones & tablets
                </p>
                <div class="banner-btns">
                  <a href="#" title="" class="apple-store"
                    ><i class="fab fa-apple"></i
                    ><span class="small-text">Get It Form</span>Appstor</a
                  >
                  <a href="#" title="" class="play-store"
                    ><i class="fab fa-google-play"></i
                    ><span class="small-text">Get It Form</span>Appstor</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ========End Cta Section ========== -->

    <!-- ======== testimonial Section ========== -->
    <!-- <section>
     
      <div class="block bg-img">
        <div class="container">
          <div class="sec-heading style2">
            <h2>Testimonials</h2>
          </div>
          <div class="client-reviews pt-20">
            <div class="testimonial-slider">
              <div class="testi-item">
                <span
                  ><img src="assets/images/resource/testi-img1.png" alt=""
                /></span>
                <p>
                  it goes without saying that customer date security and
                  compliance is top of mind in most retail app Barisal has built
                  state of the art security into our product.
                </p>
                <div class="testi-person">
                  <h5>John</h5>
                  <span>HR Manager</span>
                </div>
              </div>
              <div class="testi-item">
                <span
                  ><img src="assets/images/resource/testi-img2.png" alt=""
                /></span>
                <p>
                  it goes without saying that customer date security and
                  compliance is top of mind in most retail app Barisal has built
                  state of the art security into our product.
                </p>
                <div class="testi-person">
                  <h5>Monika Maral</h5>
                  <span>News Caster</span>
                </div>
              </div>
              <div class="testi-item">
                <span
                  ><img src="assets/images/resource/testi-img1.png" alt=""
                /></span>
                <p>
                  it goes without saying that customer date security and
                  compliance is top of mind in most retail app Barisal has built
                  state of the art security into our product.
                </p>
                <div class="testi-person">
                  <h5>Mansoor Ahmad</h5>
                  <span>HR Manager</span>
                </div>
              </div>
              <div class="testi-item">
                <span
                  ><img src="assets/images/resource/testi-img2.png" alt=""
                /></span>
                <p>
                  it goes without saying that customer date security and
                  compliance is top of mind in most retail app Barisal has built
                  state of the art security into our product.
                </p>
                <div class="testi-person">
                  <h5>Monika Maral</h5>
                  <span>News Caster</span>
                </div>
              </div>
            </div>
            <ul class="testi-navigation">
              <li class="prev">
                <span><i class="fas fa-angle-left"></i></span>
              </li>
              <li class="next">
                <span><i class="fas fa-angle-right"></i></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section> -->
    <!-- ========End testimonial Section ========== -->

    <!-- ==========Faq's Section ========= -->
    <!-- <section>
    
      <div class="block">
        <div class="container">
          <div class="sec-heading style1">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div class="faq-sec mt-80">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                  <span class="faq-no">1</span>
                  <div class="faq-content">
                    <h4>Which is Best plan For Believe Premium?</h4>
                    <p>
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                      irure dolor in reprehenderit .
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                  <span class="faq-no">2</span>
                  <div class="faq-content">
                    <h4>What offer Believe For Basic Plan?</h4>
                    <p>
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                      irure dolor in reprehenderit .
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                  <span class="faq-no">3</span>
                  <div class="faq-content">
                    <h4>How can Download the app?</h4>
                    <p>
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                      irure dolor in reprehenderit .
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                  <span class="faq-no">4</span>
                  <div class="faq-content">
                    <h4>But i must explan to you how all this?</h4>
                    <p>
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                      irure dolor in reprehenderit .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- ==========End Faq's Section ========= -->

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Stripe from "../components/Stripe.vue";
import Paypal from "../components/Paypal.vue";
import MainHeader from "../components/MainHeader.vue";

export default {
  components: {
    MainHeader,
    Footer,
    Stripe,
    Paypal,
  
},
  data() {
    return {
      picked: "1",
      promo_token: null,
      promo_code: null,
      payment_method: null,
    };
  },
  mounted() {},
  created() {
    this.getPromotoken();
  },
  methods: {
    getPromotoken() {
      if (this.$route?.query?.promo)
        this.promo_token = this.$route?.query?.promo;
      if (this.$route?.query?.promo_code)
        this.promo_code = this.$route?.query?.promo_code;
    },
    check_subscription() {
      if (this.$store?.state?.user && !this.$store?.state?.user?.is_subscribed)
        return true;
      else {
        // alert("login to continue.");
        return false;
      }
    },
    setPaymentMethod(payment_method) {
      this.payment_method = payment_method;
      this.$store.state.intent_generated = null;
      this.$store.state.general_error = null;

      //   console.log(this.$refs.step_form);
      this.$refs.step_form.nextTab();
    },
    checkPaymentMethod() {
      if (this.payment_method != null) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.step_form .wizard-header {
  display: none;
}
.step_form .wizard-icon-container {
  background: linear-gradient(184deg, #03174c, #051c50 10%, #45c5af 100%);
}
.step_form .vue-form-wizard .wizard-card-footer button {
  padding: 10px 25px;
  border: none;
  background: linear-gradient(184deg, #03174c, #051c50 10%, #45c5af 100%);
}
.step_form .pricing-btn {
  text-align: left;
}
.step_form .pricing-btn a {
  background: linear-gradient(184deg, #03174c, #051c50 10%, #45c5af 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 16px;
  font-weight: 500;
}
.step_form .vue-form-wizard .stepTitle {
  background: linear-gradient(184deg, #03174c, #051c50 10%, #45c5af 100%);
  -webkit-background-clip: text;
  color: transparent !important;
  margin-top: 10px;
}
.promo-code {
  text-align: left;
  margin-bottom: 30px;
}
.promo-code h4 span {
  font-weight: 200;
  padding-left: 5px;
}
.promo-code input[type="text"] {
  padding: 5px;
  border-radius: 2px;
  border: 0.5px solid #d1d1d1;
  width: 200px;
}
.payment-merchant-btn button {
  width: 100%;
  display: block;
  font-size: 18px;
  font-weight: 500;
  padding: 15px;
  border: none;
  text-transform: uppercase;
  border-radius: 2px;
}
.vue-form-wizard .wizard-tab-content {
  min-height: 100px;
  padding: 30px 20px 30px !important;
}
.payment-merchant-btn h4:before {
  content: "";
  background: black;
  width: 35%;
  height: 2px;
  position: absolute;
  top: 40%;
  left: 0;
}
.payment-merchant-btn h4:after {
  content: "";
  background: black;
  width: 35%;
  height: 2px;
  position: absolute;
  top: 40%;
  right: 0;
}
.payment-merchant-btn button.stripe-btn {
  background: #3395ce;
  color: white;
  cursor: pointer;
}
a{
	cursor: pointer;
}
</style>
