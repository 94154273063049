<template>
  <div class="home-section">
    <MainHeader></MainHeader>
    <section class="pricings pt-5 pb-5 success-main" id="pricings">
      <div class="container">
        <div class="row pt-5 align-items-center">
          <div class="col-md-6 col-sm-12 hide-it">
            <div class="banner-images">
              <img
                class="img-1 img-fluid"
                src="../../public/assets/images/resource/iPhone 13 Mockup-3.png"
                alt=""
              />
              <img
                class="img-2 img-fluid"
                src="../../public/assets/images/resource/iPhone 13 Mockup.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="get-app-wrap">
              <h4 class="success-text">
                {{ header_message }} <i class="fa fa-check-circle"></i>
              </h4>

              <h2 class="banner_text">Believe</h2>
              <span class="banner-para-text">
                DREAM IT, BELIEVE IT & see yourself ACHIEVE your life purpose
                with passion and prosperity.
              </span>
              <span class="banner-para">Get Free Believe App Now!</span>
              <div class="pt-4 pb-4"></div>
              <div class="get-in-btn-div">
                <a href="https://play.google.com/store/apps/details?id=com.hyptalk.believe" target="_blank" class="img-1"
                  >
                  <!-- <img
                    class="img-fluid"
                    src="../../public/assets/images/icons/android.png"
                    alt=""
                  /> -->
                  <img
                    class="img-fluid"
                    src="../../public/assets/images/resource/App-Store.png"
                    alt=""
                  />
                </a>
                

                <a href="https://apps.apple.com/us/app/believe-hypnosis/id6466321566" target="_blank" class="img-2"
                  >
                  <!-- <img
                    class="img-fluid"
                    src="../../public/assets/images/icons/apple.png"
                    alt=""
                  /> -->
                  <img
                    class="img-fluid"
                    src="../../public/assets/images/resource/Googleplay.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MainHeader from "../components/MainHeader.vue";
import API from "../config/api";

export default {
  components: {
    MainHeader,
  },
  data() {
    return {
      header_message: null,
    };
  },
  async created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.$store.state.user = this.user;
    try {
      const response = await API.post("/get-updated-user", {
        id: this.user.id
      });
      console.log("success",response.data.message);
      this.$store.state.user = null
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(response.data.message));
      this.$store.state.user = response.data.message;
      console.log(response.data.message);

    } catch (error) {
      console.log(error);
    }    
    // this.verifyUser();
    // if (!this.$store?.state?.user?.is_subscribed) {
    //   this.$router.push("/");
    // }
  },
  mounted() {
    if (this.$route?.query?.setup_intent_client_secret) {
      this.verifySetupIntent(this.$route?.query?.setup_intent_client_secret);
    } else if (this.$route?.query?.payment_intent_client_secret) {
      this.verifyPaymentIntent(
        this.$route?.query?.payment_intent_client_secret
      );
    }
  },
  methods: {
    async verifySetupIntent(setup_intent_secret) {
      try {
        const response = await this.$stripe.retrieveSetupIntent(
          setup_intent_secret
        );
        const packageName = localStorage.getItem('revcatSubType1');
        switch (response?.setupIntent?.status) {
          case "succeeded":
            console.log('all state : ' , this.$store.state);
            localStorage.setItem('revcatSubType',packageName);
            localStorage.removeItem('revcatSubType1');
            this.$store.state.stripeMessage = "Success! Your payment method has been saved.";
            this.$router.push("/success");
            break;

          case "processing":
            this.header_message =
              "Processing payment details. We'll update you when processing is complete.";
            break;

          case "requires_payment_method":
            this.$store.state.stripeMessage = "Failed to process payment details. Please try another payment method.";
            this.$router.push("/failed");
            break;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async verifyPaymentIntent(payment_intent_client_secret) {
      try {
        const response = await this.$stripe.retrievePaymentIntent(
          payment_intent_client_secret
        );
        console.log(response);
        switch (response?.paymentIntent?.status) {
          case "succeeded":
            this.$store.state.stripeMessage = "Success! Your payment has been processed.";
            this.$router.push("/success")
            break;

          case "processing":
            this.header_message =
              "Processing payment details. We'll update you when processing is complete.";
            break;

          case "requires_payment_method":
            this.$store.state.stripeMessage = "Failed to process payment details. Please try another payment method.";
            this.$router.push("/failed")
            // this.header_message =
            //   "Failed to process payment details. Please try another payment method.";
            break;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.success-main {
  min-height: 100vh;
  display: grid;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .success-main {
    display: initial;
    height: auto;
  }
  .success-main .banner-images {
    margin-top: 0;
  }
  .success-main .banner-images img {
    width: 70%;
  }
  .success-main .get-app-wrap * {
    text-align: center;
  }
  .success-main span.banner-para {
    max-width: 100% !important;
    margin: 0 auto;
    text-align: center;
    display: block;
  }
}
</style>
