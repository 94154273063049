<template>
    <div class="main-terms-content banner-bg">
      <MainHeader></MainHeader>
      <div class="main-content-wrapper pb-5">
        <div class="container">
          <div class="row ">
            <div class="col-md-12">
              <h2>Live Events coming soon to the Believe Hypnosis app!</h2>
              <p>
                Welcome to the Live Events page, where you'll soon find a schedule of exciting and
                transformative live events hosted by Victoria Gallagher inside the Believe Hypnosis app. These
                events are designed to deepen your understanding of hypnosis, manifestation, and personal
                growth while providing a unique opportunity to connect with Victoria and fellow community
                members.
              </p>
              <p>
                <b>Check back soon for a schedule of upcoming live events!</b>
              </p>
              <p>
                In the meantime, we encourage
                you to explore the Believe Hypnosis app and familiarize yourself
                with its features, guided sessions, and resources. By doing so, you'll be better prepared to make
                the most of the live events once they become available.
              </p>
              <p>
                Stay tuned for announcements regarding upcoming live events, and don't forget to join our
                Facebook group, "Believe Hypnosis: Manifest Your Dreams," to connect with our growing
                community and stay updated on the latest news and offerings from Believe Hypnosis.
              </p>
              <p>
                We look forward to seeing you at our live events soon and accompanying you on your journey
                towards manifesting the life of your dreams!
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </template>
  <script>
  import Footer from "../components/footer.vue";
  import MainHeader from "../components/MainHeader.vue";
  
  export default {
    components: {
      MainHeader,
      Footer,
    },
  };
  </script>
  <style>
     .main-content-wrapper h2 , .main-content-wrapper h4 {
      color: #071649;
      margin-bottom: 20px;
  }
  .main-content-wrapper p{
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 400;
  }
  </style>
