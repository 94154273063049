<template>
  <div class="main-terms-content banner-bg">
   
    <div class="main-content-wrapper pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2>Deletion Policy for the BELIEVE App by Hyptalk.com, LLC</h2>
          
            <div class="table-of-content">
              <h4>TABLE OF CONTENTS</h4>
              <ul>
                <li><a href="#one">Introduction </a></li>
                <li><a href="#two">Information That Gets Deleted </a></li>
                <li>
                  <a href="#three"
                    >Information That Is Not Deleted
                  </a>
                </li>
                <li>
                  <a href="#four">Why We Retain Some Data</a>
                </li>
                <li>
                  <a href="#five">How To Delete Your Account </a>
                </li>
                 <li>
                  <a href="#six">Updates To This Policy </a>
                </li>
              </ul>
            </div>
            <h4 id="one">Introduction</h4>

            <p>
              This Deletion Policy applies to users of the BELIEVE app, which is
              wholly owned and operated by Hyptalk.com, LLC. By using the
              BELIEVE app, you agree to the terms outlined in this policy
              concerning the deletion of your account
            </p>

            <h4 id="two">Information That Gets Deleted</h4>
          

            <p class="list-style">
              When you choose to delete your BELIEVE account, the following
                information is removed:
            </p>
            <div class="list-style-inner">
              <p>
               <b>Identifiers Cleared:</b> Personal identifiers including your email address, name, and FCM (Firebase Cloud Messaging) token are changed to an empty string "" in our database.
              </p>
              <p>
               <b>Authentication Removal:</b> Your user profile will be removed from Firebase, disabling any future authentication through the BELIEVE app.
              </p>
              </div>
             

            <h4 id="three">Information That Is Not Deleted</h4>
            <p>
              <i
                ><b>Activity Data:</b>  All activities you have performed in the BELIEVE app will be retained. </i
              >
            </p>
            <p class="list-style">
              We may process or share your data that we hold based on the
              following legal basis:
            </p>
            <div class="list-style-inner">
              <p>
                This encompasses:
              </p>
              <p>
               Total minutes spent using the app
              </p>
              <p>
                Favorites
              </p>
              <p>
               Playlists created
              </p>
               <p>
               Badges earned
              </p>
              <p>
              This activity data is essential for generating internal statistics and improving the app's features. Once your account is deleted, this data will be anonymized and cannot be linked back to you.
              </p>
           
            </div>

            <h4 id="four">Why We Retain Some Data</h4>
            <p>
              We keep your activity data for statistical analysis aimed at enhancing the user experience and functionality of the BELIEVE app.
            </p>
           
            <h4 id="five">How To Delete Your Account</h4>
            <p>
             To begin the account deletion process, please follow the specific steps outlined within the BELIEVE app or contact our support team at <a href="mailto:orders@hyptalk.com">orders@hyptalk.com</a>.
            </p>
    

            <h4 id="six">Updates To This Policy</h4>
            <p>
              Hyptalk.com, LLC reserves the right to modify this Deletion Policy. Should changes occur, we will notify users through appropriate channels, such as in-app notifications or updates on our website.
            </p>
           
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>


export default {
  
};
</script>
<style>
.main-content-wrapper h2,
.main-content-wrapper h4,
.main-content-wrapper h5 {
  color: #071649;
  margin-bottom: 20px;
}
.main-content-wrapper p {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
}
.main-content-wrapper p.list-style {
  font-weight: 500;
  margin: 0;
}
.main-content-wrapper .list-style-inner p {
  margin: 0;
}
.main-content-wrapper .list-style-inner {
  margin-bottom: 20px;
}
.main-content-wrapper .table-of-content {
  margin-bottom: 30px;
}
.main-content-wrapper .table-of-content ul li {
  list-style: num;
  color: black;
  margin-bottom: 5px;
  font-size: 18px;
}
.main-content-wrapper .table-of-content ul li a {
  color: black;
}
.table-div table td {
  padding: 5px;
}
.table-div {
  margin-bottom: 30px;
}
</style>
