<template>
<div class="page-wrapers">
    <MainHeader></MainHeader>
    <section class="same-layout trial-cc fixed-height">
      <div class="inner-left pt-5">
        <div class="col-sm-12">
          <img
            src="../../public/assets/images/logo-mob.png"
            alt=""
            class="d-block"
          />
          <span class="banner-para"
            ><b>DREAM</b> it, <b>BELIEVE</b> it, and <b>ACHIEVE</b> your
            DESIRES</span
          >
          <ul class="banner-list">
            <li>Speed up your manifestations</li>
            <li>Access 1000+ powerful LOA hypnosis audios</li>
            <li>Members only personal growth courses</li>
            <li>Videos, Scripts, Live Events + Much More</li>
            <li>Start Living a Happier More Abundant life now</li>
          </ul>
        </div>
      </div>
      <div class="inner-right pt-5">
        <div class="col-sm-12">
          <div id="login" class="tab-pane active text-center">
            <form class="p-a30 dez-form">
              <h3 class="trial-text m-t0 mb-2">Unlock 12 months of Believe Premium</h3>
              <p class="price-text">$0.00 today</p>
              <p class="sub-text-inner">14-Day Free Trial, then $77.99 annually renweing subscription</p>
            
              <!-- <p>Enter your email address and your password.</p> -->
         
              <img
            src="../../public/assets/images/icons/visa-logo.png"
            alt=""
            class="img-responsive mt-4 mb-3"
            width="80%"
          />
            <div class="form-group">
                <input
                  name="dzName"
                  required
                  class="form-control"
                  placeholder="Full Name*"
                  type="text"
                  v-model="name"
                />
              </div>
              <div class="form-group">
                <input
                  name="dzName"
                  required
                  class="form-control"
                  placeholder="Email Address"
                  type="text"
                  v-model="email"
                />
              </div>
             
              <div class="form-group">
                <input
                  name="dzName"
                  required
                  class="form-control"
                  placeholder="Password (8+ characters)*"
                  type="password"
                  v-model="password"
                />
              </div>
              <div class>
                <input
                  name="dzName"
                  required
                  class="form-control"
                  placeholder="Confirm Password*"
                  type="password"
                  v-model="retype_password"
                />
              </div>
              
              <div class="card-text mt-3">
                <p>The yearly membership give you unlimited access to Believe's entire library of content. You will be charged, $0.00 today, then $77.99 after the first 14 Days, which will renew annually.</p>
                <p>You may cancel your subscription at any time by going to your profile and following the intructions in the Manage Subscription page.</p>
              </div>

              <div class="form-group text-left">
                <button type="button" class="site-button main-btn pull-right" @click="btn_register">
                  <span>Get Access to Believe Premium Now</span>
                </button>

               
              </div>
            
            </form>
            
          </div>
        </div>
      </div>
    </section>
    <!-- Content -->

    <!-- Content END-->
  </div>
</template>
<script>
import MainHeader from "../components/MainHeader.vue";
export default {
  
  components: {
    MainHeader,
  },
}
</script>
<style scoped src="../../public/assets/css/login-page.css">
</style>