<template>
  <div class="services-slider-2">
    <VueSlickCarousel v-bind="settings">
      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/deep-sleep.webp"
            alt=""
          />
          <h4 class="service-title">Deep Sleep</h4>
        </div>
      </div>

      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/law-of-attraction.webp"
            alt=""
          />
          <h4 class="service-title">Law of Attraction</h4>
        </div>
      </div>

      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/motivation.webp"
            alt=""
          />
          <h4 class="service-title">Motivation</h4>
        </div>
      </div>
      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/Emotional-well-being.png"
            alt=""
          />
          <h4 class="service-title">Emotional Well-Being</h4>
        </div>
      </div>

      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/mental-focus.webp"
            alt=""
          />
          <h4 class="service-title">Mental Focus</h4>
        </div>
      </div>

      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/happiness.webp"
            alt=""
          />
          <h4 class="service-title">Happiness</h4>
        </div>
      </div>

      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/goal-achievement.webp"
            alt=""
          />
          <h4 class="service-title">Goal Achievement</h4>
        </div>
      </div>

      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/gratitude.webp"
            alt=""
          />
          <h4 class="service-title">Gratitude</h4>
        </div>
      </div>

      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/Health-and-healing.webp"
            alt=""
          />
          <h4 class="service-title">Health and healing</h4>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "MyComponent",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 9000,
        autoplaySpeed: 9000,
        cssEase: "linear",
        centerMode: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        variableWidth: true,
        adaptiveHeight: true,
        rtl: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style>
.services-slider-2 {
  margin: 0 0 20px 0;
}
.services-slider-2 .slider-wrap {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-radius: 25px;
}

.services-slider-2 .slider-wrap h4 {
  position: absolute;
  bottom: 0;
  padding: 20px;
  background: gainsboro;
}
.services-slider-2 .slider-wrap h4 {
  position: absolute;
  bottom: 0;
  padding: 20px;
  margin: 0;
  background: #68686899;
  display: block;
  width: 100%;
  color: white;
  border-radius: 0 0 25px 25px;
  font-family: "Rubik";
  font-weight: 500;
}
.services-slider-2 .slick-slide {
  margin: 0 20px;
}
</style>
