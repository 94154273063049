<template>
  <div class="main-terms-content banner-bg">
    <MainHeader></MainHeader>
    <div class="main-content-wrapper pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2>Meet Victoria</h2>
            <p>
              Victoria M. Gallagher, is the founder of Believe Hypnosis and the
              driving force behind this transformative app. As an
              internationally recognized expert in the Law of Attraction,
              Hypnosis, NLP, Meditation, and Affirmations, Victoria has
              dedicated her life to helping people manifest their dreams and
              achieve personal success.
            </p>
            <h4>Background & Experience</h4>
            <p>
              Victoria's hypnosis journey began in 1999 when she discovered her
              passion for personal development and the power of the subconscious
              mind. Sinc e then, she has become a #1 Best Selling Author, Law of
              Attraction Expert, and international speaker, specializing in
              various modalities to help people unlock their full potential.
            </p>
            <p>
              Throughout her career, Victoria has worked with thousands of
              clients, inclu ding entrepreneurs, celebrities, and individuals
              seeking personal growth. Her unique approach combines hypnotherapy
              with the Law of Attraction, creating a powerful synergy that helps
              clients overcome limitations and manifest their goals.
            </p>
            <h4>As Seen On & Recognitions</h4>
            <p>
              Victoria's expertise has earned her appearances on ABC's 20/20, as
              well as features in numerous publications, podcasts, and
              television shows. Her profound insights and engaging presence have
              made her a sought - after speaker and authority in the fiel ds of
              hypnosis and personal development.
            </p>
            <h4>Believe Hypnosis App</h4>
            <p>
              Victoria created the Believe Hypnosis app as a way to bring her
              life - changing techniques to a wider audience. Through the app,
              users can access a wealth of resources, including hypnosis
              sessions , guided meditations, affirmations, courses, and more,
              all narrated and written by Victoria herself.
            </p>
            <p>
              Her soothing voice and expertly crafted sessions guide users into
              a relaxed, receptive state, allowing them to tap into the power of
              their subconscious min d and create lasting change. With over 1000
              programs in her extensive library, Victoria's expertise is
              accessible to anyone, anytime, and anywhere.
            </p>
            <h4>Connect with Victoria</h4>
            <p>
              Stay updated with Victoria's latest insights and offerings by
              following her on social media and subscribing to her <a href="https://victoriamgallagher.com/podcast" target="_blank">Power of Your Mind Podcast</a> . Connect with like - minded individuals through her
              online community and attend her live events and workshops to
              experience her transformative tech niques firsthand.
            </p>
            <ul class="follow-icons vic-social-media">
              <li class="">
                <a
                  href="https://www.instagram.com/victoriamgallagher/"
                  target="_blank"
                  ><i class="fab fa-instagram"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/victoriamariegallagher"
                  target="_blank"
                  ><i class="fab fa-facebook"></i
                ></a>
              </li>
              <li>
                <a href="https://twitter.com/loahypnotist" target="_blank"
                  ><i class="fab fa-twitter"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/victoriagallagher"
                  target="_blank"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </li>
              <li>
                <a href="https://www.youtube.com/hyptalk" target="_blank"
                  ><i class="fab fa-youtube"></i
                ></a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@loahypnotist" target="_blank"
                  ><i class="fa-brands fa-tiktok"></i
                ></a>
              </li>
            </ul>
            <p>
              Join the <a target="_blank" href="https://www.facebook.com/groups/believehypnosis">Believe Hypnosis community</a> today and let Victoria's
              expertise guide you on the journ ey to manifesting the life of
              your dreams.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../components/footer.vue";
import MainHeader from "../components/MainHeader.vue";

export default {
  components: {
    MainHeader,
    Footer,
  },
};
</script>
<style>
.main-content-wrapper h2,
.main-content-wrapper h4 {
  color: #071649;
  margin-bottom: 20px;
}
.main-content-wrapper p {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
}
ul.vic-social-media {
    justify-content: flex-start;
    margin: 0;
}
ul.vic-social-media li a i {
    color: #071649;
}
ul.vic-social-media li a i:hover {
    color: #163cba;
}
</style>
