<template>
  <div class="subscription-detail-section subscription-detail-section-mob">
    <section class="pricings subscription-info-main pt-3 pb-3" id="pricings">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 heading-subcription">
            <h3>Subscription Details</h3>
          </div>
        </div>
        <div class="row banner justify-content-center m-0 pt-2 pb-4">
          <img
            width="72px"
            src="../../public/assets/images/logo-mob.png"
            alt=""
          />
        </div>
        <div class="sec-heading style1 mb-4">
          <h4>Believe Hypnosis App</h4>
        </div>

        <div class="row" v-if="$store.state.sub_history">
          <div
            class="col-md-6 p-4"
            v-for="data in $store.state.sub_history"
            :key="data.id"
          >
            <div class="content-column">
              <div class="subscription-inner">
                <div class="inner-middle">
                  <p class="sub-head">Subcription Type:</p>
                  <p class="sub-detail">{{ data.plan_id == 1 ? "MONTHLY" : data.plan_id == 2 ? "YEARLY" : "" }}  <br /></p>
                </div>
                <div class="inner-middle">
                  <p class="sub-head">Valid through:</p>
                  <p class="sub-detail">
                    <i class="fa fa-calendar-alt"></i>
                    {{ data.end_date | moment('LL') }}
                  </p>
                </div>
                <div class="inner-middle">
                  <p class="sub-head">Platform:</p>
                  <p class="sub-detail">
                    <i class="fa fa-cash"></i>
                    {{ data.platform.toUpperCase() }}
                  </p>
                </div>

                <div class="inner-middle">
                  <p class="sub-head">Status:</p>
                  <p class="sub-detail">
                    <span
                      :class="
                        data.status == 'active' ? 'text-success' : 'text-danger'
                      "
                    >
                      {{ data.status.toUpperCase() }}
                    </span>
                  </p>
                </div>

                <div class="inner-right">
                  <a
                    v-if="
                      data.platform == 'stripe' || data.platform == 'paypal'
                    "
                    :class="
                      data.status == 'active'
                        ? 'active-cancel-btn'
                        : 'inactive-cancel-btn'
                    "
                    @click="openModal(data.id, data.status)"
                    >Cancel</a
                  >
                  <router-link to="/manage-mob">View All Plans</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="$store.state.sub_history.length < 1">
          <div class="col-md-8 p-4 content-column">
            <p>No Subscriptions Were Found.</p>
          </div>
        </div>
      </div>
    </section>

    <modal name="confirm_modal" :height="80" :width="250" :adaptive="true">
      <div class="model-iiner">
        <h4>Are you sure?</h4>
        <ul>
          <li @click="modalAction('yes')">Yes</li>
          <li @click="modalAction('no')">No</li>
        </ul>
      </div>
    </modal>
  </div>
</template>

<script>
import API from "../config/api";

export default {
  components: {},
  data() {
    return {
      selected_sub_id: null,
    };
  },
  async created() {
    this.verifyUser();
  },
  methods: {
    async verifyUser() {
      try {
        let token = this.$route?.params?.token;
        localStorage.setItem("token", token);
        const response = await API.get("/verify");
        this.$store.state.user = response.data;
        localStorage.setItem("user", JSON.stringify(this.$store.state.user));
        console.log(this.$store.state.user)
        if (this.$store?.state?.user) this.listWebSub();
      } catch (error) {
        console.log(error.response.data);
      }
    },

    async modalAction(answer) {
      try {
        if (answer == "yes") {
          const res = await API.post("/cancel-subscription", {
            sub_history_id: this.selected_sub_id,
          });
          // alert(res.data.message);
          this.$store.state.sub_history = res.data.subscriptions;
          this.$modal.hide("confirm_modal");
        } else this.$modal.hide("confirm_modal");
      } catch (error) {
        console.log(error?.response?.data?.message);
      }
    },
    openModal(sub_id, status) {
      if (status == "active") {
        this.selected_sub_id = sub_id;
        this.$modal.show("confirm_modal");
      }
    },

    async listWebSub() {
      try {
        const sub_history = await API.get("/list-web-subscription");
        // console.log(sub_history.data.subscriptions);
        this.$store.state.sub_history = sub_history.data.subscriptions;
      } catch (error) {
        console.log("not logged in");
      }
    },
  },
};
</script>

<style>
.subscription-detail-section .subscription-info-main {
  font-family: Rubik;
}
.subscription-detail-section {
  background-color: #3dbcb826;
}
.subscription-detail-section .content-column {
  position: relative;
  padding: 25px 30px;
  background: rgba(61, 188, 184, 0.1);
  -webkit-backdrop-filter: blur(4.5px);
  backdrop-filter: blur(4.5px);
  border-radius: 10px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
  text-align: start;
}
.heading-subcription {
  text-align: center;
}
.subscription-detail-section-mob {
  min-height: 100vh;
}
.subscription-inner {
  margin: 0;
}
</style>
