<!-- <template>
  <div class="home-section">
    <MainHeader></MainHeader>
    <section class="pricings pt-5 pb-5 success-main " id="pricings">
      <div class="container">
        <div class="main-title text-center">
          <h2>{{ heading }}</h2>
          <div v-if="$store.state.user"></div>
        </div>
      </div>
    </section>
  </div>
</template> -->
<template>
  <div class="home-section">
    <MainHeader></MainHeader>
    <section class="pricings pt-5 pb-5 success-main" id="pricings">
      <div class="container">
        <div class="row pt-5 align-items-center">
          <div class="col-md-6 col-sm-12 hide-it">
            <div class="banner-images">
              <img
                class="img-1 img-fluid"
                src="../../public/assets/images/resource/iPhone 13 Mockup-3.png"
                alt=""
              />
              <img
                class="img-2 img-fluid"
                src="../../public/assets/images/resource/iPhone 13 Mockup.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="get-app-wrap">
              <h4 class="success-text">
                {{ heading }} <i class="fa fa-check-circle"></i>
              </h4>
              <div v-if="$store.state.user"></div>
              <h2 class="banner_text">Believe</h2>
              <span class="banner-para-text">
                DREAM IT, BELIEVE IT & see yourself ACHIEVE your life purpose
                with passion and prosperity.
              </span>
              <span class="banner-para">Get Free Believe App Now!</span>
              <div class="pt-4 pb-4"></div>
              <div class="get-in-btn-div">
                <a href="https://play.google.com/store/apps/details?id=com.hyptalk.believe" target="_blank" class="img-1"
                  ><img
                    class="img-fluid"
                    src="../../public/assets/images/icons/android.png"
                    alt=""
                  />
                </a>

                <a href="https://apps.apple.com/us/app/believe-hypnosis/id6466321566" target="_blank" class="img-2"
                  ><img
                    class="img-fluid"
                    src="../../public/assets/images/icons/apple.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MainHeader from "../components/MainHeader.vue";
import API from "../config/api";

export default {
  components: {
    MainHeader,
  },
  data() {
    return {
      heading: "Verifying PayPal Order..!",
    };
  },
  async created() {   
    // if (!this.$store?.state?.user?.is_subscribed) {
    //   this.$router.push("/");
    // }
  },
  mounted() {
    this.confirmOrder();
  },
  methods: {
    async confirmOrder() {
      if (this.$route?.query?.subscription_id) {
        try {
          const response = await API.post("/paypal-confirm-order", {
            token: this.$route?.query?.subscription_id ?? "",
          });
          // console.log(response.data);
          this.heading = response.data.message;
          this.$store.state.user = response.data.user;
          this.updateUser();
        } catch (err) {
          this.heading = err?.response?.data?.message;
        }
      }
    },
    async updateUser() {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.$store.state.user = this.user;
      try {
      const response = await API.post("/get-updated-user", {
      id: this.user.id
      });
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(response.data.message));
      this.$store.state.user = response.data.message;
      console.log(response.data.message);

      } catch (error) {
      console.log(error);
    }         
    }    
  },
};
</script>

<style></style>
