<template>
	<div class="main-terms-content banner-bg">
	  <MainHeader></MainHeader>
	  <div class="main-content-wrapper pb-5">
		<div class="container">
		  <div class="row">
			<div class="col-md-12">
			  <h2>Billing Details</h2>
			  <p>
				Payment will be charged to iTunes Account at confirmation of
				purchase or the end of the free trial in the case of a free trial.
			  </p>
			  <p>Yearly subscription is charged as one payment</p>
			  <p>
				Monthly and Yearly subscriptions automatically renew at the rate
				secured at the time of purchase unless auto-renew is turned off at
				least 24-hours before the end of the current period.
			  </p>
			  <p>
				Auto-renewal can be turned off easily at any time by selecting
				Manage Subscriptions in the user's Account Settings after
				purchase.
			  </p>
			</div>
		  </div>
		</div>
	  </div>
	  <Footer></Footer>
	</div>
  </template>
  <script>
  import Footer from "../components/footer.vue";
  import MainHeader from "../components/MainHeader.vue";
  
  export default {
	components: {
	  MainHeader,
	  Footer,
	},
  };
  </script>
  <style>
  .main-content-wrapper h2,
  .main-content-wrapper h4 {
	color: #071649;
	margin-bottom: 20px;
  }
  .main-content-wrapper p {
	margin-bottom: 20px;
	font-size: 16px;
	font-weight: 400;
  }
  </style>
  