var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"payment-wrapper payment-wrapper-mob"},[_c('div',{staticClass:"payment-inner-wrap"},[_c('section',{staticClass:"pricings pt-5",attrs:{"id":"pricings"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"main-title text-center"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"payment_cards"},[_c('h4',[_vm._v("Choose a plan")]),_c('div',{staticClass:"subscription-form-wrap"},[_c('form',{attrs:{"action":"#1"}},[_c('div',{staticClass:"form-group custom-select-box s",style:([
                          _vm.picked == 2
                            ? { 'background-color': '#0c5185' }
                            : '',
                          _vm.subbed_annual ? { opacity: 0.5 } : '',
                        ])},[_c('span',{staticClass:"best-value"},[_vm._v("Best Value")]),_c('label',{staticClass:"subscription_packages",attrs:{"for":"annual"}},[_c('p',{staticClass:"monthly-text",style:([_vm.picked == 2 ? { color: '#fff' } : ''])},[_vm._v(" Annual - Billed at $77.99 ")]),_c('p',{staticClass:"days-text"},[_vm._v("14 days free")]),_c('p',{staticClass:"price-text",style:([_vm.picked == 2 ? { color: '#ffffff' } : ''])},[_vm._v(" "+_vm._s(!_vm.subbed_annual ? " $ 6.49/month" : "Already Subscribed")+" ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.picked),expression:"picked"}],attrs:{"disabled":_vm.subbed_annual,"type":"radio","id":"annual","name":"subscription_plan","value":"2"},domProps:{"checked":_vm._q(_vm.picked,"2")},on:{"change":[function($event){_vm.picked="2"},function($event){return _vm.changePlan()}]}})]),_c('div',{staticClass:"form-group custom-select-box",style:([
                          _vm.picked == 1
                            ? { 'background-color': '#0c5185' }
                            : '',
                          _vm.subbed_month ? { opacity: 0.5 } : '',
                        ])},[_c('label',{staticClass:"subscription_packages",attrs:{"for":"monthly"}},[_c('p',{staticClass:"monthly-text",style:([_vm.picked == 1 ? { color: '#fff' } : ''])},[_vm._v(" Monthly ")]),_c('p',{staticClass:"days-text"},[_vm._v("Monthly payment")]),_c('p',{staticClass:"price-text",style:([_vm.picked == 1 ? { color: '#fff' } : ''])},[_vm._v(" "+_vm._s(!_vm.subbed_month ? " $ 11.99/month" : "Already Subscribed")+" ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.picked),expression:"picked"}],attrs:{"disabled":_vm.subbed_month,"type":"radio","id":"monthly","name":"subscription_plan","value":"1"},domProps:{"checked":_vm._q(_vm.picked,"1")},on:{"change":[function($event){_vm.picked="1"},function($event){return _vm.changePlan()}]}})])])])]),_c('div',{staticClass:"payment_cards"},[_c('h4',[_vm._v("Choose payment method")]),_c('div',{staticClass:"row payment-methods"},[_c('div',{staticClass:"col-md-6 p-0 paypal-button-col"},[_c('button',{staticClass:"payment_btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.showPaypal(true)}}},[_c('img',{attrs:{"src":"/assets/images/resource/paypal.png","width":"30px","alt":""}}),_c('span',[_vm._v("Paypal")])]),(_vm.payment_method == 'paypal')?_c('div',{staticClass:"paypal-body"},[(
                            _vm.payment_method == 'paypal' &&
                            _vm.$store.state.intent_generated != false
                          )?_c('Paypal',{key:_vm.picked,attrs:{"plan_id":_vm.picked,"promo_token":_vm.promo_token,"promo_code":_vm.promo_code}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"col-md-6 p-0 stripe-button-col"},[_c('button',{staticClass:"payment_btn",attrs:{"type":"button"},on:{"click":function($event){_vm.setPaymentMethod('stripe'), (_vm.show_paypal = false)}}},[_c('img',{attrs:{"src":"/assets/images/resource/credit-card.png","width":"30px","alt":""}}),_c('span',[_vm._v(" Credit or Debit Card ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(
                          _vm.payment_method == 'stripe' && _vm.show_paypal == false
                        )?_c('div',{staticClass:"stripe-body"},[(
                            _vm.payment_method == 'stripe' &&
                            _vm.$store.state.intent_generated != false
                          )?_c('Stripe',{key:_vm.picked,attrs:{"plan_id":_vm.picked,"promo_token":_vm.promo_token,"promo_code":_vm.promo_code,"actual_price":_vm.actual_price,"discount":_vm.discount}}):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"paypal_btn_div text-center"},[_c('div',{staticClass:"total"},[_c('div',{staticClass:"row total-top"},[_vm._m(3),_c('div',{staticClass:"col-md-6 text-right"},[_c('p',[_vm._v("$ "+_vm._s(_vm.actual_price))])])]),_c('div',{staticClass:"row total-top color-discount"},[_vm._m(4),_c('div',{staticClass:"col-md-6 text-right"},[_c('p',[_vm._v("$ "+_vm._s(_vm.discount))])])]),_vm._m(5),_c('div',{staticClass:"row total-middle"},[_vm._m(6),_c('div',{staticClass:"col-md-6 text-right"},[_c('span',[_vm._v(" $ "+_vm._s(_vm.$store.state.final_price)+" ")])])]),(
                              _vm.$store.state.user &&
                              !_vm.$store.state.user.trial_used &&
                              _vm.picked == '2'
                            )?_c('div',{staticClass:"row total-middle"},[_vm._m(7),_vm._m(8)]):_vm._e()]),(_vm.show_paypal)?_c('a',{staticClass:"paypal_btn_payment",on:{"click":function($event){return _vm.setPaymentMethod('paypal')}}},[_c('img',{attrs:{"src":require("../../public/assets/images/icons/paypalicon.png"),"alt":""}})]):_vm._e()])])])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sec-heading style1"},[_c('h3',[_vm._v("Manage My Subscriptions")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row banner justify-content-center m-0 pt-2 pb-4"},[_c('img',{attrs:{"width":"72px","src":require("../../public/assets/images/logo-mob.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sec-heading style1 mb-4"},[_c('h4',[_vm._v("Believe Hypnosis App")]),_c('h4',[_vm._v("Available Plans")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('h6',[_vm._v("Subtotal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('h6',[_vm._v("Discount")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('h4',[_vm._v("Total USD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('h4',[_vm._v("Total During Trial USD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 text-right"},[_c('span',[_vm._v(" $ 0.00 ")])])
}]

export { render, staticRenderFns }