<template>
  <div class="main-terms-content banner-bg">
    <MainHeader></MainHeader>
    <div class="main-content-wrapper pb-5">
      <div class="container">
        <div class="row ">
          <div class="col-md-12">
            <h2>TERMS & CONDITIONS</h2>
            <p>
              Believe is operated and wholly owned by Victoria Gallagher,
              Certified Hypnotist and Believe, LLC and companies.
            </p>
            <p>
              By using these recordings, you agree to be legally bound by these
              terms. These terms may be updated by us occasionally without
              notice to you. You should review the website every now and then
              for changes to these terms. Important Information Our Hypnotherapy
              recordings are intended to help you to make positive enhancements
              to your life, using our hypnosis recordings.
            </p>
            <p>
              Our hypnosis apps and recordings are not a substitute for medical
              treatment. Hypnotherapy is not intended to diagnose, cure or
              prevent any disease or disorder of any kind. If you are in any
              doubt you should seek the advice of your doctor or therapist. You
              agree to always follow the instructions and advice which come with
              the products and information on this website and associated
              websites. When listening to recorded hypnosis sessions, you must
              be in a safe environment where you can take approximately 30
              minutes to relax. You must never listen to hypnosis, our apps, or
              hypnosis recordings while driving or when you need to maintain
              alertness.
            </p>
            <h4>Permitted use of hypnotherapy recordings:</h4>
            <p>
              You purchase of this any of our hypnosis recordings through any of
              our purchasing channels constitutes your understanding and
              agreement to listen to the recordings as we have suggested.
            </p>
            <p>
              This Website provides for additional services and subscriptions
              available upon payment of a fee. The fees, duration and conditions
              for the accounts and paid services are highlighted in the relevant
              section of this Website. All content is copyright protected and is
              for personal use only. You may not use any part of the content for
              commercial purposes. You may not reproduce, duplicate, copy, sell,
              resell or exploit any portion of this Website, the recordings,
              trademarks, nominal or figurative, and all other marks, trade
              names, illustrations, images, or logos.
            </p>
            <p>
              We reserve the right to suspend recordings or apps at any time.
            </p>
            <h4>Disclaimers</h4>
            <p>
              Your use of the our hypnosis recordings and apps is at your sole
              risk.
            </p>
            <h4>Disclaimers & Liability</h4>
            <p>
              So far as permitted by law, and except in respect of death or
              personal injury arising from negligence, our liability for loss or
              damage of any kind resulting from the use of our hypnosis
              recordings or any content, advertisements or products provided or
              distributed through the website or other channels or our websites
              including, without limitation, economic loss or any special,
              indirect, incidental or consequential loss or damage, whether or
              not the possibility of such loss has been notified to us shall be
              limited to the aggregate purchase price of your hypnosis apps and
              downloads. The foregoing will apply whether such loss or damage
              arises in contract, negligence, under statute or otherwise.
              Nothing in these terms affects any liability for fraudulent
              misrepresentation.
            </p>
            <h4>General Information</h4>
            <p>
              These terms constitute the entire agreement between you and us.
              Any failure by us to exercise or enforce any provision of these
              terms shall not constitute a waiver of such provision. The Owner
              reserves the right to modify these Terms at any time, informing
              Users by publishing a notice within this Website. Users who
              continue to use this Website after the publication of the changes
              accept the new Terms in their entirety.
            </p>
            <p>
              These Terms and any dispute concerning the implementation,
              interpretation and validity of this agreement are subject to
              United States’ law.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../components/footer.vue";
import MainHeader from "../components/MainHeader.vue";

export default {
  components: {
    MainHeader,
    Footer,
  },
};
</script>
<style>
   .main-content-wrapper h2 , .main-content-wrapper h4 {
    color: #071649;
    margin-bottom: 20px;
}
.main-content-wrapper p{
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
}
</style>
