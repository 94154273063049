<template>
    <div class="page-wrapers main-terms-content">
        <MainHeader></MainHeader>
        <section class="same-layout payments-plans">
            <div class="inner-left pt-5">
                <div class="col-sm-12">
                    <br />
                    <br />
                    <br />
                    <br />
                    <span class="banner-para"><b>DREAM</b> it, <b>BELIEVE</b> it, and <b>ACHIEVE</b> your
                        DESIRES</span>
                    <ul class="banner-list">
                        <li>Speed up your manifestations</li>
                        <li>Access 1000+ powerful LOA hypnosis audios</li>
                        <li>Members only personal growth courses</li>
                        <li>Videos, Scripts, Live Events + Much More</li>
                        <li>Start Living a Happier More Abundant life now</li>
                    </ul>
                </div>
            </div>
            <div  class="inner-right pt-5">
                <div class="col-sm-12">
                    <div id="login" class="tab-pane active text-center">
                        <div class="p-a30 dez-form">
                            <h3 class="trial-text m-t0 mb-2">
                                {{ this.text1 }}
                            </h3>
                            <p class="price-text">$ {{ $store.state.final_price }} today</p>
                            <p class="sub-text-inner">
                                {{ this.text2 }}
                            </p>

                            <!-- <p>Enter your email address and your password.</p> -->

                            <img src="../../public/assets/images/icons/visa-logo.png" alt=""
                                class="img-responsive mt-4 mb-3" width="80%" />

                            <div class="row">
                                <div class="col">
                                    <div  class="stripe-body">
                                        <Stripe v-if="
                                            $store.state.intent_generated != false
                                        " :plan_id="this.planId" :promo_token="this.promoToken" :promo_code="this.promoCode" :key="this.planId"
                                            :actual_price="this.actualPrice" :discount="this.discount" />
                                    </div>
                                </div>
                            </div>

                            <div class="card-text mt-3">
                                <p>
                                    {{ this.text3 }}
                                </p>
                                <p>
                                    You may cancel your subscription at any time by going to your
                                    profile and following the intructions in the Manage
                                    Subscription page.
                                </p>
                            </div>

                            <div class="form-group text-left">
                                <!-- <button type="button" class="site-button main-btn pull-right" @click="btn_register">
                  <span>Get Access to Believe Premium Now</span>
                </button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Content -->

        <!-- Content END-->
    </div>
</template>
<script>
import MainHeader from "../components/MainHeader.vue";
import Stripe from "../components/Stripe.vue";
import API from "@/config/api";
// import API from "../config/api";
// import LoginModal from "../Modal/LoginModal.vue";

export default {
  components: {
    MainHeader,
    Stripe,
  },

  data() {
    return {
      promoCode: null,
      promoToken: null,
      planId: null,
      actualPrice: null,
      discount: null,
      text1: null,
      text2: null,
      text3: null,
    };
  },
  computed: {
  },
  mounted() {

  },
  created() {
    this.fillState();
    setTimeout(() => {
      this.getSessionPlan();
    }, 2000);
    console.log("state", this.$store.state.subFormData[0])
  },
  updated() {
  },
  methods: {
    async getSessionPlan() {
      try {
        const response = await API.get(`/session-plan/get?user_id=${this.$store.state.user.id}&token=${localStorage.getItem("token")}`);
        this.planId = response.data.entry.chosen_plan_id;
        if (response.data?.entry?.payload) {
          this.$store.state.subFormData[0] = response.data.entry.payload;
          this.fillState();
        }
        if (response.data?.entry?.promo_code) {
          this.promoCode = response.data.entry.promo_code;
          this.applyPromo();
        }
      } catch (error) {
        console.log(error);
      }
    },
    fillState() {
      this.text1 = this.$store.state.subFormData[0]['text1']
      this.text2 = this.$store.state.subFormData[0]['text2']
      this.text3 = this.$store.state.subFormData[0]['text3']
      this.planId = this.$store.state.subFormData[0]['planId']
      this.promoCode = this.$store.state.subFormData[0]['promo_code']
      this.promoToken = this.$store.state.subFormData[0]['promo_token']
      this.actualPrice = this.$store.state.subFormData[0]['actual_price']
      this.discount = this.$store.state.subFormData[0]['discount']
      this.$store.state.intent_generated = this.$store.state.subFormData[0]['intent_generated']

      if (this.$store.state.final_price < 0.01) {
        this.$store.state.final_price = this.actualPrice;
      }
    }
  },
};
</script>
