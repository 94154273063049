<template>
    <div class="home-section main-terms-content">
      <MainHeader></MainHeader>
      <section class="pricings pt-5 pb-5 success-main" id="pricings">
        <div class="container">
          <div class="row pt-5 align-items-center">
            <div class="col-md-6 col-sm-12 hide-it">
              <div class="banner-images">
                <img
                  class="img-1 img-fluid"
                  src="../../public/assets/images/resource/iPhone 13 Mockup-3.png"
                  alt=""
                />
                <img
                  class="img-2 img-fluid"
                  src="../../public/assets/images/resource/iPhone 13 Mockup.png"
                  alt=""
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="get-app-wrap">                  
                <h4 class="success-text text-success">
                  {{ this.header_message }} <i class="fa fa-check-circle"></i>
                </h4>
                <h2 class="banner_text">Believe</h2>
                <span class="banner-para-text">
                  DREAM IT, BELIEVE IT & see yourself ACHIEVE your life purpose
                  with passion and prosperity.
                </span>
                <span class="banner-para">Get Free Believe App Now!</span>
                <div class="pt-4 pb-4"></div>
                <div class="get-in-btn-div">
                  <a href="https://play.google.com/store/apps/details?id=com.hyptalk.believe" target="_blank" class="img-1"
                    ><img
                      class="img-fluid"
                      style="width: 170px !important;"
                      src="../../public/assets/images/resource/Googleplay.png"
                      alt=""
                    />
                  </a>
  
                  <a href="https://apps.apple.com/us/app/believe-hypnosis/id6466321566" target="_blank" class="img-1" style="margin-left: 0px !important"
                    >
                    <!-- <img
                      class="img-fluid"
                      src="../../public/assets/images/icons/apple.png"
                      alt=""
                    /> -->
                    <img
                      class="img-fluid"
                      style="width: 170px !important; margin-left: 0px !important;"
                      src="../../public/assets/images/resource/App-Store.png"
                      alt=""
                    />
                    
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import MainHeader from "../components/MainHeader.vue";
  
  export default {
    components: {
      MainHeader,
    },
    data() {
      return {
        header_message: null,
      };
    },
    created() {
        this.header_message = this.$store.state.stripeMessage;
    },
    mounted() {
    },
    methods: {
    }
  };
  </script>
  
  <style>
  .success-main {
    min-height: 100vh;
    display: grid;
    align-items: center;
  }
  @media only screen and (max-width: 600px) {
    .success-main {
      display: initial;
      height: auto;
    }
    .success-main .banner-images {
      margin-top: 0;
    }
    .success-main .banner-images img {
      width: 70%;
    }
    .success-main .get-app-wrap * {
      text-align: center;
    }
    .success-main span.banner-para {
      max-width: 100% !important;
      margin: 0 auto;
      text-align: center;
      display: block;
    }
  }
  </style>
  