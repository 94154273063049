<template>
    <div class="main-terms-content banner-bg">
      <MainHeader></MainHeader>
      <div class="main-content-wrapper pb-5">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h2>Help with a payment issue/refund request</h2>
                <p>Learn more about how to request a refund for your purchase on web, iOS or Android.</p>
                <h4>1. If you purchased your subscription on an iOS device, in the AppStore:</h4>
              <p>
                You will have to contact iTunes Support as refunds must be issued by the iTunes Support team directly and the Believe does not have access to them (see iTunes Terms of Service for more information). 
              </p>
              <h6>You can reach out to them via the page below:</h6>
             
              <p class="anchor-class">
                <a href="https://getsupport.apple.com"
                  >https://getsupport.apple.com</a
                >
              </p>
              <ul>
                <li>Go to <b>Billing & Subscriptions</b></li>
                <li>Select <b>Subscriptions & Purchases</b></li>
                <li>Select <b>Request a refund</b></li>
                <li>Select <b>Email Support?</b></li>
              </ul>

              <p><b>Useful tip:</b> The App Store and iTunes Store policies state that all sales are final, though there are some exceptions:</p>
              
              <ul>
                <li>If the purchase was accidental</li>
                <li>If you have duplicate purchases</li>
                <li>If the app does not work as described</li>
                <li>If you are charged but do not receive the item</li>
                <li>If the app is not compatible with your device</li>
              </ul>

              <h4>2. If you purchased your subscription on an Android device, in the Google Play Store:</h4>
             <p>You may request a refund through the Believe support team <b>within 30 days from your date of purchase</b>.</p>
             <h6>Make sure to include the following in your request:</h6>
             <ul>
                <li>Your Google Play transaction number (starts with <b>GPA</b>.) or tell us which email address is linked to your Google Account</li>
                <li>The reason for your refund request</li>
             </ul>

             <h4><b>3. If you purchased your subscription on our website Believe.com</b>, please send us the <b>invoice or receipt</b> number of your purchase. It is located in the email sent after the purchase was made.</h4>
             <p> Still have questions? Send us a message. We're here to help 😊</p>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </template>
  <script>
  import Footer from "../components/footer.vue";
  import MainHeader from "../components/MainHeader.vue";
  
  export default {
    components: {
      MainHeader,
      Footer,
    },
  };
  </script>
  <style>
  .main-content-wrapper h2,
  .main-content-wrapper h4 {
    color: #071649;
    margin-bottom: 20px;
  }
  .main-content-wrapper p {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
  }
  .main-content-wrapper p.anchor-class {
    margin-bottom: 10px;
  }
  .main-content-wrapper ul {
    margin-left: 20px;
  }
  .main-content-wrapper ul li{
      margin-bottom: 10px;
  }
  </style>
  