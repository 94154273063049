//  const baseURL ="https://virtualrealitycreators.com/Believe-Backend/api"
//const baseURL ="http://localhost:8000/api"
import axios from "axios";
import { store } from "../store";
const baseURL = store.state.baseurl;
let API = axios.create({ baseURL });

API.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem("token");
    if (!token) delete config.headers["Authorization"];
    else config.headers["Authorization"] = `Bearer ${token}`;
    store.state.isLoading = true;
    return config;
  },
  function (error) {
    store.state.isLoading = false;

    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    store.state.isLoading = false;

    return response;
  },
  function (error) {
    store.state.isLoading = false;

    return Promise.reject(error);
  }
);

export default API;
