<template>
  <div class="client-reviews-section">
    <!-- <div class="container"> -->
      <div class="row pb-5">
        <div class="col-md-12 text-center">
          <h2>Awesome 5-star reviews</h2>
          <p class="tagline-para">
            Experience the Global Phenomenon of Believe: 5-Star Reviews from Around the World
          </p>
        </div>
      </div>
      
        <TestimonialSlider></TestimonialSlider>
      
    <!-- </div> -->
  </div>
</template>

<script>
import TestimonialSlider from "../components/TestimonialSlider.vue";
  export default {
  components: {

    TestimonialSlider,
}
};
</script>

<style>
.client-reviews-section {
  position: relative;
  margin: 120px 0 80px 0;
}
.client-reviews-section h2 {
  font-size: 45px;
  color: black;
  text-transform: capitalize;
}
.client-reviews-section p.tagline-para {
  color: black;
  font-size: 26px;
  text-align: center;
  text-transform: capitalize;
}

.client-reviews-section .reviews {
    padding: 50px 50px 60px 40px;
    border-radius: 10px;
    background: #3dbcb826;
    min-height: 380px;
}
.client-reviews-section .reviews .stars {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.client-reviews-section .reviews .stars li i {
  color: #d0a73e;
  font-size: 22px;
}
.client-reviews-section .reviews .stars li {
  margin: 0 5px;
}
.client-reviews-section .reviews .stars li:first-child {
  margin-left: 0;
}
.client-reviews-section .reviews h4.review-title {
  font-size: 24px;
  color: black;
  font-weight: 400;
}

.client-reviews-section .reviews .client-avatar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.client-reviews-section .reviews .client-avatar img {
  height: 60px;
  width: 60px;
}
.client-reviews-section .reviews .client-avatar .client-img {
  margin-right: 10px;
}
.client-reviews-section .reviews .client-avatar .client-excerpt * {
  margin: 0;
  padding: 0;
  line-height: 1.3em;
}
.client-reviews-section .reviews .client-avatar .client-excerpt h6 {
  font-size: 14px;
}
.client-reviews-section .reviews .client-avatar .client-excerpt p {
  font-size: 14px;
}
.client-reviews-section .reviews .client-avatar .client-excerpt p.review-type {
  font-size: 10px;
  color: #00000080;
  font-weight: 400;
}
</style>
