<template>
  <div></div>
</template>

<script>
import API from "../config/api";
export default {
  data() {
    return {};
  },
  mounted() {},
  created() {
    this.verifyToken();
  },
  methods: {
    async verifyToken() {
      try {
        let token = this.$route?.query?.token;
        let promo_group = this.$route?.query?.promo_group;
        if(promo_group == undefined){
          promo_group = "SU";
        }
        if (token && token != "") {
          const response = await API.post("/validate-promolink-free-users", {
            token,
            promo_group,
          });
          console.log(response)
          localStorage.setItem("token", response.data.api_token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          setTimeout(() => {
            window.location.href = response.data.link;
          }, 0);
          window.location.href = response.data.link;
        }
      } catch (error) {
        console.log(error.response.data);
      }
    },
  },
};
</script>

<style></style>
