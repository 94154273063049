<template>
  <div class="section-five">
    <div class="container">
      <div class="row pb-5">
        <div class="col-md-12 text-center">
          <h2>Try BELIEVE Free</h2>
        </div>
      </div>
      <div class="row align-items-start grid-get-started">
        <div class="col text-center">
          <img
            src="../../public/assets/images/resource/calendar 1.png"
            alt="image"
          />
          <h6>Set daily reminders to stay on track to achieve your goals.</h6>
        </div>
        <div class="col text-center">
          <img
            src="../../public/assets/images/resource/calendar 2.png"
            alt="image"
          />
          <h6>Discover personalized recommendations for you.</h6>
        </div>
        <div class="col text-center">
          <img
            src="../../public/assets/images/resource/calendar 3.png"
            alt="image"
          />
          <h6>Participate in impactful online live events.</h6>
        </div>
        <div class="col text-center">
          <img
            src="../../public/assets/images/resource/calendar 4.png"
            alt="image"
          />
          <h6>
            Gain access to meditative audios, videos, scripts, and courses.
          </h6>
        </div>
        <div class="col text-center">
          <img
            src="../../public/assets/images/resource/calendar 5.png"
            alt="image"
          />
          <h6>Track your progress with a personalized stats dashboard.</h6>
        </div>
      </div>
      <!-- <div class="row justify-content-center pt-5 pb-5">
        <div class="col-10 section-five-inner">
          <div class="subscription-form-wrap">
            <form action="#1">
              <div class="form-group custom-select-box">
                <span class="best-value">Best Value</span>
                <label class="subscription_packages" for="annual">
                  <p class="monthly-text">Annual - Billed at $77.99</p>
                  <p class="days-text">14 days free</p>
                  <p class="price-text">$6.49/month</p>
                </label>

                <input
                  value="2"
                  type="radio"
                  id="annual"
                  name="subscription_plan"
                  v-model="$store.state.selected_package"
                />
              </div>
              <div class="form-group custom-select-box">
                <label class="subscription_packages" for="monthly">
                  <p class="monthly-text">Monthly</p>
                  <p class="days-text">Monthly payment</p>
                  <p class="price-text">$ 11.99/month</p>
                </label>

                <input
                  value="1"
                  type="radio"
                  id="monthly"
                  name="subscription_plan"
                  v-model="$store.state.selected_package"
                />
              </div>
            </form>
          </div>
        </div>
      </div> -->
      <!-- <div class="row justify-content-center">
        <div class="subscription-tagline">
          <p>
            After your free trial, the annual subscription is $77.99 USD and
            automatically renews each year.
          </p>
        </div>
      </div> -->
      <div class="row justify-content-center mt-5 full-width-mob">
        <div class="col-5">
          <router-link
            v-if="$store.state.user == null"
            to="/register"
            class="banner-cta fold-five-btn"
            >Try BELIEVE Free</router-link
          >
          <router-link
            v-if="$store.state.user != null"
            to="/plans"
            class="banner-cta fold-five-btn"
            >Try BELIEVE Free</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: 2,
    };
  },
};
</script>
<style>
.section-five {
  padding: 100px 0 50px 0;
  background-color: #3dbcb826;
  background-color: #e7eef1;
}
.section-five h2 {
  font-size: 45px;
  color: black;
  text-transform: capitalize;
}
.section-five .grid-get-started img {
  height: 40px;
  width: 40px;
  object-fit: contain;
}
.section-five .grid-get-started h6 {
  font-size: 21px;
  font-weight: 400;
  margin-top: 20px;
}
.section-five .grid-get-started .col {
  margin: 0 20px;
}
.section-five .grid-get-started .col:first-child,
.section-five .grid-get-started .col:last-child {
  margin: 0;
}
.section-five-inner .subscription-form-wrap form {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  align-content: center;
}
.section-five-inner .subscription-form-wrap form .custom-select-box {
  width: 40%;
}
.section-five-inner .subscription-tagline p {
  font-size: 18px;
}

.section-five a.fold-five-btn {
  margin: 0 auto;
}
</style>
