<template>
  <div class="main-terms-content banner-bg">
    <MainHeader></MainHeader>
    <div class="main-content-wrapper pb-5">
      <div class="container">
        <div class="row ">
          <div class="col-md-12">
            <h2>Coming Soon: Affiliate Program for Believe Hypnosis</h2>
            <p>
              Welcome to the Believe Hypnosis Affiliate Program! We're currently working on creating an exciting opportunity for you to partner with us and help spread the word about our transformative app while earning a commission on each referral. By becoming an affiliate, you'll be able to make a positive impact on people's lives while generating income for yourself.
            </p>
            <p>
              <b>⏳ Our Affiliate Program is coming soon!</b>
            </p>
            <p>
              If you're interested in becoming an affiliate, please fill out the form below to register your interest. We'll notify you as soon as our Affiliate Program is launched!
            </p>
            <p>
             <b>Our affiliate program will be perfect for:</b>
            </p>
            <p>
              <ul class="affiliate-points">
                  <li>Personal development coaches</li>
                  <li>Law of Attraction enthusiasts</li>
                  <li>Hypnotherapists</li>
                  <li>Wellness influencers</li>
                  <li>Bloggers and content creators in the personal growth niche</li>
              </ul>
            </p>
            <p>
             <b>By joining our affiliate program, you'll receive:</b>
          </p>
          <p>
              <ul class="affiliate-points">
                  <li>A generous commission structure</li>
                  <li>Marketing materials and promotional resources</li>
                  <li>Ongoing support from our team</li>
                  <li>Exclusive updates and insider information</li>
              </ul>
            </p>
            <p>
              Don't miss out on this opportunity to become a part of the Believe Hypnosis Affiliate Program. Register your interest now, and stay tuned for more details on the program's launch!
            </p>
            <p>In the meantime, we invite you to explore the Believe Hypnosis app, join our Facebook group "Believe Hypnosis: Manifest Your Dreams," and connect with our growing community of like-minded individuals.
            </p>
           
            <p>
            </p>
          </div>
        </div>
      </div>
    </div>

    <iframe
      id="JotFormIFrame-231167337688163"
      title="New Customer Registration Form"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allowfullscreen="true"
      allow="geolocation; microphone; camera"
      src="https://form.jotform.com/231167337688163"
      frameborder="0"
      style="min-width:100%;max-width:100%;height:1700px;border:none;"
      scrolling="no"
    >
    </iframe>
    <Footer></Footer>
    

  </div>
  
</template>
<script>
import Footer from "../components/footer.vue";
import MainHeader from "../components/MainHeader.vue";

export default {
  components: {
    MainHeader,
    Footer,
  },
  
};
</script>


<style>
   .main-content-wrapper h2 , .main-content-wrapper h4 {
    color: #071649;
    margin-bottom: 20px;
}
.main-content-wrapper p{
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
}

</style>