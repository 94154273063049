<template>
  <div
    class="about-section"
    style="
      background-color: #e2f5f5;
      padding: 30px 0px 30px 0px;
      text-align: center;
      margin: 0;
      width: 100vw;
      color: #fff;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    "
  >
    <h1 style="padding: 15px 0px 0px 0px; margin: 0px; margin-bottom: 15px">
      Frequently Asked Questions
    </h1>

    <div class="accordion-box">
      <h2>About Our App</h2>

      <div class="accordian-top">
        <button class="accordion">What is Believe?</button>
        <div class="panel">
          <p>
            Believe is like Netflix for personal growth. You get access to many meditations, hypnosis tracks and affirmations created by Victoria Gallagher. 
          </p>
          <p>
            With Believe, you can find inspiration throughout the day with topics ranging from manifestation, relaxation, sleep, focus, and creativity.
          </p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">What happened to HypnoCloud?</button>
        <div class="panel">
          <p>
            HypnoCloud is still available, however our engineers found some coding issues that went beyond repair. To help you continue your journey, we created a new and improved version of the app called Believe. We wanted to update our app, with the new features, but later found it wasn't possible to transfer our subscribers without the risk of data loss. We've decided not to update “HypnoCloud” and instead launch “Believe” as a stand-alone app.
          </p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">Is Believe Free?</button>
        <div class="panel">
          <p>
            When you sign up, you’ll be able to use the app at no cost. Free users of our app enjoy limited content and features
          </p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">Why do you charge money?</button>
        <div class="panel">
          <p>
            The time, energy and money that go into creating high-quality audio recordings and developing our app is ongoing. Maintaining and improving it requires a lot of hard work and it’s a team effort. 
          </p>
          <p>
            When you sign up, you’ll be able to use the app at no cost. Free users of our app enjoy limited content and features, but Pro subscribers help give us the means to keep developing the app and receive unlimited access to all the content and features.
          </p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">
          What are the features of having a Pro Subscription?
        </button>
        <div class="panel">
          <p>
            Those who are pro-users have more privileges. They get access to unlimited content, unlimited playlists, download sessions for offline use and can attend live events and take courses. Double points are awarded which can then be traded in for special rewards!
          </p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">How long is the trial period?</button>
        <div class="panel">
          <p>
            <b
              >Standard trial period is 14 days for an annual subscription, unless subscribed to monthly - no trial period.</b
            >
          </p>
          <!-- <p style="text-decoration: line-through;">$11.99 mo.</p> -->
          <p>$11.99 monthly</p>
          <p>$77.99 year ($6.49 mo.)  50% Savings</p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">How do I cancel my subscription?</button>
        <div class="panel">
          <p>To cancel your subscription, follow these steps:</p>
          <p>1.	Open the BELIEVE app and go to the 'Settings' menu.</p>
          <p>2.	Click on 'Manage.'</p>
          <p>3.	You'll be redirected to the platform where you originally purchased your subscription—Apple App Store, Google Play Store, or our website.</p>
          <p>4.	Follow the platform-specific instructions to cancel your subscription.  </p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">Can I get a refund?</button>
        <div class="panel">
          <p>For refund inquiries, please follow the guidelines based on your original point of purchase:</p>
          <p><b>iOS Users:</b> All refund requests for iOS are handled by Apple. Please navigate to the Apple App Store and follow their refund process.</p>
          <p><b>Google Play Store and Website Users:</b> For purchases made through Google Play Store or our website, contact us directly at orders@hyptalk.com to request a refund.</p>
          <p>For more details, please refer to the complete refund policy in the Terms and Conditions section of the app.</p>
        </div>
      </div>

      <h2>About Hypnosis</h2>

      <div class="accordian-top">
        <button class="accordion">What is hypnosis?</button>
        <div class="panel">
          <p>Hypnosis is a state of focused attention, heightened suggestibility, and deep relaxation. It's often used to help modify behaviors, reduce stress, or achieve specific goals. It's not sleep or unconsciousness; rather, it's a way to access the subconscious mind to facilitate change. The process is usually guided by a trained hypnotherapist but can also be self-administered.</p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">What are the benefits of hypnosis?</button>
        <div class="panel">
          <p>Hypnosis can offer a wide range of benefits, such as stress reduction, pain management, and behavioral modification. It's often used to help people quit smoking, lose weight, and improve focus or performance.</p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">Who can be hypnotized?</button>
        <div class="panel">
          <p>Most people can be hypnotized to some extent, as long as they are willing and open to the experience. However, the depth of hypnosis and its effectiveness can vary from person to person.</p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">How does hypnosis work?</button>
        <div class="panel">
          <p>Hypnosis works by guiding the individual into a relaxed state where the subconscious mind becomes more accessible. Once in this state, positive suggestions and guided imagery can help influence thoughts, feelings, and behaviors.</p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">
          Are there any side effects of hypnosis?
        </button>
        <div class="panel">
          <p>Generally, hypnosis is considered safe with minimal side effects. Some people may experience mild disorientation immediately after a session, but this usually dissipates quickly. It's essential to work with a trained and qualified hypnotherapist.</p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">
          What’s the difference between Meditation and Hypnosis?
        </button>
        <div class="panel">
          <p>Meditation and hypnosis both involve states of deep relaxation but are used for different purposes. Meditation focuses on mindfulness and the cultivation of a certain state of being. Hypnosis, on the other hand, aims to tap into the subconscious to facilitate behavioral or emotional change.</p>
        </div>
      </div>

      <h2>About our Founder</h2>

      <div class="accordian-top">
        <button class="accordion">Who founded the company?</button>
        <div class="panel">
          <p>Victoria Gallagher, our esteemed founder, is a pioneer in blending hypnotherapy with the Law of Attraction, with a career spanning over two decades.</p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">What is your company's mission?</button>
        <div class="panel">
          <p>Our mission is to empower 10 million users worldwide by 2025 to unlock their full potential and manifest success across various life avenues, through our revolutionary BELIEVE app and other transformative digital products.</p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">What are your goals for the company?</button>
        <div class="panel">
          <p>1.	Scale the BELIEVE app to reach 10 million subscribers across the globe by 2025.</p>
          <p>2.	Establish Victoria as a leading influencer in the fields of hypnotherapy and Law of Attraction.</p>
          <p>3.	Contribute to philanthropic endeavors, particularly those focused on feline welfare.</p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">How long ago was the company founded?</button>
        <div class="panel">
          <p>Victoria founded our flagship company, HypTalk, in 1999, which continues to offer individual MP3s. Our first app, HypnoCloud, was launched in 2015. These ventures have set the stage for our newest endeavor, BELIEVE.</p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">
          How many employees does the company have?
        </button>
        <div class="panel">
          <p>We operate with a dynamic, non-traditional team structure, leveraging the skills of contractors and freelancers across various domains, including development, customer support, marketing, and content creation.</p>
        </div>
      </div>

      <div class="accordian-top">
        <button class="accordion">Where is your operational base?</button>
        <div class="panel">
          <p>We are based in Phoenix, Arizona</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.about-section p {
  color: #000;
}
.accordion-box h2 {
  color: #071649;
  font-size: 24px;
}
.accordion {
  background: #3dbcb826;
  color: #071649;
  /* background: #071649; */
  color: #000;
  cursor: pointer;
  padding: 20px;
  font-weight: bold;
  width: 93%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  border-radius: 10px;
  position: relative;
}
.accordian-top {
  margin-bottom: 10px;
}
.accordion .active,
.accordion:hover {
  /* background-color: #3dbcb8; */
    /* color: #071649; */
}

.accordion:after {
  content: "\002B";
  color: #000;
  font-weight: bold;
  position: absolute;
  font-size: 22px;
  top: 21%;
  right: 3%;
}

.accordion .active:after {
  content: "\2212";
}
button.accordion:hover::after {
    /* color: #071649; */
}

.panel {
  padding: 0;
  background-color: transparent;
  max-height: 0;
  overflow: hidden;

  width: 93%;
  max-width: 83%;
  margin: 0 auto;
  text-align: left;
  transition: max-height 0.2s ease-out;
}

.accordion-box {
  max-height: 80vh;
  overflow: auto;
}
</style>

<script>
export default {

  name: "component_name",
  mounted() {
    let acc = document.getElementsByClassName("accordion");
    console.log(acc, "test");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active0");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }
  },
};
</script>
