<template>
  <div class="main-terms-content banner-bg">
    <MainHeader></MainHeader>
    <div class="main-content-wrapper pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2>How do I manage/cancel my subscription?</h2>
    
            <h6><b>Updated over a week ago</b></h6>
            <p>
              You can manage your BELIEVE subscription, auto-renewal or free
              trial at any time.
            </p>
            <h3>Important to note:</h3>
            <p>
              Subscriptions are set to automatically renew, unless they’re
              cancelled at least 24 hours before the trial ends or renewal date.
            </p>
            <p>
              If you cancel your subscription before the renewal date, you'll
              still have access to your Premium content until the end of the
              current billing cycle.
            </p>
            <p>
              Cancelling a subscription does not automatically generate a
              refund.
            </p>
            <p>
              Click <router-link to="/refund-policy">here</router-link>  to get more info about our refund policy and
              process.
            </p>

            <h4>1. If you're on Apple - iOS:</h4>
            <p>
              <b
                >For iOS purchases made in the Apple Store, you can only cancel
                your subscription by following the steps through the link
                below:</b
              >
            </p>
            <h6>Apple Subscriptions</h6>
            <p>If you want to cancel a subscription from Apple</p>
            <p>
              Learn how to cancel a subscription from Apple or a subscription
              that you purchased with an app from the App Store.
            </p>
            <p>Cancel a subscription from Apple</p>
            <p>Cancel a subscription</p>
            <p>
              How to cancel a subscription on your iPhone, iPad, or iPod touch
            </p>

            <h6>Open your device's Settings app.</h6>
            <img
              class="mb-3 mt-3"
              width="300px"
              src="../../public/assets/images/resource/ios14-settings-apple.png"
              alt=""
            />
            <ul>
              <li>Tap your name.</li>
              <li>Tap Subscriptions.</li>
              <li>Tap the subscription.</li>
              <li>
                Tap Cancel Subscription. You might need to scroll down to find
                the Cancel Subscription button. If there is no Cancel button or
                you see an expiration message in red text, the subscription is
                already cancelled.
              </li>
            </ul>

            <p>If you can't find the subscription that you want to cancel</p>
            <h4>
              2. If you purchased the subscription on Android - Google Play
              Store:
            </h4>
            <h6>Follow this link to manage your subscription:</h6>
            <p class="anchor-class">
              <a href="https://play.google.com/store/account/subscriptions"
                >https://play.google.com/store/account/subscriptions</a
              >
            </p>

            <h6>You can also try the following on your device:</h6>
            <ul>
              <li>Open the Google Play Store app</li>
              <li>Check if you’re signed in to the correct Google Account</li>
              <li>Tap Menu/Subscriptions</li>
              <li>Select the subscription you want to cancel</li>
              <li>Tap Cancel subscription</li>
              <li>Follow the instructions</li>
            </ul>

            <h4>3. If you purchased the subscription on Believe.com:</h4>
            <h6>
              For Web purchases made on Believe.com, you can cancel by logging
              in to your account and following these steps:
            </h6>
            <ul>
              <li>Subscription Settings</li>
              <li>Manage Subscription</li>
              <li>Cancel Subscription</li>
            </ul>
            <p> Still have questions? Send us a message. We're here to help 😊</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../components/footer.vue";
import MainHeader from "../components/MainHeader.vue";

export default {
  components: {
    MainHeader,
    Footer,
  },
};
</script>
<style>
.main-content-wrapper h2,
.main-content-wrapper h4 {
  color: #071649;
  margin-bottom: 20px;
}
.main-content-wrapper p {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
}
.main-content-wrapper p.anchor-class {
  margin-bottom: 10px;
}
.main-content-wrapper ul {
  margin-left: 20px;
}
.main-content-wrapper ul li{
    margin-bottom: 10px;
}
</style>
