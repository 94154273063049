<template>
    <div class="main-banner-feelings">
      <div class="container">
        <div class="row pt-5 pb-5 justify-content-center align-items-center">
          <div class="col-md-5 col-sm-12 justify-content-center align-items-center text-center">
            <div class="banner-images-feelings">
              <img
                class="img-fluid"
                width="60px"
                src="../../public/assets/images/resource/believe-icon.png"
                alt=""
              />
              <h4 class="mt-5 mb-4 pt-3">Hi friend - What are your top goals?</h4>
              <form class="servey_form">
                <div class="servey_wrapper">
                <button class=" servey_btn"><span>Sleep Better</span></button>
                <button class=" servey_btn"><span>Deal With Anxiety</span></button>
                <button class=" servey_btn"><span>Reduce Stress</span></button>
                <button class=" servey_btn"><span>Relaxy</span></button>
                <button class=" servey_btn"><span>Loss Weight</span></button>
                <button class=" servey_btn servey_btn_clicked"><span>Achieve Success</span></button>
                 </div>
                 <div class="servey_continue_wrapper m-2 mt-4">
                    <button class="servey_btn_continue"><span>Continue</span></button>
                 </div>
              </form>

            
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        checked: 2,
      };
    },
  };
  </script>
  <style>
    .main-banner-feelings {
    background: #3DBCB826;
    height: 100vh;
}
.servey_form .servey_wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.servey_form .servey_wrapper .servey_btn {
    padding: 12px 30px;
    background-color: #0C5185;
    color: white;
    border-radius: 30px;
    margin: 5px;
    border: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5em;
    cursor: pointer;
}
.servey_form .servey_wrapper .servey_btn_clicked {
    background: #0C5185;
    background: linear-gradient(45deg, #0c5185, #3dbcb8d9);
}
.servey_form .servey_wrapper .servey_btn_clicked span {
    padding-right: 5px;
}
.servey_form .servey_wrapper .servey_btn_clicked:after {
    content: '\f00c';
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
}
button.servey_btn_continue {
    width: 100%;
    padding: 12px 30px;
    background-color: #0C5185;
    color: white;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5em;
    opacity: 0.9;
}
button.servey_btn_continue:hover , button.servey_btn_continue:focus , button:focus{
    opacity: 1;
    outline: none !important;
    border: none;
}
  </style>
  