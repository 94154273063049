<template>
  <div>
    <div class="stripe-paymeny-card">
      <div v-if="elementsOptions.clientSecret && payment_intent">
        <stripe-element-payment
          ref="paymentRef"
          :pk="pk"
          :elements-options="elementsOptions"
          :confirm-params="confirmParams"
        />
        <div class="col-12 payment_btn">
          <a href="#" class="main-btn banner-cta empty-2" @click="pay"
            ><span>Complete your Subscription</span></a
          >
        </div>
      </div>
      <StripeCard
        v-if="elementsOptions.clientSecret && setup_intent"
        :options="elementsOptions"
      />
    </div>
  </div>
</template>

<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import StripeCard from "./StripeCard.vue";
import API from "../config/api";
export default {
  components: {
    StripeElementPayment,
    StripeCard,
  },
  props: {
    plan_id: String,
    promo_token: String,
    promo_code: String,
    actual_price: String,
    discount: String,
  },
  data() {
    return {
      pk: "pk_live_aXEBsfKQF0Ac22gvVxJwHsTk",
      // pk: "pk_test_51Iw6BGDjK3I8iT1C8OpxJS1NMmfrawYMrEyCImc6vfp6c0ZLCDE2XyJntKNVmqlT1xcunnPftLIVlHyql3AZiz4I00ttM70Gvr",
      elementsOptions: {
        appearance: {}, // appearance options
        clientSecret: null,
      },
      confirmParams: {
        return_url: process.env.VUE_APP_SITE_URL + '/processing', // success url
        // return_url: "http://localhost:8080/processing", // success url
      },
      payment_intent: false,
      setup_intent: false,
    };
  },
  mounted() {
    this.generatePaymentIntent();
    // this.$store.state.final_price = this.actual_price;
  },
  destroyed(){
    localStorage.removeItem('revcatSubType1');
  },
  methods: {
    async generatePaymentIntent() {
      try {
        const response = await API.post("/create-stripe-subscription", {
          plan: this.plan_id,
          promo_token: this.promo_token ?? null,
          promo_code: this.promo_code ?? null,
        });
        if(response.status == 200){
          let packageName = null;
          if(this.plan_id == '1' || this.plan_id == 1){
            packageName = "Monthly";
          }else if(this.plan_id == '2' || this.plan_id == 2){
            packageName = "Yearly";
          }
          localStorage.setItem('revcatSubType1',packageName);
        }
        // console.log(paymentIntent);
        if (response.data.type == "payment_intent") {
          this.payment_intent = true;
        } else if (response.data.type == "setup_intent") {
          this.setup_intent = true;
        }
        this.elementsOptions.clientSecret = response.data.clientSeccret;
        this.$store.state.intent_generated = true;
        // this.$store.state.final_price = paymentIntent.data.intent.amount / 100;
      } catch (err) {
        console.log(err);

        this.$store.state.intent_generated = false;

        console.log(err?.response?.data?.message);
        this.$store.state.general_error = err?.response?.data?.message ?? null;
      }
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
  },
};
// window.onload = function() {
//   let frameElement = document.getElementById("stripe-payment-element-mount-point");
//   console.log(frameElement);
//   let doc = frameElement.contentDocument;
//   doc.body.innerHTML = doc.body.innerHTML + '<style>.bar {width:45%;}</style>';
// }
</script>
<style>
.pay-by-stripe-main .email input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #000 !important;
}
.pay-by-stripe-main .email input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #000 !important;
  opacity: 1;
}
.pay-by-stripe-main .email input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #000 !important;
  opacity: 1;
}
.pay-by-stripe-main .email input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
}
.pay-by-stripe-main .email input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000 !important;
}

.pay-by-stripe-main .email input::placeholder {
  /* Most modern browsers support this now. */
  color: #000 !important;
}
a.pay-by-stripe {
  padding: 10px 25px;
  border: none;
  background: linear-gradient(184deg, #03174c, #051c50 10%, #45c5af 100%);
  color: white;
  border-radius: 4px;
}
.pay-by-stripe-main {
  text-align: right;
  margin-top: 30px;
}
.pay-by-stripe-main .email-label-top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.pay-by-stripe-main .email input[type="text"] {
  width: 100%;
  padding: 10px;
  background-color: var(--colorBackground);
  border-radius: 5px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
    color 0.15s ease;
  border: 1px solid #00000080;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  display: block;
}
.pay-by-stripe-main .email-label-top .col-md-6 label {
  text-align: left;
  display: block;
  color: #071649;
  font-size: 22px;
  font-weight: 400;
}
.pay-by-stripe-main .email-label-top h6,
.pay-by-stripe-main .email-label-top h6 a {
  color: #042051;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
}
.pay-by-stripe-main .email {
  margin-bottom: 30px;
}
.total .total-top h6,
.total .total-top p {
  color: #042051;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
}
.total .total-top h6 {
  text-align: left;
}
.total .total-middle h4,
.total .total-middle span {
  display: block;
  color: #071649;
  font-size: 22px;
  font-weight: 400;
}
.total .total-middle h4 {
  text-align: left;
}
.total {
  margin-bottom: 20px;
}
.col-12.payment_btn a {
  width: 100%;
  margin: 0 0 10px 0;
}
</style>
