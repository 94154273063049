import Vue from "vue";
import Vuex from "vuex";
// import api from "../config/api";

import moment from 'moment';

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MMMM D, YYYY')
  }
});
Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    baseurl: process.env.VUE_APP_API_URL,
    // baseurl: "https://virtualrealitycreators.com/Believe-Backend/api",
    // baseurl: "http://localhost:8000/api",
    isLoading: false,
    user: null,
    general_error: null,
    intent_generated: null,
    payment_successful: false,
    final_price: "0.00",
    selected_package: "",
    sub_history: [],
    subFormData: [{text1: null, text2: null, text3: null, planId: null, promo_token: null, promo_code: null, actual_price: null, discount: null }],
    stripeMessage: null,
    revcatSubType: null,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
