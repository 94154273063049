<template>
  <div class="services-slider">
    <VueSlickCarousel v-bind="settings">
      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/Abundance-Mindset.webp"
            alt=""
          />
          <h4 class="service-title">Abundance Mindset</h4>
        </div>
      </div>

      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/Spiritual-Pursuits.jpg"
            alt=""
          />
          <h4 class="service-title">Boost Energy</h4>
        </div>
      </div>

      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/career-success.webp"
            alt=""
          />
          <h4 class="service-title">Career Success</h4>
        </div>
      </div>

      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/Inner-Peace.webp"
            alt=""
          />
          <h4 class="service-title">Inner Peace</h4>
        </div>
      </div>

      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/positive-vibrations.webp"
            alt=""
          />
          <h4 class="service-title">Positive Vibration</h4>
        </div>
      </div>

      <div class="service-slide-main">
        <div class="slider-wrap">
          <img
            src="../../public/assets/images/slider-image/newimg/relaxation-an-stress-relief.webp"
            alt=""
          />
          <h4 class="service-title">Relaxation</h4>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "MyComponent",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 9000,
        autoplaySpeed: 9000,
        cssEase: "linear",
        centerMode: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        variableWidth: true,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style>
.services-slider {
  margin: 0 0 20px 0;
}
.services-slider .slider-wrap {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-radius: 25px;
}

.services-slider .slider-wrap h4 {
  position: absolute;
  bottom: 0;
  padding: 20px;
  background: gainsboro;
}
.services-slider .slider-wrap h4 {
  position: absolute;
  bottom: 0;
  padding: 20px;
  margin: 0;
  background: #68686899;
  display: block;
  width: 100%;
  color: white;
  border-radius: 0 0 25px 25px;
  font-family: "Rubik";
  font-weight: 500;
}
.services-slider .slick-slide {
  margin: 0 20px;
}
</style>
