<template>
  <div>
    <!-- <button type="button" class="paypal-btn" @click="createOrder">
      <img src="/assets/images/paypal.svg" width="60px" alt="" />
    </button> -->
  </div>
</template>

<script>
import API from "../config/api";

export default {
  props: {
    plan_id: String,
    promo_token: String,
    promo_code: String,
  },
  components: {},
  created() {},
  mounted() {
    this.createOrder();
  },
  methods: {
    async createOrder() {
      try {
        const response = await API.post("/create-paypal-subscription", {
          plan: this.plan_id,
          promo_token: this.promo_token ?? null,
          promo_code: this.promo_code ?? null,
        });
        console.log("plan", this.plan_id)
        console.log(response);
        this.$store.state.intent_generated=true;
        window.open(response.data.links[0].href, "_blank");
      } catch (err) {
        this.$store.state.intent_generated=false;

        console.log(err?.response?.data?.message);
        this.$store.state.general_error = err?.response?.data?.message ?? null;
      }
    },
  },
};
</script>

<style>
.paypal-btn {
  padding: 12px 50px;
  background-color: #fbce46;
  border-radius: 2px;
  border: none;
  outline: none;
  cursor: pointer;
}
</style>
