<template>
  <div class="main-banner banner-bg video-bg">
    <div class="container">
      <div class="row justify-content-center">
       
        <!-- <video
          id="background-video"
          autoplay
          loop
          muted
          playsinline
          poster="../../public/assets/images/resource/fallback-poster.png"
        >
         src="https://believehypnosis.my.canva.site/videos/be23385dc8068dfa07404e9768647f2f.mp4" 

          <source         
            src="../../public/assets/video/stars.mp4"
            type="video/mp4"
          />
        </video> -->
        
        <!-- <video id="background-video" src="../../public/assets/video/stars.mp4" playsinline="" preload="metadata" autoplay="" muted="" loop="" style="display:block;object-fit:cover;width:100%;height:100%;" poster="../../public/assets/images/resource/fallback-poster.png">
        </video> -->

        <div class="col-md-6 col-sm-12">
          <div class="top-logo">
            <span>
              <router-link to="/">
                <img
                  src="../../public/assets/images/logo-footer.webp"
                  alt=""
                  class="video-bg-logo"
                  width="70"
                />
              </router-link>
            </span>
            <nav id="primary-menu p-0">
              <ul style="position: relative">
                <li v-if="$store.state.user != null" class="lock-btn">
              <a type="button" title="" @click="logout()"
                > Logout</a
              >
              <!-- <i class="fa fa-lock"></i> -->
            </li>
            <li v-if="$store.state.user == null" class="lock-btn">
              <!-- <router-link type="button" class="login-btn" to="/login" title=""
                > Login</router-link
              > -->
              <button class="login-btn" v-on:click="goToLoginPage">Login</button>
              <!-- <i class="fa fa-key"></i> -->
            </li>
            <li v-if="$store.state.user != null" class="lock-btn">
              <router-link type="button" to="/manage" title=""
                > Subscriptions</router-link
              >
              <!-- <i class="fa fa-cog"></i> -->
            </li>
              </ul>
            </nav>
          </div>
          <h1 class="banner_text">Believe</h1>
          <span class="banner-para"
            ><b>DREAM</b> it, <b>BELIEVE</b> it, and <b>ACHIEVE</b> your
            DESIRES</span
          >

          
          <router-link
            v-if="$store.state.user == null"
            :to="`/register`"
            class="header-btn video-bg-btn"
            >Try BELIEVE Free</router-link
          >
          <router-link
            v-if="$store.state.user != null"
            :to="`/plans`"
            class="header-btn video-bg-btn"
            >Try BELIEVE Free</router-link
          >

          <img
            class="img-video-bg img-fluid"
            src="../../public/assets/images/resource/video-bg-img.webp"
            alt=""
          />

          <!-- <div id="Ufm25OHoeNE7ysrg" style="transform: rotate(0deg);top: 0%;left: 0%;width: 100%;position: absolute;height: 100%;opacity: 1;max-height: 500px;top: auto;bottom: 0 !important;z-index: -1;">
            <img src="../../public/assets/images/shadow.svg" alt="Orange Blur Circle Illustration" loading="lazy" style="display:block;object-fit: contain;width:100%;height:100%;">
          </div> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headernew",

  data() {
    return {
      display: true,
      selected_sub_id: null,
      loggedUserToken: null,
      boolloggedUserToken: false,
    };
  },
  props: {
    msg: String,
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    goToLoginPage(){
      this.$router.push("/login");
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("promo_code");
      localStorage.removeItem("has_promo");
      localStorage.removeItem("package_type");
      this.$store.state.user = null;
      this.$router.push("/login");
    },
    checkLogin() {
      this.loggedUserToken = localStorage.getItem("token");
      if (this.loggedUserToken != null) {
        this.boolloggedUserToken = true;
      } else {
        this.boolloggedUserToken = false;
      }
      // console.log("token", this.loggedUserToken);
    },
  },
};
</script>

<style>
.video-bg-btn{
    padding: 13px 25px;
}
.img-video-bg {
    display: block;
    margin: auto;
    margin-top: 40px;
    height: 480px;
    width: 250px;
}
.video-bg .row .col-md-6 {
  text-align: center;
}
.top-logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
a.header-btn.video-bg-btn {
  color: #0c1f4b;
  font-size: 20px;
  font-weight: 600;
  border: none;
  text-align: center;
  margin: auto;
}
.video-bg {
  background-image: url("../../public/assets/images/blue_gradient_bg.webp");
  /* background: rgb(3,68,171); */
/* background: linear-gradient(90deg, rgba(3,68,171,1) 0%, rgba(6,19,56,1) 35%, rgba(3,57,100,1) 100%); */
  padding: 0 !important;
  /* background: black; */
  position: relative;
}
#background-video {  
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-bg h1.banner_text ,.video-bg span.banner-para {
    color: white;
}
.top-logo nav {
    position: absolute;
    top: 10%;
    right: 0;
}
.top-logo{
    position: relative;
    margin-bottom: 30px;
}
li.lock-btns {
    margin-left: -50%;
}
li.af-login {
    margin-left: 0;
}

.video-bg span.banner-para{
    max-width: 100%;
    display: block;
    font-size: 20px;
    margin-bottom: 30px;
}
li.lock-btns a:hover {
    background: transparent;
}
@media only screen and (max-width: 1240px) and (min-width: 1024px)  {
    .video-bg .banner_text {
    font-family: "Rubik";
    font-size: 100px;
    }
    .img-video-bg {
    
    height: 400px;
    width: 220px;
}
a.video-bg-btn , span.banner-para {
    font-size: 16px !important;
}

li.af-login a{
    padding: 20px 5px 0 5px;
    font-size: 13px;
}
}
@media only screen and (max-width: 767px){
    .video-bg h1.banner_text {
    font-size: 55px !important;
}
.video-bg span.banner-para {
    font-size: 14px;
}
.img-video-bg {
    
    height: 450px;
    width: 220px;
}
.video-bg-btn{
    padding: 13px 25px;
    font-size: 18px !important;
}
.top-logo nav{
    position: relative;
}
img.video-bg-logo {
    width: 60px;
}
.top-logo {
    width: 65%;
    margin: auto;
    margin-bottom: 30px;
}
.top-logo {
    display: flex;   
    justify-content: space-between;    
}
nav ul li {
    font-size: 11px;
}
li.lock-btns{
    margin-left: 0;
}
}
</style>
